import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from './en.json';
import { baseLocaleEN } from '@maltego/ui';
import { localeEN as vaultLocaleEN } from "@vault/cases";

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    fallbackNS: ['common', 'vaultCases'],

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    resources: {
      en: {
        common: baseLocaleEN,
        translation: English,
        vaultCases: vaultLocaleEN
      },
    },
  });

export default i18n;
