var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"SENTRY_RELEASE_NAME_MISSING;"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const TRACES_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE);
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;

// Flag to track Sentry initialization
let isSentryInitialized = false;

export function initializeSentry() {
  if (isSentryInitialized) {
    return;
  }

  SENTRY_DSN &&
    Sentry.init({
      // Only enable Sentry if DSN is set
      dsn: SENTRY_DSN,
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: TRACES_SAMPLE_RATE,
      environment: APP_ENV,
      // ...
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps
    });

  isSentryInitialized = true;
}
