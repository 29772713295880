import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvitedOrExistingMember } from '../../types';

const validateEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const seperatorCharacters = ' ,;';
const splitRegex = new RegExp(`[${seperatorCharacters}]`, 'g');
const lastCharacterRegex = new RegExp(`[${seperatorCharacters}]$`);

export const getEmailError = (
  members: InvitedOrExistingMember[],
  existingEmails: string[],
  newlyEnteredEmail: string,
  t
) => {
  const isMemberWithMatchingEmail = members.some(
    (member) =>
      member.email.toLowerCase().trim() ===
      newlyEnteredEmail.toLowerCase().trim()
  );
  if (isMemberWithMatchingEmail) {
    return t('components.useBulkEmailState.validationDuplicateInvite');
  }

  const isEmailAlreadyInvited = existingEmails.some(
    (email) =>
      email.toLowerCase().trim() === newlyEnteredEmail.toLowerCase().trim()
  );
  if (isEmailAlreadyInvited) {
    return t('components.useBulkEmailState.validationDuplicateInvite');
  }

  if (!validateEmail(newlyEnteredEmail)) {
    return t('components.useBulkEmailState.validationInvalidEmail');
  }
};

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const removeEmailFromString = (email, emailInputStr) => {
  // Remove valid email, and it's trailing seperator from the input string
  const regexExp = new RegExp(
    `${escapeRegExp(email)}[${seperatorCharacters}]?`,
    'gi'
  );

  return emailInputStr.replace(regexExp, '');
};

function setErrorMessage(
  error: string,
  emailErrorMessages,
  invalidEmails,
  setError: (value: string) => void
) {
  const nonEmptyErrorMessages = emailErrorMessages.filter(
    (errorMsg) => !!errorMsg
  );

  if (nonEmptyErrorMessages.length === 0 && error) {
    setError('');
  } else if (nonEmptyErrorMessages.length === 1) {
    setError(nonEmptyErrorMessages[0]);
  } else if (nonEmptyErrorMessages.length > 1) {
    setError(`${invalidEmails[0]} ${nonEmptyErrorMessages[0]}`);
  }
}

const useBulkEmailState = (members: InvitedOrExistingMember[]) => {
  /**
   * Refactored state and logic out of BulkEmailInput.
   *
   * This allows forms using BulkEmailInput to view the error, email and input state of the component.
   */
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const { t } = useTranslation();

  const processInputValue = useCallback(
    (inputValue) => {
      // Extract valid emails, display any error messages.
      const potentialEmails = inputValue
        .split(splitRegex)
        .filter((strPart) => strPart.length > 0);

      const emailErrorMessages = potentialEmails.map((email) =>
        getEmailError(members, validEmails, email, t)
      );
      const newValidEmails = potentialEmails.filter(
        (_, index) => !emailErrorMessages[index]
      );
      const invalidEmails = potentialEmails.filter(
        (_, index) => !!emailErrorMessages[index]
      );

      if (newValidEmails.length > 0) {
        setValidEmails([...validEmails, ...newValidEmails]);

        let inputValueWithoutEmails = inputValue;
        for (const email of newValidEmails) {
          inputValueWithoutEmails = removeEmailFromString(
            email,
            inputValueWithoutEmails
          );
        }
        inputValueWithoutEmails = inputValueWithoutEmails.trim(); // Remove whitespace
        setValue(inputValueWithoutEmails);
      } else {
        setValue(inputValue);
      }

      setErrorMessage(error, emailErrorMessages, invalidEmails, setError);
    },
    [error, members, t, validEmails]
  );

  const onChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      const characterIndex = event.target.selectionStart;

      const isLastCharacterSeperator = !!newValue.match(lastCharacterRegex);
      const characterBeforeCursor = newValue[characterIndex - 1];
      const isCharacterAtIndexSeperatorOrEnd =
        (characterBeforeCursor || '').match(lastCharacterRegex) ||
        newValue.length === characterIndex;
      if (isLastCharacterSeperator && isCharacterAtIndexSeperatorOrEnd) {
        /**
         * Allow user to move cursor left in order to edit the email value,
         * without it being processed.
         *
         * E.g. 'aw@maltego.com,' should not be parsed, if the user's cursor is at the end
         * of 'aw' and they are editing the username before the domain.
         */
        processInputValue(newValue);
      } else {
        setValue(newValue);
        if (error) {
          setError(''); // Clear errors
        }
      }
    },
    [error, processInputValue]
  );

  const onKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        processInputValue(value);
      }
    },
    [value, processInputValue]
  );

  const onClick = useCallback(
    (clickedEmail) => {
      // Append clicked email to input to allow editing
      setValue(`${value} ${clickedEmail}`);
      setValidEmails(validEmails.filter((email) => email !== clickedEmail));
    },
    [value, validEmails]
  );

  const onDelete = useCallback(
    (deletedEmail) => {
      setValidEmails(validEmails.filter((email) => email !== deletedEmail));
    },
    [validEmails]
  );

  const isValidForSubmit = (cleanedFinalEmailInput: string) => {
    // Parent form is being submitted, perform final validation
    const inputError = getEmailError(
      members,
      validEmails,
      cleanedFinalEmailInput,
      t
    );

    if (cleanedFinalEmailInput.length !== 0 && inputError) {
      // Don't show 'invalid email' for empty string
      setError(inputError);
      return false;
    }

    if (cleanedFinalEmailInput.length === 0 && validEmails.length === 0) {
      setError(t('yup.requiredField'));
      return false;
    }

    return true;
  };

  return {
    isValidForSubmit,
    emailInputValue: value,
    validEmails,
    error,
    onChange,
    onKeyUp,
    onClick,
    onDelete,
  };
};

export default useBulkEmailState;
