import React, { ReactNode, useMemo } from 'react';
import { NavigationContext } from '@maltego/common-contexts';
import { useRouter } from 'next/router';

export const NavigationProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const router = useRouter();

  const value = useMemo(
    () => ({
      navigateTo: (url, queryParameters) =>
        queryParameters
          ? router.push({ pathname: url, query: queryParameters })
          : router.push(url),
      getCurrentPathname: () => router.pathname,
    }),
    [router]
  );

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
