import { MCheckbox, MDialog, WarningIcon } from '@maltego/mui-core';
import { Box, DialogContent, DialogContentText, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from '@maltego/ui';
import editGraphInMaltegoClient from '../../../components/cases/links/editGraphInMaltegoClient';
import { useGraphLockHandler } from '../../../hooks/useGraphLockHandler';
import useUserPreferences from '../../../hooks/useUserPreferences';

interface LockGraphDialogProps {
  caseId?: string;
  graphId: string;
  graphName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const LockGraphDialog = ({
  caseId,
  graphId,
  graphName,
  onConfirm,
  onCancel,
}: LockGraphDialogProps) => {
  const { t } = useTranslation();
  const { setLockGraphDialogDeactivated } = useUserPreferences();
  const { showSnackbar } = useSnackbar();
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const { lockGraph } = useGraphLockHandler();

  const confirmLockHandler = () => {
    lockGraph(graphId, graphName, caseId);
    if (isConfirmChecked) {
      setLockGraphDialogDeactivated(true);
    }
    editGraphInMaltegoClient(graphId, showSnackbar);
    onConfirm();
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <Trans i18nKey="components.LockGraphDialog.editInMaltego" />
      </MDialog.Title>
      <MDialog.Alert
        startIcon={
          <SvgIcon
            viewBox="0 0 24 24"
            sx={{ fill: 'none !important' }}
            component={WarningIcon}
          />
        }
      >
        <Trans i18nKey="components.LockGraphDialog.alertMessage" />
      </MDialog.Alert>

      <DialogContent>
        <b>{graphName}</b> <p />
        <DialogContentText sx={{ fontSize: 16, color: 303849 }}>
          <Trans i18nKey="components.LockGraphDialog.warningMessage" />
        </DialogContentText>
        <Box height={15} />
        <MCheckbox
          value={isConfirmChecked}
          sx={{ fontSize: 14, pl: 0 }}
          onClick={(e) => setIsConfirmChecked(!isConfirmChecked)}
        />
        <Trans i18nKey="components.LockGraphDialog.doNotShowThisAgain" />
      </DialogContent>

      <MDialog.Actions
        confirmText={t('pages.cases.editInMaltego')}
        onCancel={onCancel}
        onConfirm={confirmLockHandler}
      />
    </>
  );
};
export default LockGraphDialog;
