import { TableColumnDefinition } from '@maltego/mui-core';
import { Case, Graph } from '../../../graph-store-client/model';
import { CaseManagementTable } from '../../table/CaseManagementTable/CaseManagementTable';
import { MaltegoFile } from '../MaltegoFile';
import CaseAccess from './CaseAccess';
import CaseMoreOptions from './CaseMoreOptions';
import CaseName from './CaseName';
import GraphInUse from '../CaseGraphs/GraphInUse';
import { useTableRowClickCaseGraph } from '../CaseAndGraphTableRowHook';

export interface CaseContext {}

const COLUMNS: TableColumnDefinition<MaltegoFile<Case | Graph>, CaseContext>[] =
  [
    {
      key: 'name',
      title: 'Name',
      align: 'left',
      isSortable: true,
      render: CaseName,
    },
    {
      key: '',
      title: 'Access',
      align: 'left',
      isSortable: false,
      render: CaseAccess,
    },
    {
      key: 'in_use',
      title: 'In Use',
      align: 'left',
      isSortable: false,
      render: GraphInUse,
    },
    {
      key: 'type',
      title: 'Type',
      align: 'left',
      isSortable: true,
      cellSx: {
        ' p': { color: '#303849', fontSize: '12px', fontWeight: '400' },
      },
    },
    {
      key: '',
      title: 'Menu',
      align: 'right',
      isSortable: false,
      render: CaseMoreOptions,
    },
  ];

interface CasesTableProps {
  cases: MaltegoFile<Case | Graph>[];
}

const CasesTable = ({ cases }: CasesTableProps) => {
  const onTableRowClick = useTableRowClickCaseGraph();

  return (
    <CaseManagementTable
      data={cases}
      columns={COLUMNS}
      onTableRowClick={onTableRowClick}
    />
  );
};

export default CasesTable;
