import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { AlertColor, Snackbar } from '@mui/material';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import { TransitionProps } from '@mui/material/transitions';
import { MAlert } from '@maltego/mui-core';
import { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';

const SnackbarContext = createContext<SnackbarProviderValue | null>(null);

export enum SnackBarDuration {
  MANUAL = -1,
  SHORT = 1500,
  STANDARD = 3000,
  LONG = 6000,
}

export interface SnackbarMessage {
  message: string;
  severity: AlertColor;
  duration: SnackBarDuration;
  key: number;
}

interface SnackbarProviderValue {
  showSnackbar: (
    message: string,
    severity: AlertColor,
    duration: SnackBarDuration,
    additionalFunction?: () => void
  ) => void;
}

interface TransitionDownProps extends TransitionProps {
  children: React.ReactElement;
}

function TransitionDown({ children, ...props }: TransitionDownProps) {
  return (
    <Slide {...props} direction="down">
      {children}
    </Slide>
  );
}

const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /**
   * Subsequent snackbar messages need to be changed in a two-step process.
   * This is done for the exit transition to work properly.
   *
   * To remove snackbar correctly:
   * 1. Set open prop to false
   * 2. Wait for exit transition to complete
   * 3. Switch to the next snackbar, and set open to true.
   */
  const [isOpen, setIsOpen] = React.useState(false);
  const [snackBarQueue, setSnackBarQueue] = React.useState<
    readonly SnackbarMessage[]
  >([]);

  const currentlyVisibleSnackbar =
    snackBarQueue.length > 0 ? snackBarQueue[0] : undefined;

  let manualCloseTriggerFun;
  const showSnackbar = useCallback(
    (
      message: string,
      severity: AlertColor,
      duration: SnackBarDuration,
      additionalFunction: () => void
    ) => {
      manualCloseTriggerFun = additionalFunction;
      // Add a new message to the end of the queue
      setSnackBarQueue((prev) => [
        ...prev,
        {
          message,
          severity,
          duration,
          key: new Date().getTime(),
        },
      ]);
      setIsOpen(true);
    },
    [setIsOpen, setSnackBarQueue]
  );

  const value: SnackbarProviderValue = useMemo(
    () => ({
      showSnackbar,
    }),
    [showSnackbar]
  );

  const handleClickAway = useCallback(
    (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return; // Ignore user clicking elsewhere on the screen
      }
      setIsOpen(false);
    },
    [setIsOpen]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (manualCloseTriggerFun) {
      manualCloseTriggerFun();
    }
  }, [setIsOpen]);

  const handleExitTransitionFinished = useCallback(() => {
    setSnackBarQueue((prev) => prev.slice(1));
    if (snackBarQueue.length > 1) {
      setIsOpen(true);
    }
  }, [setSnackBarQueue, snackBarQueue, setIsOpen]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}

      <Snackbar
        key={currentlyVisibleSnackbar?.key}
        open={isOpen}
        autoHideDuration={
          currentlyVisibleSnackbar?.duration === SnackBarDuration.MANUAL
            ? undefined
            : currentlyVisibleSnackbar?.duration
        }
        TransitionComponent={TransitionDown}
        TransitionProps={{ onExited: handleExitTransitionFinished }}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          top: {
            xs: 11,
          },
          maxWidth: '100vw',
          px: {
            xs: 1.375, // 11px
            md: 18.75, // 150px
          },
        }}
      >
        <Box sx={{ width: '100%' }}>
          {currentlyVisibleSnackbar && (
            <MAlert
              severity={currentlyVisibleSnackbar.severity}
              onClose={handleClose}
              manualClose={
                currentlyVisibleSnackbar.duration === SnackBarDuration.MANUAL
              }
            >
              {currentlyVisibleSnackbar.message}
            </MAlert>
          )}
        </Box>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

function useSnackbar(): SnackbarProviderValue {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider');
  }
  return context;
}

export { SnackbarProvider, useSnackbar, SnackbarContext };
export default SnackbarProvider;
