const yupLocale = {
  mixed: {
    default: 'yup.requiredField',
    required: 'yup.requiredField',
  },
  string: {
    email: 'yup.invalidEmail',
  },
};

export default yupLocale;
