import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

const useStandardForm = <TFormValues = any>(
  yupSchema: AnyObjectSchema,
  defaultValues?: any
) =>
  useForm<TFormValues>({
    defaultValues: defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    reValidateMode: 'onChange',
  });

export default useStandardForm;
