import { BaseTableCellProps, MDialog } from '@maltego/mui-core';
import { MoreHoriz } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CaseManagementDialogStyles } from '../../dialog/CaseManagementDialog';
import { GraphVersion } from '../../../../graph-store-client/model';
import { useSnackbar } from '@maltego/ui';
import { DeleteIcon, ExternalLink, RestoreIcon } from '../../../../icons';
import { CaseContext } from '../../CasesTable/CasesTable';
import viewGraphInMaltegoClient from '../../../../components/cases/links/viewGraphInMaltegoClient';
import DeleteGraphHistoryDialog from '../../dialog/DeleteGraphHistoryDialog';
import RestoreGraphHistoryDialog from '../../../../components/cases/dialog/RestoreGraphHistoryDialog';
import editGraphInMaltegoClient from '../../../../components/cases/links/editGraphInMaltegoClient';
import { useGetGraphHistory } from '../../../../graph-store-client/maltegoCaseManagementAPI';
import { useGraphLockHandler } from '../../../../hooks/useGraphLockHandler';

const GraphHistoryMoreOptions = ({
  row,
  rowIndex,
}: BaseTableCellProps<GraphVersion, CaseContext>) => {
  const { data } = useSession();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { data: graphHistory, isLoading: isGraphsLoading } = useGetGraphHistory(
    row.graphId
  );
  const open = Boolean(anchorEl);
  const iAmVersionAuthor = row.authorEmail === data?.user?.email;
  const topRecord = rowIndex === 0;
  const onlyVersion = graphHistory?.length === 1;

  // TODO only graph author should have restore button enabled
  // const iAmGraphAuthor = isGraphAuthor(row, data || undefined)

  const [isDeleteGraphHistoryDialogOpen, setIsDeleteGraphHistoryDialogOpen] =
    React.useState(open);
  const [isRestoreGraphHistoryDialogOpen, setIsRestoreGraphHistoryDialogOpen] =
    React.useState(open);

  const { showSnackbar } = useSnackbar();
  const { lockGraph } = useGraphLockHandler();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const editInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    lockGraph(row.graphId);
    editGraphInMaltegoClient(row.graphId, showSnackbar);
  };

  const viewInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    viewGraphInMaltegoClient(row.graphId, showSnackbar, row.version);
  };

  const restoreGraphHistory = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsRestoreGraphHistoryDialogOpen(true);
  };

  const deleteGraphHistory = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsDeleteGraphHistoryDialogOpen(true);
  };

  return (
    <>
      <div>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem disabled={!topRecord} onClick={editInMaltego}>
            <ListItemIcon>
              <ExternalLink height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.editInMaltego')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={viewInMaltego}>
            <ListItemIcon>
              <ExternalLink height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.viewInMaltego')}</ListItemText>
          </MenuItem>
          <MenuItem disabled={topRecord} onClick={restoreGraphHistory}>
            <ListItemIcon>
              <RestoreIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.restoreVersion')}</ListItemText>
          </MenuItem>
          {iAmVersionAuthor && <Divider variant={'middle'} />}
          <MenuItem
            disabled={!iAmVersionAuthor || onlyVersion}
            onClick={deleteGraphHistory}
          >
            <ListItemIcon>
              <DeleteIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.deleteVersion')}</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      <MDialog
        sx={CaseManagementDialogStyles}
        open={isRestoreGraphHistoryDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <RestoreGraphHistoryDialog
          graphId={row.graphId}
          version={row.version}
          onConfirm={() => setIsRestoreGraphHistoryDialogOpen(false)}
          onCancel={() => setIsRestoreGraphHistoryDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isDeleteGraphHistoryDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <DeleteGraphHistoryDialog
          graphId={row.graphId}
          version={row.version}
          onConfirm={() => setIsDeleteGraphHistoryDialogOpen(false)}
          onCancel={() => setIsDeleteGraphHistoryDialogOpen(false)}
        />
      </MDialog>
    </>
  );
};
export default GraphHistoryMoreOptions;
