import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { APIError, getAuthHeaders } from '../../utils';
import { getDisplayMessageFromError } from '../../utils/apiCalls';
import { SnackBarDuration, useSnackbar } from '../../contexts';
import { useTranslation } from 'react-i18next';

interface UserAssignmentsMutation {
  // Schema of backend
  assignee: string;
  assignedEntitlements: string[];
}

interface UserRolesMutation {
  // Schema of backend
  accountId: string; // Organization ID
  role: string;
  userId: string;
}

export interface CombinedMutation {
  userId: string;
  accountId: string;
  newRole: string | null; // Null if no changes are made
  newAssignments: string[] | null; // Null if no changes are made
}

const getCombinedMutationFunction =
  (updateUserRolesURL: string, updatedUserAssignmentsURL: string) =>
  async (mutationVariable: CombinedMutation) => {
    const { userId, accountId, newRole, newAssignments } = mutationVariable;
    const apiCallPromises = [];
    const headers = await getAuthHeaders();
    if (newRole) {
      const body: UserRolesMutation = {
        userId,
        accountId,
        role: newRole,
      };

      const updateRolesRequest = axios
        .post(updateUserRolesURL, body, {
          headers,
        })
        .catch((error: AxiosError) => {
          throw new APIError(
            error.message,
            getDisplayMessageFromError(error),
            error.response.status
          );
        });
      apiCallPromises.push(updateRolesRequest);
    }

    if (newAssignments) {
      const body: UserAssignmentsMutation = {
        assignee: userId,
        assignedEntitlements: newAssignments,
      };
      const updateAssignmentsRequest = axios
        .post(updatedUserAssignmentsURL, body, {
          headers,
        })
        .catch((error: AxiosError) => {
          throw new APIError(
            error.message,
            getDisplayMessageFromError(error),
            error.response.status
          );
        });
      apiCallPromises.push(updateAssignmentsRequest);
    }
    return Promise.all(apiCallPromises);
  };
export default function useUserEditMutation(
  queryUsersURL: string,
  queryProductsURL: string,
  updateUserRolesURL: string,
  updateUserAssignmentsURL: string,
  onSuccess: () => void
) {
  /**
   * The user edit drawer needs to make multiple API calls, to different endpoints.
   *
   * This hook is used to abstract the complexity away from the component.
   */
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useMutation<unknown, APIError, CombinedMutation>(
    getCombinedMutationFunction(updateUserRolesURL, updateUserAssignmentsURL),
    {
      onSuccess: (data, mutationVariables: CombinedMutation) => {
        if (mutationVariables.newAssignments) {
          queryClient.invalidateQueries([queryProductsURL]).then(() => {});
        }
        queryClient.invalidateQueries([queryUsersURL]).then(() => {});
        showSnackbar(
          t('components.useUserEditMutation.userUpdateSuccess'),
          'success',
          SnackBarDuration.STANDARD
        );
        onSuccess();
      },
      onError: (err) => {
        console.error(err);
        showSnackbar(
          t('components.useUserEditMutation.userUpdateFailed'),
          'error',
          SnackBarDuration.STANDARD
        );
      },
    }
  );
}
