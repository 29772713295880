import { AlertColor } from '@mui/material';
import customProtocolCheck from 'custom-protocol-check';
import { SnackBarDuration } from '@maltego/ui';

function viewGraphInMaltegoClient(
  graphId: string,
  showSnackbar: (
    message: string,
    severity: AlertColor,
    duration: SnackBarDuration
  ) => void,
  version?: number
) {
  const maltegoScheme = process.env.NEXT_PUBLIC_MALTEGO_PROTOCOL_SCHEME;

  let viewUrl = `${maltegoScheme}://cloud/open/${graphId}`;
  if (version != null) {
    viewUrl = viewUrl + `?version=${version}`;
  }
  customProtocolCheck(
    viewUrl,
    () =>
      showSnackbar('No up-to-date version of Maltego was found', 'error', 6000),
    () => {}
  );

  customProtocolCheck(
    `${maltegoScheme}://`,
    () => {
      console.log('Custom protocol not found.');
    },
    () => {
      console.log('Custom protocol found and opened the file successfully.');
    },
    1000
  );
  console.log('Custom protocol found and opened the file successfully.');
}
export default viewGraphInMaltegoClient;
