import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';

interface ConfirmCheckboxProp extends CheckboxProps {
  isChecked: boolean;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const ConfirmCheckbox: React.FC<ConfirmCheckboxProp> = ({
  color = 'error',
  isChecked,
  label,
  name,
  onChange,
  ...props
}) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox
        disableRipple
        checked={isChecked}
        color={color}
        onChange={onChange}
        name={name}
        sx={{ py: 0.25 }}
        {...props}
      />
    }
  />
);

export default ConfirmCheckbox;
