import InvitedOrExistingMember, {
  MemberStatusEnum,
} from './InvitedOrExistingMember';
import CustomerRolesEnum from './CustomerRolesEnum';

const IOrganizationsMockUsers: InvitedOrExistingMember[] = [
  {
    id: 'firstMockUser',
    name: 'First Mock User',
    firstname: 'First',
    lastname: 'User',
    email: '1mu@mail.com',
    roles: [CustomerRolesEnum.ORGANIZATION_ADMIN],
    status: MemberStatusEnum.verified,
    products: [
      '3f87063e-57e4-4277-bce2-9ecf7c48d2d6',
      '52bb4cc3-5259-43ee-949c-15d5391a0fa1',
    ],
  },
  {
    id: 'secondMockUser',
    name: 'Second Mock User',
    firstname: 'Second',
    lastname: 'User',
    email: '2mu@mail.com',
    roles: [CustomerRolesEnum.TECHNICAL_ADMIN],
    status: MemberStatusEnum.verified,
    products: [
      '3f87066e-57e4-4277-bce2-9ecf7c48d2d6',
      '459cb69f-9bc0-4963-a429-6cce0a633e75',
      '8adcd99282e891850182ef1edf2a0df9',
    ],
  },
  {
    id: 'thirdMockUser',
    name: 'Third Mock User',
    firstname: 'Thrid',
    lastname: 'User',
    email: '3mu@mail.com',
    roles: [CustomerRolesEnum.FINANCE_ADMIN],
    status: MemberStatusEnum.verified,
    products: [
      '3f87063e-57e4-4277-bce2-9ecf7c48d2d6',
      '3f87066e-57e4-4277-bce2-9ecf7c48d2d6',
      '8adcd99282e891850182ef1edf2a0df9',
    ],
  },
  {
    id: 'fourthMockUser',
    name: 'Fourth Mock User',
    firstname: 'Fourth',
    lastname: 'User',
    email: '4mu@mail.com',
    roles: [CustomerRolesEnum.MEMBER],
    status: MemberStatusEnum.verified,
    products: ['3f87044e-57e4-4277-bce2-9ecf7c48d2d6'],
  },
  {
    id: 'fifthMockUser',
    name: 'Fifth Mock User',
    email: '5mu@mail.com',
    roles: [CustomerRolesEnum.MEMBER],
    status: MemberStatusEnum.invited,
    products: [],
  },
  {
    id: 'id-shadow-user',
    name: '',
    email: 'newuser@maltego.com',
    firstname: 'Shadow',
    lastname: 'User',
    roles: ['ADMIN'],
    status: MemberStatusEnum.shadow,
    products: ['52bb4cc3-5259-43ee-949c-15d5391a0fa1'],
  },
];
export default IOrganizationsMockUsers;
