import { TableColumnDefinition } from '@maltego/mui-core';
import { Box } from '@mui/material';
import { Graph56 } from '../../../icons';
import { Graph } from '../../../graph-store-client/model';
import { CaseManagementTable } from '../../table/CaseManagementTable/CaseManagementTable';
import EmptyState from '../../table/EmptyState';
import CaseGraphMoreOptions from './CaseGraphMoreOptions';
import CaseName from '../CasesTable/CaseName';
import { MaltegoFile } from '../MaltegoFile';
import GraphInUse from './GraphInUse';
import GraphAccess from './GraphAccess';
import { useTableRowClickCaseGraph } from '../CaseAndGraphTableRowHook';

export interface CaseGraphsContext {}
const COLUMNS: TableColumnDefinition<MaltegoFile<Graph>, CaseGraphsContext>[] =
  [
    {
      key: 'name',
      title: 'Name',
      align: 'left',
      isSortable: true,
      render: CaseName,
    },
    {
      key: '',
      title: 'Access',
      align: 'left',
      isSortable: false,
      render: GraphAccess,
    },
    {
      key: 'in_use',
      title: 'In Use',
      align: 'left',
      isSortable: false,
      render: GraphInUse,
    },
    {
      key: 'type',
      title: 'Type',
      align: 'center',
    },
    {
      key: '',
      title: 'Edit',
      align: 'right',
      isSortable: false,
      render: CaseGraphMoreOptions,
    },
  ];
const rowSx = {
  cursor: 'pointer',
};
interface CaseGraphsTableProps {
  graphs: MaltegoFile<Graph>[];
}
const CaseGraphsTable = ({ graphs }: CaseGraphsTableProps) => {
  const onTableRowClick = useTableRowClickCaseGraph();
  if (!graphs || graphs.length == 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="100%"
        sx={{ mt: '15%' }}
      >
        <EmptyState
          icon={Graph56}
          title="No graphs have been created"
          description="Graphs belong to a case will appear here once they are created"
        />
      </Box>
    );
  }
  return (
    <>
      <CaseManagementTable
        data={graphs}
        columns={COLUMNS}
        rowSx={rowSx}
        onTableRowClick={onTableRowClick}
      />
    </>
  );
};
export default CaseGraphsTable;
