/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */

export type CaseMemberRole =
  (typeof CaseMemberRole)[keyof typeof CaseMemberRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseMemberRole = {
  OWNER: 'OWNER',
  CONTRIBUTOR: 'CONTRIBUTOR',
  READER: 'READER',
} as const;
