import React from 'react';

import OrganizationSelector from '@/components/organization/OrganizationSelector';
import CreateOrRenameOrganizationDialog from '@/components/organization/CreateOrRenameOrganizationDialog';
import { useOrganization } from '@maltego/ui';
import { MDialog, useToggle } from '@maltego/mui-core';

const OrganizationController = () => {
  const { isOpen, onClose, onOpen } = useToggle();
  const { currentOrganization, organizations, setCurrentOrganizationID } =
    useOrganization();

  return (
    <>
      <OrganizationSelector
        currentOrganization={currentOrganization}
        organizations={organizations}
        onOrganizationChange={setCurrentOrganizationID}
        onOrganizationCreate={onOpen}
      />

      <MDialog open={isOpen} onClose={onClose} size="md">
        <CreateOrRenameOrganizationDialog onClose={onClose} />
      </MDialog>
    </>
  );
};

export default OrganizationController;
