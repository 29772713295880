export const dummyOrganizationCyber = {
  id: '1',
  name: 'Cyber Security Organization',
};

export const dummyOrganizationForensic = {
  id: '2',
  name: 'Forensic Research Organization',
};

export const dummyOrganizationBrand = {
  id: '3',
  name: 'Brand Protection Organization',
};

// TODO: Remove duplicate of dummy and mock organizations
export const dummyOrganizationsList = [
  dummyOrganizationCyber,
  dummyOrganizationForensic,
  dummyOrganizationBrand,
];
