import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  BaseTag,
  InvitedOrExistingMember,
  Product,
  ProductChip,
  RedirectURL,
  UserCheckIcon,
  UserNoCheckIcon,
} from '@maltego/ui';
import {
  BaseTable,
  BaseTableCellProps,
  DetailsButton,
  DetailsButtonParentHoverSx,
  TableColumnDefinition,
} from '@maltego/mui-core';
import dayjs from 'dayjs';
import { useAuthentication } from '@maltego/common-contexts';
import SharedSubscriptionLink from '../SharedSubscriptionLink/SharedSubscriptionLink';

interface SharedProductsTableContext {
  currentUserEmail: string;
  users: InvitedOrExistingMember[];
  isUserAdmin: boolean;
  isExpiredProducts?: boolean;
  handleCellHoverEvent: () => void;
  viewSubscriptionUrl?: RedirectURL;
  onSwitchTabClick?: (tabNumber: number) => void;
}

const NameCell = ({
  row,
  context,
}: BaseTableCellProps<Product, SharedProductsTableContext>) => {
  const isNotAssignedToCurrentNonAdminUser =
    !context.isUserAdmin &&
    !row.assignees.some(
      (assignee) => assignee.email === context.currentUserEmail
    );
  const { t } = useTranslation();
  const startDate = row.entitlementPeriod.validFrom * 1000;
  const isFutureStartDate = startDate > Date.now();

  const tooltipTitleComponent = (
    <Typography
      variant="body2"
      sx={{
        color: 'inherit',
      }}
    >
      {t('components.SharedProductsTable.starts')}:{' '}
      {dayjs(new Date(startDate)).format('MMM DD, YYYY')}
    </Typography>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        '.MuiTypography-root': {
          opacity:
            isNotAssignedToCurrentNonAdminUser || context.isExpiredProducts
              ? 0.3
              : undefined,
          backgroundColor:
            isNotAssignedToCurrentNonAdminUser || context.isExpiredProducts
              ? 'transparent'
              : undefined,
        },
      }}
    >
      <ProductChip
        productId={row.product.id}
        productName={row.productPlan.name}
      />

      {isFutureStartDate && (
        <BaseTag
          sx={{ ml: 0.5, py: 0.25 }}
          tagDescription={t('components.SharedProductsTable.startsSoon')}
          tooltipTitle={tooltipTitleComponent}
        />
      )}
    </Box>
  );
};

const AssignedToCell = ({
  row,
  context,
}: BaseTableCellProps<Product, SharedProductsTableContext>) => {
  const { t } = useTranslation();
  const assignedUserNames = row.assignees
    .map((assignee) => {
      const user = context.users.find((u) => u.id === assignee.id);
      if (!user?.firstname) {
        return user?.email;
      }
      return `${user?.firstname} ${user?.lastname}`;
    })
    .filter(Boolean);
  const isUnassigned = row.assignees.length === 0;
  const isNotAssignedToCurrentNonAdminUser =
    !context.isUserAdmin &&
    !row.assignees.some(
      (assignee) => assignee.email === context.currentUserEmail
    );

  if (context.isUserAdmin) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Tooltip
          disableHoverListener={!assignedUserNames}
          title={
            <Typography
              variant="body2"
              sx={{
                color: 'inherit',
                maxWidth: 300,
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 4,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {assignedUserNames.length > 0
                ? assignedUserNames.join(', ')
                : t('components.SharedProductsTable.noAssignedUsers')}
            </Typography>
          }
          placement="top"
          arrow
        >
          <Box
            onMouseEnter={() => context.handleCellHoverEvent()}
            onMouseLeave={() => context.handleCellHoverEvent()}
            data-testid="assigned-cell-icon"
            sx={{
              display: 'flex',
              cursor: context.isExpiredProducts ? 'not-allowed' : 'pointer',
              alignItems: 'center',
            }}
          >
            <SvgIcon
              viewBox="0 0 16 17"
              sx={{
                fontSize: 16,
                color:
                  isUnassigned || context.isExpiredProducts
                    ? 'grey.400'
                    : 'grey.900',
              }}
              component={isUnassigned ? UserNoCheckIcon : UserCheckIcon}
            />

            <Typography
              variant="body2"
              display="inline"
              sx={{
                color:
                  isUnassigned || context.isExpiredProducts
                    ? 'grey.400'
                    : 'grey.900',
                lineHeight: '24px',
                ml: 1,
              }}
            >
              {row.assignees.length}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  } else {
    if (!isNotAssignedToCurrentNonAdminUser) {
      return (
        <SvgIcon
          component={UserCheckIcon}
          sx={{
            mt: 1,
            color: context.isExpiredProducts ? 'grey.400' : 'grey.900',
          }}
        />
      );
    }
    return (
      <Typography
        variant="body2"
        sx={{
          color: 'grey.400',
        }}
      >
        {t('components.SharedProductsTable.notAssigned')}
      </Typography>
    );
  }
};

const MaxAssignmentsCell = ({ row, context }: BaseTableCellProps<Product>) => {
  if (!row.productPlan.seats || row.productPlan.seats >= 1000000) {
    return (
      <Typography
        variant="body2"
        sx={{
          color: context.isExpiredProducts ? 'grey.400' : 'grey.900',
        }}
      >
        ∞
      </Typography>
    );
  }
  return (
    <Typography
      variant="body2"
      sx={{
        color: context.isExpiredProducts ? 'grey.400' : 'grey.900',
      }}
    >
      {row.productPlan.seats}
    </Typography>
  );
};

const ValidUntilCell = ({
  row,
  context,
}: BaseTableCellProps<Product, SharedProductsTableContext>) => {
  const isNotAssignedToCurrentNonAdminUser =
    !context.isUserAdmin &&
    !row.assignees.some(
      (assignee) => assignee.email === context.currentUserEmail
    );
  const { t } = useTranslation();

  if (context.isExpiredProducts) {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
      >
        <Typography variant="body2" sx={{ color: 'error.main', mr: 0.5 }}>
          {t('components.SharedProductsTable.expired')}
        </Typography>
        {context.isUserAdmin && (
          <SharedSubscriptionLink
            isLinkInTableCell
            handleCellHoverEvent={() => context.handleCellHoverEvent()}
            onButtonClick={() => context.onSwitchTabClick(2)}
            viewSubscriptionUrl={context.viewSubscriptionUrl}
          />
        )}
      </Box>
    );
  }
  return (
    <Typography
      variant="body2"
      sx={{ opacity: isNotAssignedToCurrentNonAdminUser ? 0.3 : undefined }}
    >
      {dayjs(new Date(row.entitlementPeriod.validUntil * 1000)).format(
        'MMM DD, YYYY'
      )}
    </Typography>
  );
};

const ActionsCell = ({
  context,
}: BaseTableCellProps<SharedProductsTableContext>) => {
  if (context.isExpiredProducts) {
    return null;
  }
  return <DetailsButton label="components.SharedProductsTable.details" />;
};

export const SHARED_PRODUCTS_TABLE_COLUMNS = (
  isExpiredProducts: boolean
): TableColumnDefinition[] => {
  const headerStyling = {
    opacity: isExpiredProducts ? 0.3 : undefined,
  };

  return [
    {
      key: 'name',
      title: !isExpiredProducts
        ? 'components.SharedProductsTable.product'
        : 'components.SharedProductsTable.expiredProduct',
      render: NameCell,
      isSortable: true,
      headerSx: headerStyling,
    },
    {
      key: 'assignedTo',
      title: 'components.SharedProductsTable.assigned',
      render: AssignedToCell,
      align: 'right',
      headerSx: headerStyling,
    },
    {
      key: 'maxAssignments',
      title: 'components.SharedProductsTable.maxAssignments',
      render: MaxAssignmentsCell,
      align: 'right',
      headerSx: headerStyling,
    },
    {
      key: 'validUntil',
      title: 'components.SharedProductsTable.validUntil',
      render: ValidUntilCell,
      align: 'right',
      isSortable: true,
      headerSx: headerStyling,
    },
    {
      key: 'actions',
      title: !isExpiredProducts ? 'components.SharedProductsTable.edit' : '',
      render: !isExpiredProducts ? ActionsCell : null,
      align: 'right',
    },
  ];
};

interface ProductsTableProps {
  isExpiredProducts?: boolean;
  isUserAdmin?: boolean;
  products: Product[];
  users: InvitedOrExistingMember[];
  viewSubscriptionUrl?: RedirectURL;
  onSwitchTabClick?: (tabNumber: number) => void;
  onTableRowClick?: (tableRow: Product) => void;
}

const SharedProductsTable: React.FC<ProductsTableProps> = ({
  isUserAdmin = true,
  isExpiredProducts,
  products,
  users,
  viewSubscriptionUrl,
  onSwitchTabClick,
  onTableRowClick,
}) => {
  const { t } = useTranslation();
  const { claims } = useAuthentication();
  const currentUserEmail = claims.email;
  const [isRowTooltipVisible, setIsRowTooltipVisible] = useState(true);

  const columns = useMemo(() => {
    if (!isUserAdmin) {
      return SHARED_PRODUCTS_TABLE_COLUMNS(isExpiredProducts).filter(
        (item) => item.key !== 'maxAssignments'
      );
    }
    return SHARED_PRODUCTS_TABLE_COLUMNS(isExpiredProducts);
  }, [isUserAdmin, isExpiredProducts]);

  const handleCellHoverEvent = () => {
    setIsRowTooltipVisible((isRowTooltipVisible) => !isRowTooltipVisible);
  };

  const sortedProducts = useMemo(() => {
    const newData = [...products];

    newData.sort((a, b) => {
      // Sort alphabetically, with Maltego products first
      if (
        a.product.name.toLowerCase().startsWith('maltego') &&
        !b.product.name.toLowerCase().startsWith('maltego')
      ) {
        return -1;
      }
      if (
        !a.product.name.toLowerCase().startsWith('maltego') &&
        b.product.name.toLowerCase().startsWith('maltego')
      ) {
        return 1;
      }

      // Sort by whether assignedTo array includes currentUserID if current user is not admin
      if (!isUserAdmin) {
        if (
          a.assignees.some((assignee) => assignee.email === currentUserEmail) &&
          !b.assignees.some((assignee) => assignee.email === currentUserEmail)
        ) {
          return -1;
        }
        if (
          !a.assignees.some(
            (assignee) => assignee.email === currentUserEmail
          ) &&
          b.assignees.some((assignee) => assignee.email === currentUserEmail)
        ) {
          return 1;
        }
      }

      if (a.product.name.toLowerCase() > b.product.name.toLowerCase()) {
        return 1;
      }
      if (a.product.name.toLowerCase() < b.product.name.toLowerCase()) {
        return -1;
      }

      return 0;
    });

    return newData;
  }, [products, currentUserEmail, isUserAdmin]);

  return (
    <Box
      sx={{
        '.MuiTable-root': {
          tableLayout: 'fixed', // Prevent table from exceeding 100% width
        },
        'th:nth-of-type(1)': {
          width: '40%',
        },
        'th:nth-of-type(4)': {
          width: 180,
        },
      }}
    >
      <BaseTable
        columns={columns}
        context={{
          users,
          currentUserEmail,
          isUserAdmin,
          isExpiredProducts,
          viewSubscriptionUrl,
          handleCellHoverEvent,
          onSwitchTabClick,
        }}
        data={sortedProducts}
        rowSx={(rowData: Product) => {
          const isNotAssignedToCurrentNonAdminUser =
            !isUserAdmin &&
            !rowData.assignees.some(
              (assignee) => assignee.email === currentUserEmail
            );
          let hoverButtonStyling = { ...DetailsButtonParentHoverSx['&:hover'] }; // hover on row to show DetailsButton
          hoverButtonStyling['.selection-circle']['opacity'] = 1;

          return {
            '.selection-circle': {
              backgroundColor: isNotAssignedToCurrentNonAdminUser
                ? 'grey.400'
                : undefined,
              opacity: isNotAssignedToCurrentNonAdminUser ? 0.3 : undefined,
            },
            '&:hover': {
              border: '1px solid',
              td: {
                borderTop: '1px solid',
                borderBottom: '1px solid',
              },
              ...hoverButtonStyling,
            },
          };
        }}
        onTableRowClick={onTableRowClick}
        rowPropsGenerator={(rowData: Product, rowIndex) => {
          const isNotAssignedToCurrentNonAdminUser =
            !isUserAdmin &&
            !rowData.assignees.some(
              (assignee) => assignee.email === currentUserEmail
            );
          if (isNotAssignedToCurrentNonAdminUser) {
            return {
              tooltip: {
                title: t(
                  'components.SharedProductsTable.productNotAssignedToYou'
                ),
                followCursor: true,
              },
              disabled: false,
            };
          } else if (isExpiredProducts && isRowTooltipVisible) {
            return {
              tooltip: {
                title: t('components.SharedProductsTable.productHasExpired'),
                followCursor: true,
              },
              disabled: true,
            };
          }
          return {
            tooltip: { title: '', followCursor: true },
            disabled: false,
          };
        }}
      />
    </Box>
  );
};

export default SharedProductsTable;
