import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { InfoIcon, MDialog, MDrawer, useToggle } from '@maltego/mui-core';
import { Box, SvgIcon } from '@mui/material';
import ProductEditDrawerHeader from './ProductEditDrawerHeader';
import {
  InvitedOrExistingMember,
  Product,
  RedirectURL,
  UserRole,
} from '../../types';
import ProductEditDrawerTable from './ProductEditDrawerTable';
import { isProductDesktopOrTool, useStandardMutation } from '../../utils';
import { SnackBarDuration, useSnackbar } from '../../contexts';
import { areStringArraysEqual } from '../UserEditDrawer/UserEditDrawer';
import RemoveAssignmentDialog from './RemoveAssignmentDialog';

const getAssignedUserIds = (product, users) => {
  const userIdsAssignedProduct = users.reduce((acc, user) => {
    if (user.products.includes(product.product.id)) {
      acc.push(user.id);
    }
    return acc;
  }, [] as string[]);

  userIdsAssignedProduct.sort(); // Sort assigned users, so we can detect when assignments change
  return userIdsAssignedProduct;
};

interface ProductEditDrawerProps {
  isUserAdmin: boolean;
  onClose: () => void;
  products: Product[];
  queryProductInfoURL?: (string) => string;
  queryProductsURL: string;
  queryUsersURL: string;
  viewSubscriptionUrl?: RedirectURL;
  roles: UserRole[];
  selectedProduct: Product;
  updateAssignedUsersURL: string;
  userEmail?: string;
  users: InvitedOrExistingMember[];
}

interface AssignUsersMutation {
  // Schema of backend
  entitlementId: string;
  assignedUsers: string[];
}

const ProductEditDrawer: React.FC<ProductEditDrawerProps> = ({
  isUserAdmin,
  onClose,
  products,
  queryProductInfoURL,
  queryProductsURL,
  queryUsersURL,
  viewSubscriptionUrl,
  roles,
  selectedProduct,
  updateAssignedUsersURL,
  userEmail,
  users: originalUsers,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [users, setUsers] = useState<InvitedOrExistingMember[]>(originalUsers);
  const [product, setProduct] = useState<Product>(selectedProduct);
  const isMaltegoLicense = isProductDesktopOrTool(selectedProduct);

  const {
    isOpen: isRemoveAssignmentDialogOpen,
    onOpen: onOpenRemoveAssignmentDialog,
    onClose: onCloseRemoveAssignmentDialog,
  } = useToggle();

  const originalAssignments = useMemo(
    () => getAssignedUserIds(selectedProduct, originalUsers),
    [selectedProduct, originalUsers]
  );
  const currentAssignments = useMemo(
    () => getAssignedUserIds(selectedProduct, users),
    [selectedProduct, users]
  );

  const hasAssignmentsChanged = useMemo(
    () => !areStringArraysEqual(originalAssignments, currentAssignments),
    [originalAssignments, currentAssignments]
  );

  const unassignedUserEmails = useMemo(() => {
    if (isMaltegoLicense) {
      const unassignedUsers = originalAssignments.filter(
        (originalAssignment) => !currentAssignments.includes(originalAssignment)
      );
      const unassignedUserHasDataAssignment = users.filter(
        (user) =>
          unassignedUsers.includes(user.id) && user.products.length !== 0
      );
      return unassignedUserHasDataAssignment.map((item) => item.email);
    }
  }, [currentAssignments, isMaltegoLicense, originalAssignments, users]);

  const onUserClick = useCallback(
    (clickedUser: InvitedOrExistingMember) => {
      const updatedProduct = { ...product };
      const newUsers = users.map((user: InvitedOrExistingMember) => {
        if (clickedUser.id !== user.id) {
          return user;
        } else {
          // Create copy to prevent modifying original users data
          const updatedUser = { ...user };
          const isCurrentlyAssigned = updatedUser.products.includes(
            updatedProduct.product.id
          );

          if (isCurrentlyAssigned) {
            updatedUser.products = updatedUser.products.filter(
              (id) => id !== updatedProduct.product.id
            );

            updatedProduct.assignees = updatedProduct.assignees.filter(
              (assignee) => assignee.id !== updatedUser.id
            );
          } else {
            const hasAvailableSeats =
              updatedProduct.productPlan.seats -
                updatedProduct.assignees.length >
              0; // unlimited seats is 1 million

            if (hasAvailableSeats) {
              // Create new array to prevent modifying original assignment data
              updatedUser.products = [
                ...updatedUser.products,
                updatedProduct.product.id,
              ];

              updatedProduct.assignees = [
                ...updatedProduct.assignees,
                {
                  id: updatedUser.id,
                  email: updatedUser.email,
                },
              ];
            }
          }
          return updatedUser;
        }
      });
      setProduct(updatedProduct);
      setUsers(newUsers);
    },
    [users, product]
  );

  const assignUsers = useStandardMutation<AssignUsersMutation, Product>({
    queryKey: updateAssignedUsersURL,
    mutationMethod: 'POST',
    onSuccess: () => {
      queryClient.invalidateQueries([queryProductsURL]).then(() => {});
      queryClient.invalidateQueries([queryUsersURL]).then(() => {});
      showSnackbar(
        t('components.ProductEditDrawer.productAssignedToUpdateSuccess', {
          productName: selectedProduct.product.name,
        }),
        'success',
        SnackBarDuration.STANDARD
      );
      onClose();
    },
    onError: () => {
      showSnackbar(
        t('components.ProductEditDrawer.productAssignedToUpdateFailed', {
          productName: selectedProduct.product.name,
        }),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });

  const updateAssignmentMutation = useCallback(() => {
    assignUsers.mutate({
      assignedUsers: currentAssignments,
      entitlementId: selectedProduct.id,
    });
    onCloseRemoveAssignmentDialog();
  }, [
    assignUsers,
    currentAssignments,
    onCloseRemoveAssignmentDialog,
    selectedProduct,
  ]);

  const onAssignBtnClick = useCallback(() => {
    if (isMaltegoLicense && unassignedUserEmails.length > 0) {
      onOpenRemoveAssignmentDialog();
    } else {
      updateAssignmentMutation();
    }
  }, [
    isMaltegoLicense,
    onOpenRemoveAssignmentDialog,
    unassignedUserEmails,
    updateAssignmentMutation,
  ]);

  return (
    <>
      <MDrawer.Title
        drawerTitle="components.ProductEditDrawer.productInfo"
        onClose={onClose}
      />

      <MDrawer.Body size="lg" disablePadding>
        <Box sx={{ px: 8.25, pb: 4, position: 'relative' }}>
          <ProductEditDrawerHeader
            isUserAdmin={isUserAdmin}
            numAssignedUsers={currentAssignments.length}
            product={selectedProduct}
            queryProductInfoURL={queryProductInfoURL}
            viewSubscriptionUrl={viewSubscriptionUrl}
            userEmail={userEmail}
          />
        </Box>
        {isUserAdmin && (
          <Box
            sx={{
              px: 2,
              pb: 4,
            }}
          >
            <ProductEditDrawerTable
              onRowClick={onUserClick}
              product={product}
              products={products}
              roles={roles}
              users={users}
            />
          </Box>
        )}
      </MDrawer.Body>

      <MDrawer.Hint
        sx={{ backgroundColor: 'grey.A100' }}
        startIcon={
          <SvgIcon
            component={InfoIcon}
            sx={{ fill: 'none !important' }}
            viewBox="0 0 24 24"
          />
        }
      >
        {isUserAdmin
          ? t('components.ProductEditDrawer.hintTobuyMore')
          : t('components.ProductEditDrawer.hintToContactAdmin')}
      </MDrawer.Hint>

      <MDrawer.Actions
        confirmDisabled={isUserAdmin && !hasAssignmentsChanged}
        confirmText={isUserAdmin ? 'common.button.apply' : 'common.button.ok'}
        errorMessage={assignUsers.error?.message}
        isLoading={assignUsers.isLoading}
        onConfirm={isUserAdmin ? onAssignBtnClick : onClose}
        onClose={onClose}
      />
      <MDialog size="md" open={isRemoveAssignmentDialogOpen}>
        <RemoveAssignmentDialog
          unassignedUserEmails={unassignedUserEmails}
          onClose={onCloseRemoveAssignmentDialog}
          onConfirm={updateAssignmentMutation}
        />
      </MDialog>
    </>
  );
};

export default ProductEditDrawer;
