import { useLocalStorage } from '@maltego/ui';

const useUserPreferences = () => {
  const [isLockGraphDialogDeactivated, setLockGraphDialogDeactivated] =
    useLocalStorage('lockGraphDialogDeactivated', false);

  return {
    isLockGraphDialogDeactivated,
    setLockGraphDialogDeactivated,
  } as const;
};

export default useUserPreferences;
