import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { signIn, signOut, useSession } from 'next-auth/react';
import { FullPageLoader } from '@maltego/mui-core';
import { AuthenticationContext } from '@maltego/common-contexts';
import SSRConsistency from './SSRConsistency';
import { CustomSession } from '../../types';
import axios from 'axios';
import { useRouter } from 'next/router';
import ExpiredSessionError from '../ExpiredSessionError/ExpiredSessionError';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const KeycloakAuthProvider: React.FC<{
  children: React.ReactNode;
  skipOrganizationSelection?: boolean;
}> = ({ children, skipOrganizationSelection }) => {
  const { data, status } = useSession();
  const session = data as CustomSession;
  const [isLogout, setIsLogout] = useState(false);
  const router = useRouter();
  const pathName = router.pathname;
  const isPathNameRsvp = pathName.toLowerCase().startsWith('/rsvp');

  const customLogout = useCallback(async () => {
    setIsLogout(true);
    const {
      data: { keycloakLogoutUrl },
    } = await axios.get('/api/auth/logout');
    await signOut({ redirect: false });
    window.location.href = keycloakLogoutUrl;
  }, [setIsLogout]);

  const [tokenClaims, setTokenClaims] = useState({
    sub: '',
    given_name: '',
    family_name: '',
    name: '',
    email: '',
    organization: {
      id: '',
    },
    scope: '',
  });

  const value = useMemo(
    () => ({
      isAuthenticated: status === 'authenticated',
      login: (authorizationParams = {}) => {
        if (skipOrganizationSelection) {
          // Ensure that all logins for this app skip org selection
          authorizationParams = {
            ...authorizationParams,
            skipOrgSelection: 'true',
          };
        }
        return signIn('keycloak', {}, authorizationParams);
      },
      logout: customLogout,
      claims: tokenClaims,
    }),
    [customLogout, skipOrganizationSelection, status, tokenClaims]
  );

  useEffect(() => {
    if (status === 'unauthenticated' && !isLogout && !isPathNameRsvp) {
      const authParams = skipOrganizationSelection
        ? { skipOrgSelection: 'true' }
        : {};
      signIn('keycloak', {}, authParams).then(() => {
        /* Ignore */
      });
    }

    if (session) {
      const parsedToken = parseJwt(session.accessToken);
      setTokenClaims({
        sub: parsedToken.sub,
        given_name: parsedToken.given_name,
        family_name: parsedToken.family_name,
        name: parsedToken.name,
        email: parsedToken.email,
        organization: {
          id: parsedToken?.organization?.id || '',
        },
        scope: parsedToken.scope,
      });
    }
  }, [session, skipOrganizationSelection, status, isLogout, isPathNameRsvp]);

  if (status === 'loading') {
    return <FullPageLoader />;
  }

  if (session?.error === 'RefreshAccessTokenError') {
    return (
      <AuthenticationContext.Provider value={value}>
        <ExpiredSessionError />
      </AuthenticationContext.Provider>
    );
  }

  if (status === 'authenticated' || isPathNameRsvp) {
    return (
      <AuthenticationContext.Provider value={value}>
        <SSRConsistency>{children}</SSRConsistency>
      </AuthenticationContext.Provider>
    );
  }

  return <></>;
};

export default KeycloakAuthProvider;
