import React, { useEffect, useState } from 'react';
import { FullPageLoader } from '@maltego/mui-core';

const SSRConsistency: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /**
   *  NEXTJS performs an SSR render, and the SSR rendered HTML needs to match the first client side render.
   *
   *  As the user isn't authenticated on the server, a loading indicator is rendered on the server and we must render the same loading
   *  indicator on the first client render.
   */

  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  if (isClientSide) {
    return <>{children}</>;
  }

  return <FullPageLoader />;
};

export default SSRConsistency;
