import Head from 'next/head';
import React from 'react';

interface HeadPortalProps {
  title: string;
  isForStaffPortal?: boolean;
}
const HeadPortal: React.FC<HeadPortalProps> = ({ title, isForStaffPortal }) => {
  const displayTitle = isForStaffPortal ? title : `${title} - Maltego Portal`;

  return (
    <Head>
      <title>{displayTitle}</title>
    </Head>
  );
};

export default HeadPortal;
