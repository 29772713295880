import DownloadIcon from 'public/images/icons/drawer/download24.svg';
import PaymentsIcon from 'public/images/icons/drawer/billing24.svg';
import ProductsIcon from 'public/images/icons/drawer/products24.svg';
import SettingsIcon from 'public/images/icons/drawer/settings24.svg';
import ShopIcon from 'public/images/icons/drawer/shop.svg';
import TrainingIcon from 'public/images/icons/drawer/training24.svg';
import FolderIcon from 'public/images/icons/drawer/folder24.svg';
import ArchiveIcon from 'public/images/icons/drawer/archive24.svg';
import UsersIcon from 'public/images/icons/drawer/users24.svg';
import { CustomerRolesEnum, ExternalLinkIcon } from '@maltego/ui';
import { NavigationItem } from '@maltego/mui-core';

export interface CustomerNavigationItem extends NavigationItem {
  requiresRole?: CustomerRolesEnum[];
}

const sideNavItems: Record<string, CustomerNavigationItem[]> = {
  vault: [
    {
      href: '/cases',
      label: 'layout.drawerItems.cases',
      icon: FolderIcon,
      requiresRole: [CustomerRolesEnum.BETA_USER],
    },
    {
      label: 'layout.drawerItems.archivedCases',
      icon: ArchiveIcon,
      requiresRole: [CustomerRolesEnum.BETA_USER],
      isComingSoon: true,
    },
  ],
  desktop: [
    {
      href: '/',
      label: 'layout.drawerItems.download',
      icon: DownloadIcon,
    },
    {
      href: 'https://spark.maltego.com',
      label: 'layout.drawerItems.training',
      tooltip: 'layout.drawerItems.trainingForDrawerCollapsed',
      icon: TrainingIcon,
      rightIcon: ExternalLinkIcon,
    },
    {
      href: '/shop',
      label: 'layout.drawerItems.shop',
      icon: ShopIcon,
      isComingSoon: false,
      requiresRole: [],
    },
  ],
  admin: [
    {
      href: '/users',
      label: 'layout.drawerItems.users',
      icon: UsersIcon,
      requiresRole: [CustomerRolesEnum.ORGANIZATION_ADMIN],
    },
    {
      href: '/products',
      label: 'layout.drawerItems.products',
      icon: ProductsIcon,
    },
    {
      href: '/invoices',
      label: 'layout.drawerItems.billing',
      icon: PaymentsIcon,
      requiresRole: [
        CustomerRolesEnum.ORGANIZATION_ADMIN,
        CustomerRolesEnum.FINANCE_ADMIN,
      ],
    },
    {
      href: '/settings',
      label: 'layout.drawerItems.settings',
      icon: SettingsIcon,
      requiresRole: [
        CustomerRolesEnum.ORGANIZATION_ADMIN,
        CustomerRolesEnum.FINANCE_ADMIN,
      ],
    },
  ],
};

export default sideNavItems;
