import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { MDialog, WarningIcon } from '@maltego/mui-core';
import {
  Box,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { Mtgl36, RootDirectory, SvgCase16 } from '../../../icons';
import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useAddGraphToCase,
  useGetAllCases,
  useRemoveGraphFromCase,
  useUpdateGraph,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { Graph } from '../../../graph-store-client/model';
import { MaltegoFile } from '../MaltegoFile';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

const CREATE_CASE_SCHEMA = yup.object({
  caseId: yup.string().required('Please select the case'),
});

export const ROOT: string = 'root';

export interface GraphCaseReassignmentForm {
  caseId: string;
}

interface MoveGraphDialogProps {
  graphData: MaltegoFile<Graph>;
  onConfirm: () => void;
  onCancel: () => void;
}

interface CaseFileIconProps {
  caseLabel: string;
}

export const MoveGraphDialog = ({
  graphData,
  onConfirm,
  onCancel,
}: MoveGraphDialogProps) => {
  const { t } = useTranslation();
  const { data: cases, isLoading } = useGetAllCases();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GraphCaseReassignmentForm>({
    resolver: yupResolver(CREATE_CASE_SCHEMA),
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: { caseId: graphData.caseId ?? ROOT },
  });

  const updateGraphMutation = useUpdateGraph();
  const addGraphToCaseMutation = useAddGraphToCase();
  const removeGraphFromCaseMutation = useRemoveGraphFromCase();
  const error = updateGraphMutation.error;
  const errorDisplay = useMemo(
    () => (error ? error.response?.data?.title : null),
    [error]
  );
  const { showSnackbar } = useSnackbar();

  const MoveToRootString = () => (
    <Typography sx={{ my: 0.25 }}>
      <SvgIcon
        component={RootDirectory}
        viewBox="0 0 16 16"
        sx={{ fontSize: 16, mr: 1 }}
      />
      Root directory
    </Typography>
  );

  const CaseFileIcon = ({ caseLabel }: CaseFileIconProps) => (
    <Typography sx={{ my: 0.25 }}>
      <SvgIcon
        component={SvgCase16}
        viewBox="0 0 16 16"
        sx={{ fontSize: 16, mr: 1 }}
      />
      {caseLabel}
    </Typography>
  );

  const moveGraph = (data: GraphCaseReassignmentForm) => {
    if (data.caseId == ROOT) {
      if (graphData.caseId === null) return;

      removeGraphFromCaseMutation.mutate(
        {
          caseId: graphData.caseId,
          graphId: graphData.id,
        },
        {
          onSuccess: () => {
            const successMessage = t(
              'components.MoveGraphDialog.graphMovedToRootSuccessMessage',
              { graphName: graphData.name }
            );
            showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
            onConfirm();
          },
          onSettled: () => {
            void queryClient.invalidateQueries({
              queryKey: getGetCaseGraphsQueryKey(graphData.caseId),
            });
            void queryClient.invalidateQueries({
              queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
            });
          },
        }
      );
    } else
      addGraphToCaseMutation.mutate(
        {
          caseId: data.caseId,
          data: { id: graphData.id },
        },
        {
          onSuccess: () => {
            const successMessage = t(
              'components.MoveGraphDialog.graphMovedToCaseSuccessMessage',
              {
                graphName: graphData.name,
                caseName: cases?.find((c) => c.id === data.caseId)?.name,
              }
            );
            showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
            onConfirm();
          },
          onSettled: () => {
            void queryClient.invalidateQueries({
              queryKey: getGetCaseGraphsQueryKey(data.caseId),
            });

            if (graphData.caseId !== null) {
              void queryClient.invalidateQueries({
                queryKey: getGetCaseGraphsQueryKey(graphData.caseId),
              });
            } else {
              void queryClient.invalidateQueries({
                queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
              });
            }
          },
        }
      );
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        {t('pages.cases.moveGraph')} {graphData.name}
      </MDialog.Title>
      <DialogContent>
        {isLoading && <LinearProgress />}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
          <Mtgl36 height={36} width={36} />
          <FormControl fullWidth error={errors?.caseId != null ?? false}>
            <InputLabel id="case-label">
              {t('pages.cases.selectCase')}
            </InputLabel>
            <Select
              labelId="case-label"
              {...register('caseId')}
              label={t('pages.cases.selectCase')}
            >
              {graphData.caseId && (
                <MenuItem key={'root'} value={ROOT}>
                  <MoveToRootString />
                </MenuItem>
              )}
              {cases?.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  <CaseFileIcon caseLabel={c.name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.caseId?.message}</FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      {errorDisplay && (
        <MDialog.Alert
          startIcon={
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ fill: 'none !important' }}
              component={WarningIcon}
            />
          }
        >
          {errorDisplay}
        </MDialog.Alert>
      )}
      <MDialog.Actions
        confirmText={t('common.button.confirm')}
        onCancel={onCancel}
        isLoading={updateGraphMutation.isLoading}
        onConfirm={handleSubmit(moveGraph)}
      />
    </>
  );
};
