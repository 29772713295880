/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */

export type GraphAttachmentVersionProtocol =
  (typeof GraphAttachmentVersionProtocol)[keyof typeof GraphAttachmentVersionProtocol];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GraphAttachmentVersionProtocol = {
  FILES: 'FILES',
  AZURE_BLOB: 'AZURE_BLOB',
} as const;
