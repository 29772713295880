const BSI_HOST = process.env.NEXT_PUBLIC_BUSINESS_INTEGRATION_URL;
const GATEWAY_HOST = process.env.NEXT_PUBLIC_GATEWAY_URL;
export const BSI_ROOT = `${BSI_HOST}/api/protected/`;

const API_URLs = {
  // GW
  roles: `${GATEWAY_HOST}/roles`,
  updateUserRoles: `${GATEWAY_HOST}/roles/user`,
  updateUserAssignments: `${GATEWAY_HOST}/users/batch/assign`,
  products: `${GATEWAY_HOST}/products`,
  myRole: `${GATEWAY_HOST}/roles/mine`,
  organizations: `${GATEWAY_HOST}/accounts`,
  users: `${GATEWAY_HOST}/accounts/users`,
  invitations: `${GATEWAY_HOST}/invitations`,
  rsvps: `${GATEWAY_HOST}/invitations/rsvps`,
  resendRsvp: (invitationId: string) =>
    `${GATEWAY_HOST}/invitations/${invitationId}/resend`,
  resendShadowUserInvitation: `${GATEWAY_HOST}/invitations/shadow-invite`,
  removeInvitation: (invitationId: string) =>
    `${GATEWAY_HOST}/invitations/${invitationId}`,
  setDefaultOrganisation: (organizationId: string) =>
    `${GATEWAY_HOST}/accounts/${organizationId}/default`,
  removeUserByAdmin: (organizationId: string, userId: string) =>
    `${GATEWAY_HOST}/accounts/${organizationId}/users/${userId}`,
  userAcknowledgeRemoval: (organizationId: string) =>
    `${GATEWAY_HOST}/users/self/acknowledge-removal/${organizationId}`,
  userRemoveSelf: (organizationId: string) =>
    `${GATEWAY_HOST}/users/self/organisation/${organizationId}`,
  wsConnection: `${GATEWAY_HOST}/ws-stomp`,
  removeDefaultOrg: `${GATEWAY_HOST}/accounts/default`,
  updateAssignedUsers: `${GATEWAY_HOST}/products/batch/assign`,
  // BSI
  shopProduct: (id: string) => `${BSI_ROOT}products/${id}`,
  shopProducts: `${BSI_ROOT}products`,
  purchaseProduct: `${BSI_ROOT}products/purchase`,
  invoices: `${BSI_ROOT}zuora/invoices`,
  paymentToken: `${BSI_ROOT}payment/payment-token`,
  paymentInfo: `${BSI_ROOT}payment/payment-info`,
  removeCreditCard: (cardId: string) =>
    `${BSI_ROOT}payment/credit-cards/${cardId}`,
  setDefaultCreditCard: (cardId: string) =>
    `${BSI_ROOT}payment/credit-cards/${cardId}/make-default`,
  subscriptions: `${BSI_ROOT}zuora/subscriptions/account`,
  accountContacts: `${BSI_ROOT}zuora/account`,
  getProductInfo: (productId: string) =>
    `${BSI_ROOT}products/${productId}/basic`,
};

export default API_URLs;
