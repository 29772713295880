import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { MTextField, MTextFieldProps } from '@maltego/mui-core';
import useRoles from './useRoles';

interface UserRolesSelectProps extends MTextFieldProps {
  queryRolesURL: string;
}

const UserRolesSelect = ({
  defaultValue,
  onChange,
  label = 'Role',
  error,
  disabled,
  queryRolesURL,
  ...props
}: UserRolesSelectProps) => {
  const { isLoading, data: roles = [] } = useRoles(queryRolesURL);

  if (isLoading) {
    return <MTextField label={label} name="pending-roles" disabled />;
  }

  return (
    <MTextField
      disabled={disabled}
      select
      name="role"
      label={label}
      error={error}
      defaultValue={defaultValue || ''}
      onChange={onChange}
      {...props}
    >
      {roles.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </MTextField>
  );
};

export default UserRolesSelect;
