import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Typography } from '@mui/material';
import { CaseContext } from '../../CasesTable/CasesTable';
import { GraphVersion } from '../../../../graph-store-client/model';

const GraphHistoryVersion = ({
  row,
}: BaseTableCellProps<GraphVersion, CaseContext>) => {
  return (
    <>
      <Box sx={{ display: 'flex', columnGap: '4px' }}>
        <Typography>{row.version}</Typography>
      </Box>
    </>
  );
};
export default GraphHistoryVersion;
