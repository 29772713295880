import { InPageError, MDialog } from '@maltego/mui-core';
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  SxProps,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CaseReport from '../components/cases/CaseReports/CaseReport';
import { MaltegoFileFactory } from '../components/cases/MaltegoFile';
import { ShareCaseDialog } from '../components/cases/members/ShareCaseDialog';
import { CaseManagementDialogStyles } from '../components/cases/dialog/CaseManagementDialog';
import {
  useFindCaseById,
  useGetCaseGraphs,
} from '../graph-store-client/maltegoCaseManagementAPI';
import { CaseMemberRole } from '../graph-store-client/model';
import CaseDetailsMenu from '../components/cases/CaseDetailsMenu';
import CaseGraphsTable from '../components/cases/CaseGraphs/CaseGraphsTable';
import TabPanel from '../components/tabs/TabPanel';
import {
  CaseIcon,
  CaseSharedByMeIcon,
  CaseSharedWithMeIcon,
  ChevronLeft,
  ShareIcon,
} from '../icons';
import {
  iamIOwnerNotSharedWithAny,
  isSharedByMe,
  isSharedWithMe,
} from '../components/cases/CasesTable/utils/CaseAccessUtils';

const outerBoxContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};
const backButtonLabelStyles: SxProps = {
  fontSize: '12px',
  fontWeight: '600',
  fontStyle: 'regular',
  color: 'gray',
};
const headingOuterBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};
const headingInnerBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  gap: '10px',
};
const caseNameLabelStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  color: 'text.primary',
  gap: '8px',
};
const moreOptionsContainerStyles: SxProps = {
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'row',
};
const caseTabStyles: SxProps = {
  textTransform: 'none',
  fontSize: '21px',
  fontWeight: '400',
  fontFamily: 'Roboto',
};

const CaseDetailsPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const caseId = router.query.caseId as string;

  const [anchorEl, _] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: maltegoCase, isLoading: isCaseLoading } =
    useFindCaseById(caseId);
  const { data: graphs, isLoading: isGraphsLoading } = useGetCaseGraphs(
    caseId,
    null,
    { query: { refetchOnWindowFocus: 'always' } }
  );

  const [tabValue, setTabValue] = React.useState(0);
  const [isShareCaseDialogOpen, setIsShareCaseDialogOpen] =
    React.useState(open);

  const { data } = useSession();
  const owner = maltegoCase?.members.find(
    (member) => member.role === CaseMemberRole.OWNER
  );
  const iAmOwner = owner?.userEmail === data?.user?.email;

  const member = maltegoCase?.members.find(
    (member) => member.userEmail === data?.user?.email
  );
  //graphs?.map(graph => graph.userRole = member?.role || CaseMemberRole.READER);

  const graphFiles = useMemo(
    () =>
      (graphs ?? []).map((g) =>
        MaltegoFileFactory.fromGraph(
          g,
          maltegoCase?.members?.find((m) => m.userEmail == member?.userEmail)
            ?.role
        )
      ),
    [member, maltegoCase?.members, graphs]
  );

  const onTabClickHandler = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  // const tabList: Array<TabList> = [
  //     // {label:'Investigations', Component:CaseGraphsTable}
  // ]
  if (isGraphsLoading || isCaseLoading) return <LinearProgress />;
  if (!maltegoCase)
    return (
      <InPageError
        message={t('pages.cases.caseNotExist')}
        secondLine={t('pages.cases.checkUrlReload')}
      />
    );
  if (!graphs) return <h1>No graphs found</h1>;

  return (
    <>
      <Box sx={outerBoxContainerStyles}>
        <Typography sx={backButtonLabelStyles}>
          {t('pages.cases.allCases')}
        </Typography>
        <Box sx={headingOuterBoxStyles} aria-label="breadcrumb">
          <Box sx={headingInnerBoxStyles}>
            <IconButton onClick={() => router.push('/cases')}>
              <ChevronLeft height={20} width={20} />
            </IconButton>
            <Typography variant="h1" sx={caseNameLabelStyles}>
              {/*<SvgCase36 height={36} width={36} />*/}
              {iamIOwnerNotSharedWithAny(
                MaltegoFileFactory.fromCase(maltegoCase, member?.role),
                data || undefined
              ) && <CaseIcon height={42} width={42} />}
              {isSharedByMe(
                MaltegoFileFactory.fromCase(maltegoCase, member?.role),
                data || undefined
              ) && <CaseSharedByMeIcon height={42} width={42} />}
              {isSharedWithMe(
                MaltegoFileFactory.fromCase(maltegoCase, member?.role),
                data || undefined
              ) && <CaseSharedWithMeIcon height={42} width={42} />}
              {maltegoCase.name ?? caseId}
            </Typography>
          </Box>
          <Box sx={moreOptionsContainerStyles}>
            <CaseDetailsMenu
              caseId={maltegoCase.id}
              caseName={maltegoCase.name}
              iAmOwner={iAmOwner}
            />
            <Button
              color={iAmOwner ? 'secondary' : 'primary'}
              variant={iAmOwner ? 'contained' : 'outlined'}
              startIcon={<ShareIcon height={20} width={20} />}
              onClick={() => setIsShareCaseDialogOpen(true)}
            >
              {iAmOwner ? t('pages.cases.share') : t('pages.cases.sharingInfo')}
            </Button>
          </Box>
        </Box>
        {/* <MTabs tabList={tabList}></MTabs>           */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            aria-label="case details"
            value={tabValue}
            indicatorColor="secondary"
            onChange={onTabClickHandler}
          >
            <Tab label="Investigations" sx={caseTabStyles} />
            <Tab label="Report" sx={caseTabStyles} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <CaseGraphsTable graphs={graphFiles} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CaseReport caseId={caseId} />
        </TabPanel>
      </Box>

      <MDialog
        sx={CaseManagementDialogStyles}
        open={isShareCaseDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <ShareCaseDialog
          caseId={maltegoCase.id}
          onConfirm={() => undefined}
          onCancel={() => setIsShareCaseDialogOpen(false)}
        />
      </MDialog>
    </>
  );
};
export default CaseDetailsPage;
