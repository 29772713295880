import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Tooltip, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import { Case, Graph } from '../../../graph-store-client/model';
import { isMaltegoGraphFile } from '../../../types/typeGuards';
import { toPascalCase } from '../../../utilities/string-fomatter';
import { MaltegoFile } from '../MaltegoFile';
import { CaseContext } from '../CasesTable/CasesTable';
import {
  isGraphAuthor,
  resolveGraphAuthorTooltipText,
  resolveGraphRole,
} from '../CasesTable/utils/CaseAccessUtils';

const GraphAccess = ({
  row,
}: BaseTableCellProps<MaltegoFile<Case | Graph>, CaseContext>) => {
  const { data: session } = useSession();
  const iAmGraphAuthor = isGraphAuthor(row, session || undefined);
  const role = iAmGraphAuthor
    ? 'Graph Author'
    : isMaltegoGraphFile(row)
    ? resolveGraphRole(row)
    : '';
  const authorText = isMaltegoGraphFile(row)
    ? resolveGraphAuthorTooltipText(row)
    : undefined;

  return (
    <>
      <Tooltip title={authorText} placement="top-start" arrow>
        <Box sx={{ display: 'flex', columnGap: '10px' }}>
          <Typography sx={{ color: '#303849' }}>
            {toPascalCase(role)}
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};
export default GraphAccess;
