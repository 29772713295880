/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */

export type CaseOrganizationVisibility =
  (typeof CaseOrganizationVisibility)[keyof typeof CaseOrganizationVisibility];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOrganizationVisibility = {
  PRIVATE: 'PRIVATE',
} as const;
