import React, { useRef } from 'react';

import {
  Backdrop,
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PlusCircleIcon from '@/icons/topbar-plus-circle.svg';
import { useTranslation } from 'react-i18next';

import OrganizationSelectorProps from '@/components/organization/OrganizationSelectorProps';
import OrganizationItem from '@/components/organization/OrganizationItem';
import { useToggle } from '@maltego/mui-core';

const listItemOnHoverStyle = {
  '&:hover': {
    color: 'background.default',
    backgroundColor: 'primary.main',
    '.default-home-icon': {
      fill: (theme) => theme.palette.background.default,
    },
  },
};

const OrganizationSelector: React.FC<OrganizationSelectorProps> = ({
  currentOrganization,
  organizations,
  onOrganizationChange,
  onOrganizationCreate,
}) => {
  const { isOpen, onClose, onToggle } = useToggle();
  const clickAwayRef = useRef<HTMLDivElement | undefined>();
  const { t } = useTranslation();
  const openDropdownColor = isOpen ? 'common.white' : undefined;
  const onClickAway = (event) => {
    if (clickAwayRef.current && clickAwayRef.current.contains(event.target)) {
      return;
    }
    onClose();
  };

  const onOrganizationChangeCB = (selectedOrganizationID: string) => {
    onClose();
    onOrganizationChange(selectedOrganizationID);
  };

  const onClickToggleIcon = (event) => {
    // Stop propagation to prevent onClickAway being called,
    // and failing because event target is unmounted
    event.stopPropagation();
    onToggle();
  };

  return (
    <div ref={clickAwayRef}>
      <Box
        sx={{
          position: 'relative', // Allow collapse component to have absolute position
          color: 'primary.dark',
          minHeight: 6,
        }}
      >
        <ListItemButton
          sx={{
            zIndex: 2000,
            height: 36,
            justifyContent: 'center',
            pr: 0.25,
            '.MuiListItemText-root': { flex: '0 1 auto' },
          }}
          onClick={onToggle}
        >
          <ListItemText disableTypography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: openDropdownColor,
              }}
            >
              {currentOrganization ? currentOrganization.name : ''}
            </Typography>
          </ListItemText>
          {isOpen ? (
            <ExpandLess
              onClick={onClickToggleIcon}
              sx={{ color: openDropdownColor }}
            />
          ) : (
            <ExpandMore onClick={onClickToggleIcon} />
          )}
        </ListItemButton>

        <Collapse
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 500,
          }}
          in={isOpen}
        >
          <Paper square variant="elevation">
            <ClickAwayListener onClickAway={onClickAway}>
              <List
                sx={{
                  boxShadow: '3px 5px 0 rgba(0, 0, 0, 0.08)',
                  backgroundColor: 'background.default',
                  color: 'primary.main',
                  overflow: 'auto',
                  maxHeight: '80vh',
                  py: 0,
                  '.MuiListItem-root': {
                    px: 1.75,
                  },
                  mt: 1.9,
                }}
              >
                {organizations.map((organization) => (
                  <ListItem
                    button
                    sx={{
                      backgroundColor:
                        organization.id === currentOrganization?.id
                          ? 'grey.100'
                          : undefined,
                      ...listItemOnHoverStyle,
                    }}
                    key={`${organization.id}-${organization.name}`}
                    onClick={() => onOrganizationChangeCB(organization.id)}
                  >
                    <ListItemText disableTypography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {organization.name}{' '}
                      </Typography>
                    </ListItemText>
                    <OrganizationItem organization={organization} />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={onOrganizationCreate}
                  sx={listItemOnHoverStyle}
                >
                  <SvgIcon
                    sx={{
                      mt: 0.5,
                      mr: 0.5,
                      ml: -1 / 8,
                      color: 'inherit',
                      fontSize: 20,
                    }}
                    viewBox="0 0 20 20"
                    component={PlusCircleIcon}
                  />
                  <ListItemText disableTypography>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 500, color: 'inherit' }}
                    >
                      {t('organization.organizationSelector.addOrganization')}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </ClickAwayListener>
          </Paper>
        </Collapse>
        <Backdrop
          sx={{ backgroundColor: 'rgba(50, 56, 72, 0.45)' }}
          open={isOpen}
          onClick={onClose}
        />
      </Box>
    </div>
  );
};

export default OrganizationSelector;
