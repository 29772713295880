/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccessRoleDto,
  GetRolesForUserParams,
  UserRoleDto,
} from './model';
import { gwRequestHandler } from '../customAxiosInstances';
import type { ErrorType } from '../customAxiosInstances';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const addUserToBeta = (
  organizationId: string,
  userId: string,
  options?: SecondParameter<typeof gwRequestHandler>
) => {
  return gwRequestHandler<void>(
    {
      url: `/organizations/${organizationId}/users/${userId}/beta`,
      method: 'PUT',
    },
    options
  );
};

export const getAddUserToBetaMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToBeta>>,
    TError,
    { organizationId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addUserToBeta>>,
  TError,
  { organizationId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addUserToBeta>>,
    { organizationId: string; userId: string }
  > = (props) => {
    const { organizationId, userId } = props ?? {};

    return addUserToBeta(organizationId, userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserToBetaMutationResult = NonNullable<
  Awaited<ReturnType<typeof addUserToBeta>>
>;

export type AddUserToBetaMutationError = ErrorType<unknown>;

export const useAddUserToBeta = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addUserToBeta>>,
    TError,
    { organizationId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const mutationOptions = getAddUserToBetaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const removeUserFromBeta = (
  organizationId: string,
  userId: string,
  options?: SecondParameter<typeof gwRequestHandler>
) => {
  return gwRequestHandler<void>(
    {
      url: `/organizations/${organizationId}/users/${userId}/beta`,
      method: 'DELETE',
    },
    options
  );
};

export const getRemoveUserFromBetaMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromBeta>>,
    TError,
    { organizationId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeUserFromBeta>>,
  TError,
  { organizationId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeUserFromBeta>>,
    { organizationId: string; userId: string }
  > = (props) => {
    const { organizationId, userId } = props ?? {};

    return removeUserFromBeta(organizationId, userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveUserFromBetaMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeUserFromBeta>>
>;

export type RemoveUserFromBetaMutationError = ErrorType<unknown>;

export const useRemoveUserFromBeta = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeUserFromBeta>>,
    TError,
    { organizationId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const mutationOptions = getRemoveUserFromBetaMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateRoleForUser = (
  userRoleDto: UserRoleDto,
  options?: SecondParameter<typeof gwRequestHandler>
) => {
  return gwRequestHandler<void>(
    {
      url: `/staff-api/roles/user`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userRoleDto,
    },
    options
  );
};

export const getUpdateRoleForUserMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRoleForUser>>,
    TError,
    { data: UserRoleDto },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRoleForUser>>,
  TError,
  { data: UserRoleDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRoleForUser>>,
    { data: UserRoleDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateRoleForUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRoleForUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRoleForUser>>
>;
export type UpdateRoleForUserMutationBody = UserRoleDto;
export type UpdateRoleForUserMutationError = ErrorType<unknown>;

export const useUpdateRoleForUser = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRoleForUser>>,
    TError,
    { data: UserRoleDto },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const mutationOptions = getUpdateRoleForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRolesForUser = (
  params: GetRolesForUserParams,
  options?: SecondParameter<typeof gwRequestHandler>,
  signal?: AbortSignal
) => {
  return gwRequestHandler<UserRoleDto[]>(
    { url: `/roles/user`, method: 'GET', params, signal },
    options
  );
};

export const getGetRolesForUserQueryKey = (params: GetRolesForUserParams) => {
  return [`/roles/user`, ...(params ? [params] : [])] as const;
};

export const getGetRolesForUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getRolesForUser>>,
  TError = ErrorType<unknown>
>(
  params: GetRolesForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRolesForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof gwRequestHandler>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesForUserQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRolesForUser>>> = ({
    signal,
  }) => getRolesForUser(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRolesForUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRolesForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRolesForUser>>
>;
export type GetRolesForUserQueryError = ErrorType<unknown>;

export const useGetRolesForUser = <
  TData = Awaited<ReturnType<typeof getRolesForUser>>,
  TError = ErrorType<unknown>
>(
  params: GetRolesForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRolesForUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof gwRequestHandler>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRolesForUserQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateRoleForUser1 = (
  userRoleDto: UserRoleDto,
  options?: SecondParameter<typeof gwRequestHandler>
) => {
  return gwRequestHandler<void>(
    {
      url: `/roles/user`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userRoleDto,
    },
    options
  );
};

export const getUpdateRoleForUser1MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRoleForUser1>>,
    TError,
    { data: UserRoleDto },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRoleForUser1>>,
  TError,
  { data: UserRoleDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRoleForUser1>>,
    { data: UserRoleDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateRoleForUser1(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRoleForUser1MutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRoleForUser1>>
>;
export type UpdateRoleForUser1MutationBody = UserRoleDto;
export type UpdateRoleForUser1MutationError = ErrorType<unknown>;

export const useUpdateRoleForUser1 = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRoleForUser1>>,
    TError,
    { data: UserRoleDto },
    TContext
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const mutationOptions = getUpdateRoleForUser1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRoles = (
  options?: SecondParameter<typeof gwRequestHandler>,
  signal?: AbortSignal
) => {
  return gwRequestHandler<AccessRoleDto[]>(
    { url: `/roles`, method: 'GET', signal },
    options
  );
};

export const getGetRolesQueryKey = () => {
  return [`/roles`] as const;
};

export const getGetRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoles>>> = ({
    signal,
  }) => getRoles(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRoles>>
>;
export type GetRolesQueryError = ErrorType<unknown>;

export const useGetRoles = <
  TData = Awaited<ReturnType<typeof getRoles>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMyRoles = (
  options?: SecondParameter<typeof gwRequestHandler>,
  signal?: AbortSignal
) => {
  return gwRequestHandler<string[]>(
    { url: `/roles/mine`, method: 'GET', signal },
    options
  );
};

export const getGetMyRolesQueryKey = () => {
  return [`/roles/mine`] as const;
};

export const getGetMyRolesQueryOptions = <
  TData = Awaited<ReturnType<typeof getMyRoles>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getMyRoles>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMyRolesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyRoles>>> = ({
    signal,
  }) => getMyRoles(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMyRoles>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMyRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMyRoles>>
>;
export type GetMyRolesQueryError = ErrorType<unknown>;

export const useGetMyRoles = <
  TData = Awaited<ReturnType<typeof getMyRoles>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getMyRoles>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof gwRequestHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMyRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
