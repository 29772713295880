import { BaseTable, TableColumnDefinition } from '@maltego/mui-core';
import { SxProps, Theme } from '@mui/material';

export const CaseManagementTable = <TRow, TContext>({
  data,
  context,
  columns,
  defaultSortKey = '',
  onTableRowClick,
  rowSx,
}: BaseTableProps<TRow, TContext>) => {
  const rowSxDefault = {
    cursor: 'pointer',
    backgroundColor: 'background.default',
    '&:nth-of-type(odd)': {
      backgroundColor: 'background.default',
    },
    border: '1px solid transparent',
    ' td': {
      borderTop: '1px solid #EDEDED',
    },
    '&:last-of-type': {
      borderBottom: '1px solid #EDEDED',
    },
    '&:hover': {
      boxShadow: '0px',
      border: '1px solid gray',
      ' td': {
        borderTop: '1px solid gray',
        borderBottom: '1px solid gray',
      },
    },
    height: '76px',
  };
  const rowSxMerged = { ...rowSxDefault, ...rowSx };
  return (
    <BaseTable
      data={data}
      context={context}
      columns={columns}
      defaultSortKey={defaultSortKey}
      rowSx={rowSxMerged}
      onTableRowClick={onTableRowClick}
    />
  );
};

/**
 * Delete the below once the core models are exported
 */

interface BaseTableProps<TRow = any, TContext = any> {
  data: TRow[];
  context?: TContext;
  columns: TableColumnDefinition[];
  defaultSortKey?: string;
  onTableRowClick?: (rowData: any, rowIndex: number) => void;
  rowSx?:
    | ((rowData: object, rowIndex: number) => SxProps<Theme>)
    | SxProps<Theme>;
}
