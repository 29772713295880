// Reference: https://github.com/mui-org/material-ui/blob/master/examples/nextjs-with-typescript/src/pages/_app.tsx
import React, { useEffect } from 'react';

import { I18nextProvider } from 'react-i18next';
import i18n from '@/locales/i18n';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // By default, React Query Devtools are not included in production bundles

import { QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import type { AppProps } from 'next/app';
import { setLocale } from 'yup';

import CssBaseline from '@mui/material/CssBaseline';

import '../fonts.css';
import '../custom-cookie-banner.css';

import { CustomerPortalOrganizationProvider } from '@/context/CustomerOrganizationProvider/CustomerPortalOrganizationProvider';
import {
  ColorAndThemeProvider,
  createEmotionCache,
  CustomSession,
  EnableSentryUserTracking,
  GoogleTagManagerScript,
  KeycloakAuthProvider,
  MaltegoLogoDarkSmall,
  NavigationProvider,
  SnackbarProvider,
  TopBarLogo,
  yupLocale,
} from '@maltego/ui';
import { darkTheme, lightTheme } from '@/constants/theme';
import Error500 from '@/pages/500';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { RoleContextProvider } from '@/context/RoleProvider/RoleProvider';
import { useRouter } from 'next/router';
import Head from 'next/head';
import CustomerTopBar from '@/layout/CustomerTopBar/CustomerTopBar';
import CustomerSideNav from '@/layout/CustomerSideNav/CustomerSideNav';
import getQueryClient from '@/constants/reactQuery';
import { AppLayout } from '@maltego/mui-core';
import { SessionProvider } from 'next-auth/react';
import { initializeSentry } from '../../sentry.client.config';

setLocale(yupLocale);

export const queryClient = getQueryClient();
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  session: CustomSession;
}

const Wrapper: React.FC<{
  children: React.ReactNode;
  emotionCache: EmotionCache;
  session: CustomSession;
}> = ({ children, emotionCache, session }) => {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>Maltego Portal</title>
      </Head>
      <GoogleTagManagerScript />
      <NavigationProvider>
        <ColorAndThemeProvider
          darkTheme={darkTheme}
          lightTheme={lightTheme}
          defaultMode="light"
        >
          <CssBaseline />
          <I18nextProvider i18n={i18n}>
            <Sentry.ErrorBoundary fallback={<Error500 />}>
              <QueryClientProvider client={queryClient}>
                <SnackbarProvider>
                  <SessionProvider session={session}>
                    <KeycloakAuthProvider>
                      {children}
                      <EnableSentryUserTracking />
                    </KeycloakAuthProvider>
                  </SessionProvider>
                </SnackbarProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </Sentry.ErrorBoundary>
          </I18nextProvider>
        </ColorAndThemeProvider>
      </NavigationProvider>
    </CacheProvider>
  );
};

const SentryErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag('location', '_app');
    }}
    fallback={<Error500 />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

const WrapperForAccountPages: React.FC<{
  children: React.ReactNode;
  hideSideNav: boolean;
  hideTopBar: boolean;
  emotionCache: EmotionCache;
  session: CustomSession;
}> = ({ children, hideSideNav, hideTopBar, emotionCache, session }) => {
  return (
    <Wrapper emotionCache={emotionCache} session={session}>
      <CustomerPortalOrganizationProvider>
        <RoleContextProvider>
          <AppLayout
            hideSideNav={hideSideNav}
            topBarElement={<CustomerTopBar hideTopBar={hideTopBar} />}
            sideNavElement={<CustomerSideNav />}
            contentElement={
              <SentryErrorBoundary>{children}</SentryErrorBoundary>
            }
          />
        </RoleContextProvider>
      </CustomerPortalOrganizationProvider>
    </Wrapper>
  );
};

const WrapperForAccountlessPages: React.FC<{
  children: React.ReactNode;
  hideSideNav: boolean;
  emotionCache: EmotionCache;
  session: CustomSession;
}> = ({ children, hideSideNav, emotionCache, session }) => {
  return (
    <Wrapper emotionCache={emotionCache} session={session}>
      <AppLayout
        hideSideNav={hideSideNav}
        topBarElement={
          <TopBarLogo
            label="layout.customerTopBar.title"
            logo={MaltegoLogoDarkSmall}
          />
        }
        sideNavElement={<CustomerSideNav />}
        contentElement={<SentryErrorBoundary>{children}</SentryErrorBoundary>}
      />
    </Wrapper>
  );
};

function MyApp(props: MyAppProps): JSX.Element {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    session,
  } = props;
  const router = useRouter();
  const pathName = router.pathname;

  const hideSideNav = pageProps.hideSideNav || false;
  const hideTopBar = pageProps.hideTopBar || false;

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', function (e) {
      if (Cookiebot.consent.statistics) {
        initializeSentry();
      }
    });
  }, []);

  if (pathName.toLowerCase().startsWith('/rsvp')) {
    return (
      <WrapperForAccountlessPages
        hideSideNav={hideSideNav}
        emotionCache={emotionCache}
        session={session}
      >
        <Component {...pageProps} />
      </WrapperForAccountlessPages>
    );
  }

  // this will disable authentication for static js files (necessary for hotjar recordings)
  if (pathName.toLowerCase().match('/_next/static/chunks/pages/')) {
    return <Component {...pageProps} />;
  }

  return (
    <WrapperForAccountPages
      hideSideNav={hideSideNav}
      hideTopBar={hideTopBar}
      emotionCache={emotionCache}
      session={session}
    >
      <Component {...pageProps} />
    </WrapperForAccountPages>
  );
}

export default MyApp;
