import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';

import { DialogContent } from '@mui/material';
import {
  appendMutationVariablesToQueryData,
  SnackBarDuration,
  useOrganization,
  useSnackbar,
  useStandardForm,
  useStandardMutation,
} from '@maltego/ui';
import { useTranslation } from 'react-i18next';
import API_URLs from '@/utils/apiURLs';
import { MDialog, MTextField } from '@maltego/mui-core';
import { AccountDto } from '@portal/api/src/gw/model';

const getNewQueryStateForRename =
  // Update existing list of organizations to have new name
  (currentOrganization) => (oldOrganizations, nameMutation) =>
    oldOrganizations.map((organization) => {
      if (organization.id === currentOrganization.id) {
        return {
          ...organization,
          name: nameMutation.name,
        };
      }
      return organization;
    });

interface OrganizationRenameMutation {
  name: string;
}

const schema = yup.object({
  name: yup.string().required().min(3, 'yup.minLength'),
});

type CreateOrRenameOrganizationForm = yup.InferType<typeof schema>;

const CreateOrRenameOrganizationDialog: React.FC<{
  isForRenaming?: boolean;
  onClose: () => void;
}> = ({ isForRenaming = false, onClose }) => {
  const { currentOrganization, setCurrentOrganizationID } = useOrganization();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const defaultName = isForRenaming ? currentOrganization.name : '';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useStandardForm<CreateOrRenameOrganizationForm>(schema, {
    name: defaultName,
  });

  const getNewQueryStateFn = useMemo(() => {
    if (isForRenaming) {
      return getNewQueryStateForRename(currentOrganization);
    }
    return appendMutationVariablesToQueryData;
  }, [currentOrganization, isForRenaming]);

  const mutation = useStandardMutation<OrganizationRenameMutation, AccountDto>({
    queryKey: API_URLs.organizations,
    mutationMethod: isForRenaming ? 'PUT' : 'POST',
    getNewQueryState: getNewQueryStateFn,
    onSuccess: ({ data }) => {
      if (!isForRenaming) {
        setCurrentOrganizationID(data.id);
      }

      showSnackbar(
        t(
          isForRenaming
            ? 'organization.CreateOrRenameOrganizationDialog.renameSuccess'
            : 'organization.CreateOrRenameOrganizationDialog.createSuccess'
        ),
        'success',
        SnackBarDuration.STANDARD
      );
      onClose();
    },
  });

  const onConfirm = useCallback(
    ({ name }) => {
      const mutationBody = {
        name: name,
      };

      if (isForRenaming) {
        // @ts-ignore
        mutationBody.id = currentOrganization.id;
      }
      mutation.mutate(mutationBody);
    },
    [isForRenaming, mutation, currentOrganization.id]
  );

  return (
    <>
      <MDialog.Title onClose={onClose}>
        {t(
          isForRenaming
            ? 'organization.CreateOrRenameOrganizationDialog.renameOrganization'
            : 'organization.CreateOrRenameOrganizationDialog.createOrganization'
        )}
      </MDialog.Title>
      <DialogContent>
        <MTextField
          label="Name"
          error={errors.name?.message}
          {...register('name')}
        />
      </DialogContent>
      <MDialog.Actions
        isLoading={mutation.isLoading}
        errorMessage={mutation.error?.displayMessage}
        onCancel={onClose}
        onConfirm={handleSubmit(onConfirm)}
      />
    </>
  );
};

export default CreateOrRenameOrganizationDialog;
