import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import SquareIcon from '@/icons/topbar-square.svg';
import HomeIcon from '@/icons/topbar-mini-home.svg';
import API_URLs from '@/utils/apiURLs';
import {
  SnackBarDuration,
  useSnackbar,
  useStandardMutation,
} from '@maltego/ui';
import { AccountDto } from '@portal/api/src/gw/model';

const HoverHomeIcon = () => (
  <Box
    className="hover-home-icon"
    sx={{
      display: 'flex',
      height: 16,
      width: 16,
      borderRadius: 2,
      backgroundColor: 'background.default',
    }}
  >
    <SvgIcon
      component={HomeIcon}
      sx={(theme) => ({
        fill: theme.palette.primary.main,
      })}
    />
  </Box>
);

const OrganizationItem: React.FC<{
  organization: AccountDto;
}> = ({ organization }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const setDefaultOrg = useStandardMutation<string, AccountDto>({
    mutationKey: API_URLs.setDefaultOrganisation(organization.id),
    mutationMethod: 'POST',
    onSuccess: () => {
      showSnackbar(
        t('topbar.OrganizationItem.defaultOrganisationUpdated'),
        'success',
        SnackBarDuration.STANDARD
      );
    },
    queryKey: API_URLs.organizations,
    onError: (error) => {
      console.log(error);
      showSnackbar(
        t('topbar.OrganizationItem.defaultOrganisationNotUpdated'),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });

  const removeDefaultOrg = useStandardMutation<string, AccountDto>({
    mutationKey: API_URLs.removeDefaultOrg,
    mutationMethod: 'DELETE',
    onSuccess: () => {
      showSnackbar(
        t('topbar.OrganizationItem.removeDefaultOrganisationUpdated'),
        'success',
        SnackBarDuration.STANDARD
      );
    },
    queryKey: API_URLs.organizations,
    onError: (error) => {
      console.log(error);
      showSnackbar(
        t('topbar.OrganizationItem.removeDefaultOrganisationNotUpdated'),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });

  const onDefaultButtonClick = (e) => {
    e.stopPropagation(); // Prevent switching to organization
    if (!organization.defaultOrg) {
      setDefaultOrg.mutate(organization.id);
    } else if (organization.defaultOrg) {
      removeDefaultOrg.mutate(organization.id);
    }
  };

  return (
    <Tooltip
      title={
        organization.defaultOrg
          ? t('topbar.OrganizationItem.removeDefaultOrganization')
          : t('topbar.OrganizationItem.setAsDefault')
      }
      placement="top"
      arrow
    >
      <IconButton
        onClick={onDefaultButtonClick}
        disableRipple
        sx={{
          p: 0,
          '.hover-home-icon': {
            display: 'none',
          },
          '&:hover': {
            '.hover-home-icon': {
              display: 'flex',
            },
            '.empty-square-icon': {
              display: 'none',
            },
            '.default-home-icon': {
              display: 'none',
            },
          },
        }}
      >
        {organization.defaultOrg ? (
          <SvgIcon
            className="default-home-icon"
            viewBox="0 0 16 16"
            component={HomeIcon}
            sx={(theme) => ({
              fontSize: 16,
              fill: theme.palette.grey[400],
            })}
          />
        ) : (
          <SvgIcon
            className="empty-square-icon"
            viewBox="0 0 16 16"
            component={SquareIcon}
            sx={{
              fontSize: 16,
              fill: 'none',
            }}
          />
        )}
        <HoverHomeIcon />
      </IconButton>
    </Tooltip>
  );
};

export default OrganizationItem;
