import { MDialog } from '@maltego/mui-core';
import { Add } from '@mui/icons-material';
import { Alert, Button, LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseManagementDialogStyles } from '../components/cases/dialog/CaseManagementDialog';
import { useSession } from 'next-auth/react';
import {
  useGetAllCases,
  useGetAllGraphs,
} from '../graph-store-client/maltegoCaseManagementAPI';
import {
  MaltegoFile,
  MaltegoFileFactory,
} from '../components/cases/MaltegoFile';
import { Case, CaseMemberRole, Graph } from '../graph-store-client/model';
import { sortBy } from '../utilities/sortBy';
import EmptyState from '../components/table/EmptyState';
import { Folder56 } from '../icons';
import CasesTable from '../components/cases/CasesTable/CasesTable';
import { CreateCaseDialog } from '../components/cases/dialog/CreateCaseDialog';

const CasesPage = () => {
  const {
    data: cases,
    isLoading: casesLoading,
    refetch: refetchCases,
  } = useGetAllCases();

  const {
    data: graphs,
    isLoading: graphsLoading,
    refetch: refetchGraphs,
  } = useGetAllGraphs(
    { unassigned: true },
    { query: { refetchOnWindowFocus: 'always' } }
  );

  const { t } = useTranslation();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const { data: session } = useSession();

  const files: MaltegoFile<Case | Graph>[] = useMemo(
    () => [
      ...sortBy(
        (cases ?? []).map((c) =>
          MaltegoFileFactory.fromCase(
            c,
            c.members?.find((m) => m.userEmail == session?.user?.email)?.role
          )
        ),
        'name'
      ),
      ...sortBy(
        (graphs ?? []).map((g) =>
          MaltegoFileFactory.fromGraph(g, CaseMemberRole.OWNER)
        ),
        'name'
      ),
    ],
    [cases, graphs, session?.user?.email]
  );

  const casesLoadingError = cases === undefined && !casesLoading;
  const graphsLoadingError = graphs === undefined && !graphsLoading;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="h1" sx={{ flex: '1 0 auto' }}>
          {t('pages.cases.allCases')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            pr: 4,
            color: '#757982',
          }}
        >
          {t('pages.cases.noOfItems', {
            noOfItems: files.length,
          })}
        </Typography>
        <Button
          startIcon={<Add />}
          color={'secondary'}
          variant={'contained'}
          onClick={() => setIsCreateDialogOpen(true)}
        >
          {t('pages.cases.newCase')}
        </Button>
      </Box>
      {(casesLoading || graphsLoading) && <LinearProgress />}
      {casesLoadingError && (
        <Alert
          severity={'error'}
          action={<Button onClick={() => refetchCases()}>Retry</Button>}
        >
          {t('pages.cases.casesLoadingError')}
        </Alert>
      )}
      {graphsLoadingError && (
        <Alert
          severity={'error'}
          action={<Button onClick={() => refetchGraphs()}>Retry</Button>}
        >
          {t('pages.cases.graphsLoadingError')}
        </Alert>
      )}
      {!files || files.length == 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="100%"
          sx={{ mt: '15%' }}
        >
          <EmptyState
            icon={Folder56}
            title="No cases have been created"
            description="Please create a new case to get started"
            isButtonShown
            buttonLabel={t('pages.cases.newCase')}
            onClick={() => setIsCreateDialogOpen(true)}
          />
        </Box>
      ) : (
        <CasesTable cases={files} />
      )}
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isCreateDialogOpen}
        size={'md'}
      >
        <CreateCaseDialog
          onConfirm={() => setIsCreateDialogOpen(false)}
          onCancel={() => setIsCreateDialogOpen(false)}
        />
      </MDialog>
    </Box>
  );
};

export default CasesPage;
