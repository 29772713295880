import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import {
  CaseIcon,
  CaseSharedByMeIcon,
  CaseSharedWithMeIcon,
  Mtgl36,
} from '../../../icons';
import { Case, Graph } from '../../../graph-store-client/model';
import { isMaltegoGraphFile } from '../../../types/typeGuards';
import { MaltegoFile } from '../MaltegoFile';
import { CaseContext } from './CasesTable';
import {
  iamIOwnerNotSharedWithAny,
  isLocked,
  isLockOwner,
  isSharedByMe,
  isSharedWithMe,
} from './utils/CaseAccessUtils';

const CaseName = ({
  row,
}: BaseTableCellProps<MaltegoFile<Case | Graph>, CaseContext>) => {
  const { data } = useSession();
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
        {isMaltegoGraphFile(row) &&
          isLocked(row) &&
          !isLockOwner(row, data || undefined) && (
            <Mtgl36
              height={36}
              width={36}
              style={{ marginLeft: '6px', opacity: '0.3' }}
            />
          )}
        {isMaltegoGraphFile(row) &&
          (!isLocked(row) || isLockOwner(row, data || undefined)) && (
            <Mtgl36 height={36} width={36} style={{ marginLeft: '6px' }} />
          )}

        {!isMaltegoGraphFile(row) &&
          iamIOwnerNotSharedWithAny(row, data || undefined) && (
            <CaseIcon height={52} width={52} />
          )}
        {!isMaltegoGraphFile(row) && isSharedByMe(row, data || undefined) && (
          <CaseSharedByMeIcon height={52} width={52} />
        )}
        {!isMaltegoGraphFile(row) && isSharedWithMe(row, data || undefined) && (
          <CaseSharedWithMeIcon height={52} width={52} />
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '16px',
              color: '#303849',
              fontStyle: isMaltegoGraphFile(row) ? 'normal' : 'bold',
              fontWeight: isMaltegoGraphFile(row) ? '400' : '700',
            }}
          >
            {' '}
            {row.name}
          </Typography>
          <Typography sx={{ fontSize: '12px', color: '#757982' }}>
            {' '}
            {
              isMaltegoGraphFile(row) && isLocked(row)
                ? isLockOwner(row, data || undefined)
                  ? 'Currently being edited by you...'
                  : 'Currently being edited...'
                : row.updatedAt
              // new Intl.DateTimeFormat("en-US", {
              //   dateStyle: "full",
              //   timeStyle: "long"
              // }).format(new Date(row.updatedAt))
            }
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default CaseName;
