export enum MemberStatusEnum {
  verified = 'VERIFIED',
  invited = 'INVITED',
  shadow = 'SHADOW',
}

export default interface InvitedOrExistingMember {
  // Signed-up user belonging to an organization
  id: string;
  name: string; // FIXME: This is populated with a user ID, not a readable name
  firstname?: string;
  lastname?: string;
  email: string;
  roles: string[];
  status: MemberStatusEnum;
  products: string[]; // FIXME: Why is this includedq
}

export interface InvitedUser {
  id?: string;
  invitee: {
    email: string;
    name?: string;
  };
  roles: string[];
}
