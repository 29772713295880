import { MCheckbox, MDialog } from '@maltego/mui-core';
import { DialogContent, DialogContentText } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetAllCasesQueryKey,
  useDeleteCaseById,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { Case } from '../../../graph-store-client/model';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface DeleteCaseDialogProps {
  caseId: string;
  caseName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteCaseDialog = ({
  caseId,
  caseName,
  onConfirm,
  onCancel,
}: DeleteCaseDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteCaseMutation = useDeleteCaseById();
  const deleteCaseByIdPayload = { caseId };
  const { showSnackbar } = useSnackbar();

  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const confirmDeleteHandler = () => {
    deleteCaseMutation.mutate(deleteCaseByIdPayload, {
      onSuccess: () => {
        const successMessage = `Case ${caseName} is deleted successfully.`;
        showSnackbar(successMessage, 'warning', SnackBarDuration.STANDARD);

        queryClient.setQueryData<Case[]>(getGetAllCasesQueryKey(), (cases) => [
          ...(cases?.filter((c) => c.id !== caseId) || []),
        ]);
        onConfirm();
      },
    });
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>Confirm Delete</MDialog.Title>

      <DialogContent>
        <DialogContentText>
          <MCheckbox
            value={isConfirmChecked}
            color="error"
            sx={{ pl: 0 }}
            onClick={(e) => setIsConfirmChecked(!isConfirmChecked)}
          />
          Are you sure you want to delete the case: <b>{caseName}</b>?
        </DialogContentText>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.delete')}
        isConfirmDisabled={!isConfirmChecked}
        onCancel={onCancel}
        onConfirm={confirmDeleteHandler}
        isLoading={deleteCaseMutation.isLoading}
        confirmBtnColor="error"
      />
    </>
  );
};
export default DeleteCaseDialog;
