/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */

export type GraphVersionProtocol =
  (typeof GraphVersionProtocol)[keyof typeof GraphVersionProtocol];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GraphVersionProtocol = {
  FILES: 'FILES',
  DB: 'DB',
} as const;
