import React from 'react';
import { Button, SvgIcon } from '@mui/material';
import { InviteUsersIcon } from '../../icons';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface InviteUsersButtonProps {
  onClick: () => void;
  sx: SxProps;
}

const InviteUsersButton = ({ onClick, sx }: InviteUsersButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="secondary"
      sx={sx}
      onClick={onClick}
      startIcon={
        <SvgIcon
          component={InviteUsersIcon}
          sx={{
            fill: 'none',
            fontSize: '20px',
          }}
        />
      }
    >
      {t('components.InviteUsersDrawer.inviteUsers')}
    </Button>
  );
};

export default InviteUsersButton;
