import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import TiesCutIcon from '@/icons/ties-cut.svg';
import API_URLs from '@/utils/apiURLs';
import {
  HeadPortal,
  SnackBarDuration,
  useOrganization,
  useSnackbar,
  useStandardMutation,
} from '@maltego/ui';
import { AccountDto } from '@portal/api/src/gw/model';

const BrokenConnectionError: React.FC<{
  disconnectedOrg: AccountDto;
  isTheOnlyOrganization: boolean;
}> = ({ disconnectedOrg, isTheOnlyOrganization }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { setCurrentOrganizationID } = useOrganization();

  const onMutationFail = useCallback(() => {
    showSnackbar(
      t('users.snackbar.createNewOrganizationError'),
      'error',
      SnackBarDuration.STANDARD
    );
  }, [showSnackbar, t]);

  const acknowledgeRemoval = useStandardMutation({
    mutationKey: API_URLs.userAcknowledgeRemoval(disconnectedOrg.id),
    mutationMethod: 'POST',
    onSuccess: () => {
      setCurrentOrganizationID();
    },
    queryKey: API_URLs.organizations,
    onError: onMutationFail,
  });

  return (
    <>
      <HeadPortal title={t('disconnection.BrokenConnection.headTitle')} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
      >
        <Box sx={{ mt: -4 }} maxWidth={500} textAlign="center">
          <SvgIcon
            component={TiesCutIcon}
            sx={{
              fontSize: 64,
              mb: 3,
            }}
            viewBox="0 0 64 64"
          />
          <Typography variant="h1" sx={{ mb: 2 }}>
            {t('disconnection.BrokenConnection.title')}
          </Typography>
          <Typography
            variant="h1"
            sx={{ mb: 2, fontSize: 16, fontWeight: 400, lineHeight: 1.3 }}
          >
            {t('disconnection.BrokenConnection.description', {
              OrgName: disconnectedOrg.name,
            })}
            {isTheOnlyOrganization
              ? t('disconnection.BrokenConnection.createNewOranization')
              : t('disconnection.BrokenConnection.clickContinue')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
            }}
            onClick={() => acknowledgeRemoval.mutate(disconnectedOrg.id)}
          >
            {isTheOnlyOrganization
              ? t('disconnection.BrokenConnection.createOrganizationBtn')
              : t('common.button.continue')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BrokenConnectionError;
