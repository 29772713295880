import { MCheckbox, MDialog } from '@maltego/mui-core';
import { DialogContent, DialogContentText } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetGraphHistoryQueryKey,
  useRestoreGraphHistory,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface RestoreGraphHistoryDialogProps {
  graphId: string;
  version: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const RestoreGraphHistoryDialog = ({
  graphId,
  version,
  onConfirm,
  onCancel,
}: RestoreGraphHistoryDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const restoreGraphHistoryMutation = useRestoreGraphHistory();
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const { showSnackbar } = useSnackbar();

  const confirmRestoreGraphHandler = () => {
    const restoreGraphHistoryPayload = { graphId, version };
    restoreGraphHistoryMutation.mutate(restoreGraphHistoryPayload, {
      onSuccess: () => {
        const successMessage = t(
          'components.RestoreGraphHistoryDialog.restoreSuccessMessage',
          { version }
        );
        showSnackbar(successMessage, 'warning', SnackBarDuration.STANDARD);
        void queryClient.invalidateQueries({
          queryKey: getGetGraphHistoryQueryKey(graphId),
        });
        onConfirm();
      },
      onError: (error) => {
        showSnackbar(
          error.response?.data.detail as string,
          'error',
          SnackBarDuration.LONG
        );
        onConfirm();
      },
    });
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <Trans i18nKey="components.RestoreGraphHistoryDialog.title" />
      </MDialog.Title>

      <DialogContent>
        <DialogContentText>
          <MCheckbox
            value={isConfirmChecked}
            color="warning"
            sx={{ pl: 0 }}
            onClick={() => setIsConfirmChecked(!isConfirmChecked)}
          />
          <Trans
            i18nKey="components.RestoreGraphHistoryDialog.warningMessage"
            values={{ version }}
          />
        </DialogContentText>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.restore')}
        isConfirmDisabled={!isConfirmChecked}
        onCancel={onCancel}
        onConfirm={confirmRestoreGraphHandler}
        isLoading={restoreGraphHistoryMutation.isLoading}
        confirmBtnColor="error"
      />
    </>
  );
};
export default RestoreGraphHistoryDialog;
