import { Session } from 'next-auth';
import {
  Case,
  CaseMemberRole,
  Graph,
} from '../../../../graph-store-client/model';
import { MaltegoFile } from '../../MaltegoFile';
import {
  isMaltegoCaseFile,
  isMaltegoGraphFile,
} from '../../../../types/typeGuards';

export function resolveRole(maltegoFile: MaltegoFile<Case>, session?: Session) {
  if (!session || !session.user || !session.user?.email) {
    return '';
  }
  const role = maltegoFile.members.find(
    (m) => m.userEmail === session.user?.email
  );
  return role ? role.role : '';
}

export function resolveGraphRole(maltegoFile: MaltegoFile<Graph>) {
  return maltegoFile.userRole;
}

export function isShared(
  maltegoFile: MaltegoFile<Case>,
  session?: Session
): boolean {
  const amIOwner = isCaseOwner(maltegoFile, session);
  if (amIOwner) {
    return maltegoFile.members.some((m) => m.userEmail != session?.user?.email);
  } else {
    return maltegoFile.members.some((m) => m.role !== CaseMemberRole.OWNER);
  }
}
export function isLocked(maltegoFile: MaltegoFile<Graph>): boolean {
  return maltegoFile.lock != null;
}

export function isSharedWithMe(
  maltegoFile: MaltegoFile<Case>,
  session?: Session
): boolean {
  const amIOwner = isCaseOwner(maltegoFile, session);
  if (!amIOwner) {
    return maltegoFile.members.some((m) => m.userEmail == session?.user?.email);
  }
  return false;
}
export function isSharedByMe(
  maltegoFile: MaltegoFile<Case>,
  session?: Session
): boolean {
  const amIOwner = isCaseOwner(maltegoFile, session);
  if (amIOwner) {
    return maltegoFile.members.some((m) => m.userEmail != session?.user?.email);
  }
  return false;
}
export function iamIOwnerNotSharedWithAny(
  maltegoFile: MaltegoFile<Case>,
  session?: Session
): boolean {
  const amIOwner = isCaseOwner(maltegoFile, session);
  if (amIOwner) {
    return (
      maltegoFile.members.filter((m) => m.userEmail != session?.user?.email)
        .length === 0
    );
  }
  return false;
}

export function resolveMembersTooltipText(
  maltegoFile: MaltegoFile<Case>,
  session?: Session
): string {
  const caseOwner = maltegoFile.members.find(
    (member) => member.role === CaseMemberRole.OWNER
  );
  const sharedWith = maltegoFile.members
    .filter((m) => m.userEmail != session?.user?.email)
    .map((m) => m.userEmail)
    .join(', ');
  const sharedByOther = maltegoFile.members
    .filter((m) => m.userEmail != caseOwner?.userEmail)
    .map((m) => m.userEmail)
    .join(', ');
  return session?.user?.email === caseOwner?.userEmail
    ? sharedWith
      ? `Shared with: ${sharedWith}`
      : ''
    : sharedByOther
    ? `Shared with: ${sharedByOther}`
    : '';
}

export function resolveOwnerTooltipText(
  maltegoFile: MaltegoFile<Case>
): string {
  const caseOwner = maltegoFile.members.find(
    (member) => member.role === CaseMemberRole.OWNER
  );
  return caseOwner ? `Owner: ${caseOwner?.userEmail}` : '';
}

export function resolveGraphAuthorTooltipText(
  maltegoFile: MaltegoFile<Graph>
): string {
  const graphAuthor = maltegoFile.ownerEmail;
  return graphAuthor ? `Graph Author: ${graphAuthor}` : '';
}

export function resolveLockOwnerTooltipText(
  maltegoFile: MaltegoFile<Graph>
): string {
  const lockedBy = maltegoFile.lock?.owner;
  return `This file is locked. Currently edited by: ${lockedBy}`;
}

export function isCaseContributor(
  maltegoFile: MaltegoFile<Case | Graph>,
  userSession?: Session
) {
  if (isMaltegoCaseFile(maltegoFile)) {
    return maltegoFile.members
      ? maltegoFile.members.find(
          (member: { role: string; userEmail: string }) =>
            member.userEmail === userSession?.user?.email &&
            member.role === CaseMemberRole.CONTRIBUTOR
        )
      : null;
  } else {
    return maltegoFile.userRole === CaseMemberRole.CONTRIBUTOR;
  }
}

export function isCaseOwner(
  maltegoFile: MaltegoFile<Case | Graph>,
  userSession?: Session
) {
  if (isMaltegoCaseFile(maltegoFile)) {
    return maltegoFile.members
      ? maltegoFile.members.find(
          (member: { role: string; userEmail: string }) =>
            member.userEmail === userSession?.user?.email &&
            member.role === CaseMemberRole.OWNER
        )
      : null;
  } else {
    return maltegoFile.userRole === CaseMemberRole.OWNER;
  }
}

export function isLockOwner(
  maltegoFile: MaltegoFile<Case | Graph>,
  userSession?: Session
) {
  if (isMaltegoGraphFile(maltegoFile)) {
    return maltegoFile.lock?.owner === userSession?.user?.email;
  }
}

export function isGraphAuthor(
  maltegoFile: MaltegoFile<Case | Graph>,
  userSession?: Session
) {
  if (isMaltegoGraphFile(maltegoFile)) {
    return maltegoFile.ownerEmail === userSession?.user?.email;
  }
}
