import { getToken } from 'next-auth/jwt';
import axios from 'axios';

export const getKeycloakLogoutUrl = async (req) => {
  const token = await getToken({ req });

  let keycloakLogoutUrl = `${
    process.env.KEYCLOAK_ISSUER
  }/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(
    process.env.NEXTAUTH_URL
  )}`;

  if (token?.id_token) {
    keycloakLogoutUrl = keycloakLogoutUrl + `&id_token_hint=${token.id_token}`;
  }
  keycloakLogoutUrl =
    keycloakLogoutUrl + `&client_id=${process.env.KEYCLOAK_ID}`;

  return keycloakLogoutUrl;
};

export const backgroundKeycloakLogoutEndpoint = async (req, res) => {
  /**
   * Make a get request to Keycloak, to perform a partial logout from Keykloak BUT NOT B2C.
   */
  try {
    await axios.get(await getKeycloakLogoutUrl(req));
    res.status(200).json({ message: 'Succesfully logged the user out' });
  } catch (error) {
    console.log(error);
    res.status(200).json({ message: 'Failed to log out user.' }); // Still return 200 code as there is nothing we can do.
  }
};

export const keycloakRedirectLogoutEndpoint = async (req, res) => {
  /**
   * Provide a URL that the user can be redirected to complete a full logout, from both keycloak and b2c.
   */
  res.status(200).json({ keycloakLogoutUrl: await getKeycloakLogoutUrl(req) });
};
