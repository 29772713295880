import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Button, Typography } from '@mui/material';
import { RedirectURL } from '../../types';

interface SharedSubscriptionLinkProps {
  isLinkInTableCell?: boolean;
  handleCellHoverEvent?: () => void;
  onButtonClick?: () => void;
  viewSubscriptionUrl?: RedirectURL;
}

const SharedSubscriptionLink: React.FC<SharedSubscriptionLinkProps> = ({
  isLinkInTableCell,
  handleCellHoverEvent,
  onButtonClick,
  viewSubscriptionUrl,
}) => {
  const { t } = useTranslation();
  const subscriptionLinkText = isLinkInTableCell
    ? `· ${t('components.SharedSubscriptionLink.subscription')} `
    : t('components.SharedSubscriptionLink.subscriptionDetails') + ' ›';
  const subscriptionLinkColor = isLinkInTableCell
    ? 'grey.600'
    : 'textLink.main';
  const subscriptionLinkSize = isLinkInTableCell ? 'body2' : 'body1';

  if (viewSubscriptionUrl) {
    return (
      <Link
        href={viewSubscriptionUrl}
        style={{
          textDecoration: 'none',
        }}
      >
        <Typography
          onMouseEnter={handleCellHoverEvent ?? undefined}
          onMouseLeave={handleCellHoverEvent ?? undefined}
          variant={subscriptionLinkSize}
          sx={{
            cursor: 'pointer',
            color: subscriptionLinkColor,
            my: isLinkInTableCell ? 0 : 1,
          }}
        >
          {subscriptionLinkText}
        </Typography>
      </Link>
    );
  }

  return (
    <Button
      onClick={onButtonClick}
      onMouseEnter={handleCellHoverEvent ?? undefined}
      onMouseLeave={handleCellHoverEvent ?? undefined}
      size="small"
      sx={{
        minWidth: 0,
        height: 0,
        px: 0,
        fontFamily: (theme) => theme.typography.fontFamily,
        fontSize: (theme) => theme.typography[subscriptionLinkSize],
        textTransform: 'none',
        color: subscriptionLinkColor,
        '&:hover': {
          color: subscriptionLinkColor,
          backgroundColor: 'transparent',
        },
      }}
    >
      {subscriptionLinkText}
    </Button>
  );
};

export default SharedSubscriptionLink;
