import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SvgIcon, Typography } from '@mui/material';
import { BaseError, BaseErrorButton, Title } from '@maltego/mui-core';
import { ClockIcon, LogInIcon } from '../../icons';
import { useAuthentication } from '@maltego/common-contexts';

interface ExpiredSessionErrorProps {
  colorMode?: 'light' | 'dark';
  description?: string;
  title?: string;
}

const ExpiredSessionError: React.FC<ExpiredSessionErrorProps> = ({
  colorMode = 'light',
  title,
  description,
}) => {
  const { t } = useTranslation();
  const titleMessage = t(
    title || 'components.ExpiredSessionError.sessionExpired'
  );
  const descriptionMessage = t(
    description || 'components.ExpiredSessionError.sessionExpiredDescription'
  );

  const { login } = useAuthentication();

  return (
    <>
      <Title>{t('components.ExpiredSessionError.headTitle')}</Title>
      <BaseError maltegoLogo backgroundColor="grey.200" colorMode={colorMode}>
        <SvgIcon
          viewBox="0 0 67 67"
          sx={{ fill: 'none', fontSize: '66px', mb: 1 }}
          component={ClockIcon}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            sx={{ mt: 1.5, mb: 2, fontSize: 27, color: 'primary.main' }}
          >
            {titleMessage}
          </Typography>
          <Typography variant="body1" sx={{ mb: -1, color: 'primary.main' }}>
            {descriptionMessage}
          </Typography>
          <BaseErrorButton
            onClick={login}
            startIcon={
              <SvgIcon
                viewBox="0 0 16 16"
                sx={{ fill: 'primary.contrastText' }}
                component={LogInIcon}
              />
            }
            variant="contained"
          >
            {t('components.ExpiredSessionError.logInAgain')}
          </BaseErrorButton>
        </Box>
      </BaseError>
    </>
  );
};

export default ExpiredSessionError;
