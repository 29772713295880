import { SnackBarDuration, useSnackbar } from '../../contexts';
import { useTranslation } from 'react-i18next';
import { useStandardMutation } from '../../utils';
import { useMutationFunc } from '../../utils/reactQuery';
import { useMutation } from '@tanstack/react-query';

const useResendInvitationMutation = (
  resendRsvpToUserURL: string,
  onClose: () => void
) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useStandardMutation({
    queryKey: resendRsvpToUserURL,
    onSuccess: () => {
      onClose();
      showSnackbar(
        t('components.useResendInvitationMutation.resendInvitationSuccess'), // TODO: Allow for translation keys to be passed directly to snackbar
        'success',
        SnackBarDuration.STANDARD
      );
    },
    onError: () => {
      showSnackbar(
        t('components.useResendInvitationMutation.resendInvitationFailed'),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });
};

export default useResendInvitationMutation;
export const useResendShadowInvitationMutation = (
  resendShadowMutationURL: string,
  shadowUserEmail: string,
  onClose: () => void
) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const mutationFunc = useMutationFunc<null>(resendShadowMutationURL, 'POST', {
    shadowUserEmail: shadowUserEmail,
  });
  return useMutation<undefined, undefined, null>(mutationFunc, {
    onSuccess: () => {
      onClose();
      showSnackbar(
        t('components.useResendInvitationMutation.resendInvitationSuccess'),
        'success',
        SnackBarDuration.STANDARD
      );
    },
    onError: () => {
      showSnackbar(
        t('components.useResendInvitationMutation.resendInvitationFailed'),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });
};
