import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import dayjs from 'dayjs';
import {
  Avatar,
  Box,
  Button,
  Divider,
  SvgIcon,
  Typography,
} from '@mui/material';
import ProductChip from '../Product/ProductChip';
import { RedirectURL } from '../../types';
import {
  isProductDesktopOrTool,
  MALTEGO_PRODUCT_DESCRIPTION,
} from '../../utils';
import { AssignedUser, MaltegoProductLogo } from '../../icons';
import { Product } from '../../types';
import { ProductTypeEnum } from '../../types/Product';
import BaseTag from '../BaseTag/BaseTag';

const MAX_SYMBOLS_ALLOWED = 190;

interface ProductEditDrawerHeaderProps {
  isUserAdmin: boolean;
  numAssignedUsers: number;
  product: Product;
  queryProductInfoURL: (string) => string;
  viewSubscriptionUrl: RedirectURL;
  userEmail?: string;
}

const ProductEditDrawerHeader: React.FC<ProductEditDrawerHeaderProps> = ({
  isUserAdmin,
  numAssignedUsers,
  product,
  queryProductInfoURL,
  viewSubscriptionUrl,
  userEmail,
}) => {
  const { t } = useTranslation();
  const [isTruncateDescription, setIsTruncateDescription] = useState(true);
  const isMaltegoLicense = isProductDesktopOrTool(product);
  const isMaltegoDesktop = product.product.type === ProductTypeEnum.DESKTOP;
  const isProductAssignedToUser = product.assignees.some(
    (assignee) => assignee.email === userEmail
  );
  const isFutureStartDate =
    product.entitlementPeriod.validFrom * 1000 > Date.now();

  const description = MALTEGO_PRODUCT_DESCRIPTION;

  const isDescShorterThanMax = description.length < MAX_SYMBOLS_ALLOWED;

  const onReadMoreOrLessClick = useCallback(
    () => setIsTruncateDescription(!isTruncateDescription),
    [isTruncateDescription]
  );

  return (
    <>
      {isMaltegoLicense ? (
        <SvgIcon
          sx={{
            width: '62px',
            height: '62px',
          }}
          viewBox="0 0 39 39"
          fill="none"
          component={MaltegoProductLogo}
        />
      ) : null}

      <Box sx={{ display: 'flex', my: 1.5 }}>
        <ProductChip
          productId={product.product.id}
          productName={product.productPlan.name}
          sx={{ fontSize: '18px', py: 5 / 8 }}
        />
        {isFutureStartDate && (
          <BaseTag
            sx={{ ml: 0.5, py: 0.25 }}
            tagDescription={t('components.ProductEditDrawerHeader.startsSoon')}
          />
        )}
      </Box>

      {isMaltegoDesktop && (
        <Box
          sx={{
            display: 'inline',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: 'inline',
              color: 'grey.700',
              lineHeight: '20px',
              mr: 0.5,
            }}
          >
            {isDescShorterThanMax || !isTruncateDescription
              ? description
              : `${description.slice(0, MAX_SYMBOLS_ALLOWED)}...`}
          </Typography>
          {!isDescShorterThanMax && (
            <Button
              size="small"
              sx={{
                minWidth: 0,
                height: 0,
                color: 'grey.500',
                pb: 1 / 8,
                px: 0,
                fontFamily: (theme) => theme.typography.fontFamily,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={onReadMoreOrLessClick}
            >
              {isTruncateDescription
                ? t('components.ProductEditDrawerHeader.more')
                : t('components.ProductEditDrawerHeader.less')}
            </Button>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 5,
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isUserAdmin ? (
            <>
              <Avatar
                sx={{
                  backgroundColor: 'grey.800',
                  width: '25px',
                  height: '25px',
                  fontSize: '14px',
                }}
              >
                {numAssignedUsers}
              </Avatar>
              <Typography variant="body1" color="grey.600" sx={{ mx: 0.5 }}>
                {t('components.ProductEditDrawerHeader.totalSeats', {
                  totalSeats:
                    product.productPlan.seats >= 1000000
                      ? t('components.ProductEditDrawerHeader.unlimitedSeats')
                      : product.productPlan.seats,
                })}
              </Typography>
              <Typography variant="body1">
                {t('components.ProductEditDrawerHeader.assigned')}
              </Typography>
            </>
          ) : isProductAssignedToUser ? (
            <>
              <SvgIcon
                sx={{ fontSize: 15, mr: 0.5 }}
                component={AssignedUser}
                viewBox="0 0 17 17"
              />
              <Typography variant="body1">
                {t('components.ProductEditDrawerHeader.assignedToYou')}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">
              {t('components.ProductEditDrawerHeader.notAssignedToYou')}
            </Typography>
          )}
        </Box>
        <Typography variant="body1">
          {isFutureStartDate
            ? t('components.ProductEditDrawerHeader.starts')
            : t('components.ProductEditDrawerHeader.expiresOrRenews')}
          :{' '}
          {dayjs(
            new Date(
              (isFutureStartDate
                ? product.entitlementPeriod.validFrom
                : product.entitlementPeriod.validUntil) * 1000
            )
          ).format('MMM DD, YYYY')}
        </Typography>
      </Box>
      <Divider sx={{ borderColor: 'grey.800' }} />

      {isUserAdmin && viewSubscriptionUrl && (
        <Link href={viewSubscriptionUrl} style={{ textDecoration: 'none' }}>
          <Typography
            variant="body1"
            sx={{
              my: 1,
              color: 'textLink.main',
              cursor: 'pointer',
            }}
          >
            {t('components.ProductEditDrawerHeader.subscriptionDetails')} ›
          </Typography>
        </Link>
      )}
    </>
  );
};

export default ProductEditDrawerHeader;
