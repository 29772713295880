import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import assignColor from './colorAssignments';
import { useColorMode } from '../../contexts';
import { darkModeProductColors, lightModeProductColors } from './productColors';
import { SxProps } from '@mui/system';

interface IProductChipProps {
  productName: string;
  tooltip?: string;
  productId: string;
  sx?: SxProps;
}

const ProductChip: React.FC<IProductChipProps> = ({
  productName,
  tooltip,
  productId,
  sx,
}) => {
  const { colorMode } = useColorMode();
  const paletteColor =
    colorMode === 'light' ? lightModeProductColors : darkModeProductColors;

  const assignedColorToProduct = assignColor(paletteColor, productId);
  const isMaltegoProduct = productName.toLowerCase().startsWith('maltego');

  if (!productName) {
    return null;
  }

  return (
    <Box
      sx={{
        flex: '0 1 auto',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        borderColor: assignedColorToProduct.borderColor + ' !important',
        border: 'none',
        borderTop: 1,
        borderBottom: 1,
        mr: 0.25,
        ':hover': {
          flexShrink: 0,
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: paletteColor.text,
          backgroundColor: assignedColorToProduct.backgroundColor,
          fontSize: 13.5,
          px: 0.5,
          py: 0.375,
          textTransform: isMaltegoProduct ? 'uppercase' : 'none',
          fontWeight: isMaltegoProduct ? 700 : 400,
          ...sx,
        }}
      >
        {productName}
      </Typography>
    </Box>
  );
};

export default ProductChip;
