import { Error403 } from '@maltego/mui-core';
import { useAuthentication } from '@maltego/common-contexts';

const CustomerError403 = () => {
  const { logout } = useAuthentication();

  return <Error403 logout={logout} />;
};

export default CustomerError403;
