import React from 'react';
import { Chip, SvgIcon } from '@mui/material';
import { Box } from '@mui/system';
import { CloseAlertIcon } from '../../icons';

interface ValidatedEmailChipProps {
  email: string;
  onClick: () => void;
  onDelete: () => void;
}

const DiagonalLeftCorner = () => (
  <Box
    sx={{
      position: 'relative',
      border: 'none',
      width: 12,
      flexShrink: 0,
      overflow: 'hidden',
    }}
  >
    <Box
      className="email-chip-corner"
      sx={{
        backgroundColor: 'white',
        borderLeft: 1,
        borderColor: 'grey.300',
        width: 15,
        height: 15,
        position: 'absolute',
        top: 11,
        left: 4,
        zIndex: 1,
        transform: 'rotateZ(45deg)',
      }}
    />
    <Box
      className="email-chip-corner"
      sx={{
        backgroundColor: 'white',
        borderLeft: 1,
        borderBottom: 1,
        borderColor: 'grey.300',
        position: 'absolute',
        top: 19,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
  </Box>
);

const ValidatedEmailChip: React.FC<ValidatedEmailChipProps> = ({
  email,
  onClick,
  onDelete,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        cursor: 'pointer',
        '.email-chip-corner, .MuiChip-root': {
          backgroundColor: '#fff',
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&:hover .email-chip-corner, &:hover .MuiChip-root': {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      <DiagonalLeftCorner />
      <Chip
        label={email}
        variant="outlined"
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={
          <SvgIcon
            viewBox="0 0 24 24"
            sx={{
              color: 'primary.main',
              fill: 'none',
            }}
            component={CloseAlertIcon}
          />
        }
        sx={{
          border: 1,
          borderLeft: 0,
          borderRadius: 0,
          borderColor: 'grey.300',
          backgroundColor: 'white',
          pl: 0,
          m: 0,
          mt: 1,
          mr: 1,
          span: {
            fontSize: 14,
            pl: 0,
          },
        }}
      />
    </Box>
  );
};

export default ValidatedEmailChip;
