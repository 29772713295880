import React from 'react';
import { SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { BetaIcon } from '../../icons';

interface TopBarLogoProps {
  label: string;
  logo: React.ElementType;
  isBeta?: boolean;
}

const TopBarLogo: React.FC<TopBarLogoProps> = ({
  label,
  logo,
  isBeta = false,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SvgIcon
        width="42"
        height="42"
        sx={{
          width: 42,
          height: 42,
        }}
        viewBox="0 0 42 42"
        fill="none"
        component={logo}
      />
      <Typography
        variant="h2"
        color="text.primary"
        sx={{
          pl: 0.875,
          textTransform: 'uppercase',
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        {t(label)}
      </Typography>
      {isBeta && (
        <SvgIcon
          sx={{
            width: 26,
            height: 22,
            color: 'textLink.main',
          }}
          viewBox="-4 0 26 22"
          component={BetaIcon}
        />
      )}
    </Box>
  );
};

export default TopBarLogo;
