import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const TopBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}): JSX.Element => (
  <div>
    <AppBar
      elevation={0}
      sx={{
        position: 'relative',
        zIndex: 'drawer',
        backgroundColor: 'background.default',
        borderBottom: 1,
        borderColor: 'grey.A200',
      }}
    >
      <Toolbar
        disableGutters={true}
        sx={{
          pl: 1,
          pr: 1.625,
          display: 'fixed',
          justifyContent: 'space-between',
          minHeight: 58,
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  </div>
);

export default TopBar;
