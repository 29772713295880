import type { EditorThemeClasses } from 'lexical';

import './MaltegoEditorTheme.css';

const theme: EditorThemeClasses = {
  blockCursor: 'MaltegoEditorTheme__blockCursor',
  characterLimit: 'MaltegoEditorTheme__characterLimit',
  code: 'MaltegoEditorTheme__code',
  codeHighlight: {
    atrule: 'MaltegoEditorTheme__tokenAttr',
    attr: 'MaltegoEditorTheme__tokenAttr',
    boolean: 'MaltegoEditorTheme__tokenProperty',
    builtin: 'MaltegoEditorTheme__tokenSelector',
    cdata: 'MaltegoEditorTheme__tokenComment',
    char: 'MaltegoEditorTheme__tokenSelector',
    class: 'MaltegoEditorTheme__tokenFunction',
    'class-name': 'MaltegoEditorTheme__tokenFunction',
    comment: 'MaltegoEditorTheme__tokenComment',
    constant: 'MaltegoEditorTheme__tokenProperty',
    deleted: 'MaltegoEditorTheme__tokenProperty',
    doctype: 'MaltegoEditorTheme__tokenComment',
    entity: 'MaltegoEditorTheme__tokenOperator',
    function: 'MaltegoEditorTheme__tokenFunction',
    important: 'MaltegoEditorTheme__tokenVariable',
    inserted: 'MaltegoEditorTheme__tokenSelector',
    keyword: 'MaltegoEditorTheme__tokenAttr',
    namespace: 'MaltegoEditorTheme__tokenVariable',
    number: 'MaltegoEditorTheme__tokenProperty',
    operator: 'MaltegoEditorTheme__tokenOperator',
    prolog: 'MaltegoEditorTheme__tokenComment',
    property: 'MaltegoEditorTheme__tokenProperty',
    punctuation: 'MaltegoEditorTheme__tokenPunctuation',
    regex: 'MaltegoEditorTheme__tokenVariable',
    selector: 'MaltegoEditorTheme__tokenSelector',
    string: 'MaltegoEditorTheme__tokenSelector',
    symbol: 'MaltegoEditorTheme__tokenProperty',
    tag: 'MaltegoEditorTheme__tokenProperty',
    url: 'MaltegoEditorTheme__tokenOperator',
    variable: 'MaltegoEditorTheme__tokenVariable',
  },
  embedBlock: {
    base: 'MaltegoEditorTheme__embedBlock',
    focus: 'MaltegoEditorTheme__embedBlockFocus',
  },
  hashtag: 'MaltegoEditorTheme__hashtag',
  heading: {
    h1: 'MaltegoEditorTheme__h1',
    h2: 'MaltegoEditorTheme__h2',
    h3: 'MaltegoEditorTheme__h3',
    h4: 'MaltegoEditorTheme__h4',
    h5: 'MaltegoEditorTheme__h5',
    h6: 'MaltegoEditorTheme__h6',
  },
  image: 'editor-image',
  indent: 'MaltegoEditorTheme__indent',
  link: 'MaltegoEditorTheme__link',
  list: {
    listitem: 'MaltegoEditorTheme__listItem',
    listitemChecked: 'MaltegoEditorTheme__listItemChecked',
    listitemUnchecked: 'MaltegoEditorTheme__listItemUnchecked',
    nested: {
      listitem: 'MaltegoEditorTheme__nestedListItem',
    },
    olDepth: [
      'MaltegoEditorTheme__ol1',
      'MaltegoEditorTheme__ol2',
      'MaltegoEditorTheme__ol3',
      'MaltegoEditorTheme__ol4',
      'MaltegoEditorTheme__ol5',
    ],
    ul: 'MaltegoEditorTheme__ul',
  },
  ltr: 'MaltegoEditorTheme__ltr',
  mark: 'MaltegoEditorTheme__mark',
  markOverlap: 'MaltegoEditorTheme__markOverlap',
  paragraph: 'MaltegoEditorTheme__paragraph',
  quote: 'MaltegoEditorTheme__quote',
  rtl: 'MaltegoEditorTheme__rtl',
  table: 'MaltegoEditorTheme__table',
  tableAddColumns: 'MaltegoEditorTheme__tableAddColumns',
  tableAddRows: 'MaltegoEditorTheme__tableAddRows',
  tableCell: 'MaltegoEditorTheme__tableCell',
  tableCellActionButton: 'MaltegoEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer:
    'MaltegoEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'MaltegoEditorTheme__tableCellEditing',
  tableCellHeader: 'MaltegoEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'MaltegoEditorTheme__tableCellPrimarySelected',
  tableCellResizer: 'MaltegoEditorTheme__tableCellResizer',
  tableCellSelected: 'MaltegoEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'MaltegoEditorTheme__tableCellSortedIndicator',
  tableResizeRuler: 'MaltegoEditorTheme__tableCellResizeRuler',
  tableSelected: 'MaltegoEditorTheme__tableSelected',
  text: {
    bold: 'MaltegoEditorTheme__textBold',
    code: 'MaltegoEditorTheme__textCode',
    italic: 'MaltegoEditorTheme__textItalic',
    strikethrough: 'MaltegoEditorTheme__textStrikethrough',
    subscript: 'MaltegoEditorTheme__textSubscript',
    superscript: 'MaltegoEditorTheme__textSuperscript',
    underline: 'MaltegoEditorTheme__textUnderline',
    underlineStrikethrough: 'MaltegoEditorTheme__textUnderlineStrikethrough',
  },
};

export default theme;
