import React from 'react';
import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Tooltip, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import { Case, Graph } from '../../../graph-store-client/model';
import { AvatarAccessIcon } from '../../../icons';
import {
  isMaltegoCaseFile,
  isMaltegoGraphFile,
} from '../../../types/typeGuards';
import { toPascalCase } from '../../../utilities/string-fomatter';
import { MaltegoFile } from '../MaltegoFile';
import { CaseContext } from './CasesTable';
import {
  isCaseOwner,
  isGraphAuthor,
  isShared,
  resolveGraphRole,
  resolveMembersTooltipText,
  resolveOwnerTooltipText,
  resolveRole,
} from './utils/CaseAccessUtils';

const CaseAccess = ({
  row,
}: BaseTableCellProps<MaltegoFile<Case | Graph>, CaseContext>) => {
  const { data } = useSession();
  const amICaseOwner = isCaseOwner(row, data || undefined);
  const myRole = isMaltegoCaseFile(row)
    ? resolveRole(row, data || undefined)
    : '';
  const isSharedFile = isMaltegoCaseFile(row)
    ? isShared(row, data || undefined)
    : false;
  const membersText = isMaltegoCaseFile(row)
    ? resolveMembersTooltipText(row, data || undefined)
    : undefined;
  const sharedByOwner = isMaltegoCaseFile(row)
    ? resolveOwnerTooltipText(row)
    : undefined;

  const iAmGraphAuthor = isMaltegoGraphFile(row)
    ? isGraphAuthor(row, data || undefined)
    : false;
  const myGraphRole = iAmGraphAuthor
    ? 'Graph Author'
    : isMaltegoGraphFile(row)
    ? resolveGraphRole(row)
    : '';
  const role = myRole || myGraphRole;

  return (
    <>
      {amICaseOwner && (
        <Tooltip title={membersText} placement="top-start" arrow>
          <Box sx={{ display: 'flex', columnGap: '10px' }}>
            <Typography sx={{ color: '#303849' }}>
              {toPascalCase(role)}
            </Typography>
            {isSharedFile && <AvatarAccessIcon height="16" width="16" />}
          </Box>
        </Tooltip>
      )}
      {!amICaseOwner && (
        <Tooltip
          title={
            <>
              {sharedByOwner} <br /> {membersText}{' '}
            </>
          }
          placement="top-start"
          arrow
        >
          <Box sx={{ display: 'flex', columnGap: '10px' }}>
            <Typography sx={{ color: '#303849' }}>
              {toPascalCase(role)}
            </Typography>
            {isSharedFile && <AvatarAccessIcon height="16" width="16" />}
          </Box>
        </Tooltip>
      )}
    </>
  );
};
export default CaseAccess;
