import { MCheckbox, MDialog, WarningIcon } from '@maltego/mui-core';
import { Box, DialogContent, DialogContentText, SvgIcon } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useUnlockGraph,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface UnlockGraphDialogProps {
  caseId?: string;
  graphId: string;
  graphName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const UnlockGraphDialog = ({
  caseId,
  graphId,
  graphName,
  onConfirm,
  onCancel,
}: UnlockGraphDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const unlockGraphMutation = useUnlockGraph();
  const { showSnackbar } = useSnackbar();
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);

  const confirmUnlockHandler = () => {
    const unlockGraphPayload = { graphId };
    unlockGraphMutation.mutate(unlockGraphPayload, {
      onSuccess: () => {
        const successMessage = t(
          'components.UnlockGraphDialog.unlockSuccessMessage',
          { graphName }
        );
        showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);

        if (caseId) {
          void queryClient.invalidateQueries({
            queryKey: getGetCaseGraphsQueryKey(caseId),
          });
        } else {
          void queryClient.invalidateQueries({
            queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
          });
        }
        onConfirm();
      },
      onError: (error) => {
        showSnackbar(
          error.response?.data.detail as string,
          'error',
          SnackBarDuration.LONG
        );
        onConfirm();
      },
    });
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <Trans i18nKey="components.UnlockGraphDialog.unlockTheGraph" />
      </MDialog.Title>
      <MDialog.Alert
        startIcon={
          <SvgIcon
            viewBox="0 0 24 24"
            sx={{ fill: 'none !important' }}
            component={WarningIcon}
          />
        }
      >
        <Trans i18nKey="components.UnlockGraphDialog.alertMessage" />
      </MDialog.Alert>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 16, color: 303849 }}>
          <Trans
            i18nKey="components.UnlockGraphDialog.warningMessage"
            values={{ graphName }}
          />
        </DialogContentText>
        <Box height={15} />
        <MCheckbox
          value={isConfirmChecked}
          color="error"
          sx={{ fontSize: 14, pl: 0 }}
          onClick={(e) => setIsConfirmChecked(!isConfirmChecked)}
        />
        <Trans i18nKey="components.UnlockGraphDialog.checkboxLabel" />
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.unlock')}
        isConfirmDisabled={!isConfirmChecked}
        onCancel={onCancel}
        onConfirm={confirmUnlockHandler}
        isLoading={unlockGraphMutation.isLoading}
        confirmBtnColor="error"
      />
    </>
  );
};
export default UnlockGraphDialog;
