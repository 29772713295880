import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { HexagonIcon } from '../../icons';
import { useAuthentication } from '@maltego/common-contexts';

interface UserAvatarProps {
  isActive: boolean;
  onClick: () => void;
}

const UserAvatar = React.forwardRef<HTMLButtonElement, UserAvatarProps>(
  (props, ref) => {
    const { isActive, onClick } = props;
    const { claims } = useAuthentication();

    let nameParts: string[] = [];
    if (claims && claims.family_name && claims.given_name) {
      const splitFamilyNameLastPart = (claims?.family_name as string)
        .split(' ')
        .slice(-1);

      nameParts = [claims.given_name as string, ...splitFamilyNameLastPart];
    } else if (claims && claims.name) {
      nameParts = claims.name.split(' ');
    }

    return (
      <IconButton
        ref={ref}
        disableRipple
        color="inherit"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={onClick}
        sx={(theme) => ({
          color: 'text.primary',
          zIndex: 2000,
          '&:hover .MuiTypography-root ': {
            color: 'primary.contrastText',
          },
          '&:hover svg': {
            fill: theme.palette.grey[800],
          },
          svg: {
            fill: isActive ? theme.palette.primary.main : undefined,
          },
        })}
      >
        <HexagonIcon width={42} />
        <Typography
          variant="body1"
          component="span"
          sx={{
            fontWeight: 'bold',
            position: 'absolute',
            top: 23,
            lineHeight: 0.125,
            ml: 0.125,
            color: isActive ? 'primary.contrastText' : undefined,
          }}
        >
          {nameParts.length > 0 &&
            `${nameParts[0][0]}.${nameParts[nameParts.length - 1][0]}.`}
        </Typography>
      </IconButton>
    );
  }
);

UserAvatar.displayName = 'UserAvatar';
export default UserAvatar;
