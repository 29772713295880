import { InfoIcon, MDialog, TableColumnDefinition } from '@maltego/mui-core';
import { DialogContent, DialogContentText, SvgIcon } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  getGetGraphHistoryQueryKey,
  useGetGraphHistory,
  useUpdateGraphHistory,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import {
  Graph,
  GraphVersion,
  GraphVersionUpdate,
} from '../../../graph-store-client/model';
import { CaseManagementTable } from '../../table/CaseManagementTable/CaseManagementTable';
import { CaseGraphsContext } from '../CaseGraphs/CaseGraphsTable';
import TabPanel from '../../../components/tabs/TabPanel';
import GraphHistoryDate from '../../../components/cases/CaseGraphs/GraphHistory/GraphHistoryDate';
import GraphHistoryTag from '../../../components/cases/CaseGraphs/GraphHistory/GraphHistoryTag';
import GraphHistoryVersion from '../../../components/cases/CaseGraphs/GraphHistory/GraphHistoryVersion';
import GraphHistoryMoreOptions from '../../../components/cases/CaseGraphs/GraphHistory/GraphHistoryMoreOptions';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';
import { useQueryClient } from '@tanstack/react-query';
import { MaltegoFile } from '../MaltegoFile';
import { useSession } from 'next-auth/react';

const COLUMNS: TableColumnDefinition<GraphVersion, CaseGraphsContext>[] = [
  {
    key: 'version',
    title: 'SN',
    align: 'left',
    render: GraphHistoryVersion,
  },
  {
    key: 'authorEmail',
    title: 'Author',
    align: 'center',
  },
  {
    key: 'createdAt',
    title: 'Created At',
    align: 'center',
    render: GraphHistoryDate,
  },
  {
    key: 'tag',
    title: 'Tag',
    align: 'center',
    render: GraphHistoryTag,
  },
  {
    key: '',
    title: 'Action',
    align: 'right',
    render: GraphHistoryMoreOptions,
  },
];
const rowSx = {
  cursor: 'pointer',
};

interface GraphHistoryTableProps {
  graphHistory: GraphVersion[] | undefined;
}

const GraphHistoryTable = ({ graphHistory }: GraphHistoryTableProps) => {
  return (
    <>
      {graphHistory && (
        <CaseManagementTable
          data={graphHistory}
          columns={COLUMNS}
          rowSx={rowSx}
        />
      )}
    </>
  );
};

interface GraphHistoryDialogProps {
  graph: MaltegoFile<Graph>;
  graphId: string;
  graphName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const GraphHistoryTableDialog = ({
  graph,
  graphId,
  graphName,
  onConfirm,
  onCancel,
}: GraphHistoryDialogProps) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { data: session } = useSession();
  const { data: graphHistory, isLoading: isGraphsLoading } =
    useGetGraphHistory(graphId);

  const graphHistoryTagMutation = useUpdateGraphHistory();

  const saveTagsHandler = () => {
    graphHistory?.forEach((graphHistoryRecord) => {
      if (
        graphHistoryRecord.tag != null &&
        graphHistoryRecord.authorEmail === session?.user?.email
      ) {
        const graphId = graphHistoryRecord.graphId;
        const version = graphHistoryRecord.version;
        const data: GraphVersionUpdate = { tag: graphHistoryRecord.tag };

        const graphHistoryTagPayload = { graphId, version, data };
        graphHistoryTagMutation.mutate(graphHistoryTagPayload, {
          onSuccess: () => {
            // void queryClient.invalidateQueries({ queryKey: getGetGraphHistoryQueryKey(graphId) });
            void queryClient.invalidateQueries({
              queryKey: getGetGraphHistoryQueryKey(graphId),
            });
            onConfirm();
          },
          onError: (error) => {
            showSnackbar(
              error.response?.data.detail as string,
              'error',
              SnackBarDuration.LONG
            );
            onConfirm();
          },
        });
      }
    });
    const successMessage = `All modified tags are saved successfully.`;
    showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <b>{graphName}</b> : Graph Version History
      </MDialog.Title>
      <MDialog.Alert
        startIcon={
          <SvgIcon
            viewBox="0 0 24 24"
            sx={{ fill: 'none !important' }}
            component={InfoIcon}
          />
        }
      >
        You can modify Tag only for versions created by You
      </MDialog.Alert>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 14, color: 303849 }}>
          {/*Trans Component necessary for bold font in translation*/}
          <Trans>{t('pages.graphHistory.graphHistoryIntro')}</Trans>
          <p />
        </DialogContentText>
        <TabPanel value={tabValue} index={0}>
          <GraphHistoryTable graphHistory={graphHistory} />
        </TabPanel>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.saveTags')}
        onCancel={onCancel}
        onConfirm={saveTagsHandler}
        isLoading={graphHistoryTagMutation.isLoading}
      />
    </>
  );
};
export default GraphHistoryTableDialog;
