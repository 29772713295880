import { BaseTableCellProps, MDialog } from '@maltego/mui-core';
import { MoreHoriz } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareCaseDialog } from '../members/ShareCaseDialog';
import { CaseManagementDialogStyles } from '../dialog/CaseManagementDialog';
import {
  DeleteIcon,
  ShareIcon,
  UnlockIcon,
  VersionHistoryIcon,
  EditIcon,
  ExternalLink,
  MoveIcon,
  DuplicateIcon,
} from '../../../icons';
import { Case, Graph } from '../../../graph-store-client/model';
import { isMaltegoGraphFile } from '../../../types/typeGuards';
import DeleteCaseDialog from '../dialog/DeleteCaseDialog';
import DeleteGraphDialog from '../dialog/DeleteGraphDialog';
import { MaltegoFile } from '../MaltegoFile';
import { MoveGraphDialog } from '../dialog/MoveGraphDialog';
import { RenameCaseDialog } from '../dialog/RenameCaseDialog';
import { RenameGraphDialog } from '../dialog/RenameGraphDialog';
import { CaseContext } from './CasesTable';
import {
  isCaseOwner,
  isGraphAuthor,
  isLocked,
  isLockOwner,
} from './utils/CaseAccessUtils';
import UnlockGraphDialog from '../dialog/UnlockGraphDialog';
import GraphHistoryTableDialog from '../dialog/GraphHistoryTableDialog';
import { useTableRowClickCaseGraph } from '../CaseAndGraphTableRowHook';
import openGraphInWebViewer from '../links/openGraphInWebViewer';
import DuplicateGraphDialog from '../dialog/DuplicateGraphDialog';

const CaseMoreOptions = ({
  row,
}: BaseTableCellProps<MaltegoFile<Case | Graph>, CaseContext>) => {
  const { data } = useSession();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isRenameCaseDialogOpen, setIsRenameCaseDialogOpen] =
    React.useState<boolean>(open);
  const [isRenameGraphDialogOpen, setIsRenameGraphDialogOpen] =
    React.useState<boolean>(open);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = React.useState<boolean>(open);
  const [isShareCaseDialogOpen, setIsShareCaseDialogOpen] =
    React.useState(open);
  const [isDeleteCaseDialogOpen, setIsDeleteCaseDialogOpen] =
    React.useState(open);
  const [isDeleteGraphDialogOpen, setIsDeleteGraphDialogOpen] =
    React.useState(open);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] =
    React.useState<boolean>(open);
  const [isUnlockGraphDialogOpen, setIsUnlockGraphDialogOpen] =
    React.useState(open);
  const [isGraphHistoryDialogOpen, setIsGraphHistoryDialogOpen] =
    React.useState(open);

  const iAmCaseOwner = isCaseOwner(row, data || undefined);
  const iAmGraphAuthor = isGraphAuthor(row, data || undefined);
  const iAmLockOwner = isLockOwner(row, data || undefined);
  const isFileLocked = isMaltegoGraphFile(row) ? isLocked(row) : false;
  const disableUnlockGraph = !(iAmGraphAuthor || iAmLockOwner) || !isFileLocked;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const rename = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    isMaltegoGraphFile(row)
      ? setIsRenameGraphDialogOpen(true)
      : setIsRenameCaseDialogOpen(true);
  };
  const move = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsMoveDialogOpen(true);
  };

  // TODO: REACTIVATE WHEN PROFILER IS RUNNING ON SERVER
  // const profiler = (event:React.MouseEvent<HTMLElement>) => {
  //     handleClose(event);
  //     window.open(process.env.NEXT_PUBLIC_OSINT_PROFILER_URL, '_blank')
  // }

  const share = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsShareCaseDialogOpen(true);
  };
  const deleteCase = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsDeleteCaseDialogOpen(true);
  };
  const deleteGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsDeleteGraphDialogOpen(true);
  };

  const onEditOrOpenClicked = useTableRowClickCaseGraph();
  const editInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    onEditOrOpenClicked(row);
  };
  const viewInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    onEditOrOpenClicked(row);
  };
  const openInWebViewer = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    openGraphInWebViewer(row.id);
  };
  const unlockGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsUnlockGraphDialogOpen(true);
  };
  const duplicateGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsDuplicateDialogOpen(true);
  };
  const graphHistory = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsGraphHistoryDialogOpen(true);
  };
  return (
    <>
      <div>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>
        {/* <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={ {".MuiPaper-root": {backgroundColor: 'background.default', boxShadow:'3'}}}> */}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={(event) => event.stopPropagation()}
        >
          {isMaltegoGraphFile(row) && (
            <MenuItem disabled={!iAmGraphAuthor} onClick={editInMaltego}>
              <ListItemIcon>
                <ExternalLink height={20} width={20} />
              </ListItemIcon>
              <ListItemText> {t('pages.cases.editInMaltego')}</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem onClick={viewInMaltego}>
              <ListItemIcon>
                <ExternalLink height={20} width={20} />
              </ListItemIcon>
              <ListItemText> {t('pages.cases.viewInMaltego')}</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem onClick={openInWebViewer}>
              <ListItemIcon>
                <ExternalLink height={20} width={20} />
              </ListItemIcon>
              <ListItemText> {t('pages.cases.openInWebViewer')}</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem onClick={graphHistory}>
              <ListItemIcon>
                <VersionHistoryIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>{t('pages.cases.graphHistory')}</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem disabled={disableUnlockGraph} onClick={unlockGraph}>
              <ListItemIcon>
                <UnlockIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>Unlock Graph</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem onClick={move}>
              <ListItemIcon>
                <MoveIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>{t('pages.cases.move')}</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem onClick={duplicateGraph}>
              <ListItemIcon>
                <DuplicateIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>{t('pages.cases.duplicate')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem disabled={!iAmCaseOwner} onClick={rename}>
            <ListItemIcon>
              <EditIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.rename')}</ListItemText>
          </MenuItem>
          {/* TODO: REACTIVATE WHEN PROFILER IS RUNNING ON SERVER */}
          {/*{!isMaltegoGraphFile(row) && <MenuItem onClick={profiler}>*/}
          {/*<ListItemIcon><OsintProfilerIcon height={20} width={20} /></ListItemIcon>*/}
          {/*    <ListItemText>Open OSINT Profiler</ListItemText>*/}
          {/*</MenuItem>}*/}
          {!isMaltegoGraphFile(row) && (
            <MenuItem onClick={share}>
              <ListItemIcon>
                <ShareIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>
                {' '}
                {iAmCaseOwner
                  ? t('pages.cases.sharingOptions')
                  : t('pages.cases.sharingInfo')}
              </ListItemText>
            </MenuItem>
          )}
          {((!isMaltegoGraphFile(row) && iAmCaseOwner) ||
            (isMaltegoGraphFile(row) && iAmGraphAuthor)) && (
            <Divider variant={'middle'} />
          )}
          {!isMaltegoGraphFile(row) && (
            <MenuItem disabled={!iAmCaseOwner} onClick={deleteCase}>
              <ListItemIcon>
                <DeleteIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>Delete Case</ListItemText>
            </MenuItem>
          )}
          {isMaltegoGraphFile(row) && (
            <MenuItem
              disabled={!iAmGraphAuthor || isFileLocked}
              onClick={deleteGraph}
            >
              <ListItemIcon>
                <DeleteIcon height={20} width={20} />
              </ListItemIcon>
              <ListItemText>Delete Graph</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isRenameCaseDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <RenameCaseDialog
          caseId={row.id}
          caseName={row.name}
          onConfirm={() => setIsRenameCaseDialogOpen(false)}
          onCancel={() => setIsRenameCaseDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isRenameGraphDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <RenameGraphDialog
          caseId={(row as MaltegoFile<Graph>).caseId}
          graphName={row.name}
          graphId={row.id}
          onConfirm={() => setIsRenameGraphDialogOpen(false)}
          onCancel={() => setIsRenameGraphDialogOpen(false)}
        />
      </MDialog>
      {!isMaltegoGraphFile(row) && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isShareCaseDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <ShareCaseDialog
            caseId={row.id}
            onConfirm={() => undefined}
            onCancel={() => setIsShareCaseDialogOpen(false)}
          />
        </MDialog>
      )}
      {!isMaltegoGraphFile(row) && iAmCaseOwner && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isDeleteCaseDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <DeleteCaseDialog
            caseId={row.id}
            caseName={row.name}
            onConfirm={() => setIsDeleteCaseDialogOpen(false)}
            onCancel={() => setIsDeleteCaseDialogOpen(false)}
          />
        </MDialog>
      )}
      {isMaltegoGraphFile(row) && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isMoveDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <MoveGraphDialog
            graphData={row}
            onConfirm={() => setIsMoveDialogOpen(false)}
            onCancel={() => setIsMoveDialogOpen(false)}
          />
        </MDialog>
      )}
      {isMaltegoGraphFile(row) && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isDuplicateDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <DuplicateGraphDialog
            graphId={row.id}
            graphName={row.name}
            onConfirm={() => setIsDuplicateDialogOpen(false)}
            onCancel={() => setIsDuplicateDialogOpen(false)}
          />
        </MDialog>
      )}
      {isMaltegoGraphFile(row) && iAmGraphAuthor && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isDeleteGraphDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <DeleteGraphDialog
            graphId={row.id}
            graphName={row.name}
            onConfirm={() => setIsDeleteGraphDialogOpen(false)}
            onCancel={() => setIsDeleteGraphDialogOpen(false)}
          />
        </MDialog>
      )}
      {isMaltegoGraphFile(row) && iAmGraphAuthor && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isUnlockGraphDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'md'}
        >
          <UnlockGraphDialog
            caseId={row.caseId || ''}
            graphId={row.id}
            graphName={row.name}
            onConfirm={() => setIsUnlockGraphDialogOpen(false)}
            onCancel={() => setIsUnlockGraphDialogOpen(false)}
          />
        </MDialog>
      )}
      {isMaltegoGraphFile(row) && iAmGraphAuthor && (
        <MDialog
          sx={CaseManagementDialogStyles}
          open={isGraphHistoryDialogOpen}
          onClick={(e) => e.stopPropagation()}
          size={'lg'}
        >
          <GraphHistoryTableDialog
            graph={row}
            graphId={row.id}
            graphName={row.name}
            onConfirm={() => setIsGraphHistoryDialogOpen(false)}
            onCancel={() => setIsGraphHistoryDialogOpen(false)}
          />
        </MDialog>
      )}
    </>
  );
};
export default CaseMoreOptions;
