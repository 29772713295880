import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  BaseTable,
  BaseTableCellProps,
  MCheckbox,
  TableColumnDefinition,
} from '@maltego/mui-core';
import {
  InvitedOrExistingMember,
  MemberStatusEnum,
  Product,
  UserRole,
} from '../../types';
import { getAssignmentCriteria, getAssignmentTooltip } from '../../utils';
import BaseTag from '../BaseTag/BaseTag';

interface ProductEditDrawerTableContext {
  roles: UserRole[];
  product: Product;
  products: Product[];
}

const RenderCheckbox = ({
  row,
  context,
}: BaseTableCellProps<
  InvitedOrExistingMember,
  ProductEditDrawerTableContext
>) => {
  const { isAssignedToUser, isSelectionDisabled } = getAssignmentCriteria(
    context.product,
    row,
    context.products
  );

  return (
    <Box sx={{ p: 1 }}>
      <MCheckbox
        sx={{ p: 0 }}
        disabled={isSelectionDisabled}
        checked={isAssignedToUser}
      />
    </Box>
  );
};

const NameCell = ({
  row,
  context,
}: BaseTableCellProps<
  InvitedOrExistingMember,
  ProductEditDrawerTableContext
>) => {
  const { isSelectionDisabled } = getAssignmentCriteria(
    context.product,
    row,
    context.products
  );
  const { t } = useTranslation();
  const isPendingUser =
    row.status === MemberStatusEnum.invited ||
    row.status === MemberStatusEnum.shadow;

  return (
    <Box
      sx={{
        verticalAlign: 'middle',
        pt: 0.5,
      }}
    >
      {(row.firstname || row.lastname) && !isPendingUser && (
        <Typography
          variant="body1"
          sx={{
            lineHeight: 0.75,
            mb: 0.7,
          }}
        >
          {row.firstname} {row.lastname}
        </Typography>
      )}
      <Typography
        variant="body1"
        sx={{
          lineHeight: 1.2,
          mb: 0.25,
          fontSize: isPendingUser ? 14 : 11,
          opacity: isPendingUser ? 1 : isSelectionDisabled ? 0.8 : 0.65,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {row.email}
      </Typography>
      {isPendingUser && (
        <BaseTag
          tagDescription={t('components.ProductEditDrawerTable.pending')}
        />
      )}
    </Box>
  );
};

const RoleCell = ({
  row,
  context,
}: BaseTableCellProps<
  InvitedOrExistingMember,
  ProductEditDrawerTableContext
>) => {
  const roleId = row.roles[0];
  const roleDisplay =
    context.roles.find((r) => r.id === roleId)?.name || roleId;

  return <Typography variant="body2">{roleDisplay}</Typography>;
};

const COLUMNS: TableColumnDefinition<
  InvitedOrExistingMember,
  ProductEditDrawerTableContext
>[] = [
  {
    key: 'checkbox',
    title: '',
    cellSx: {
      width: 40,
      px: 0,
    },
    render: RenderCheckbox,
  },
  {
    key: 'name',
    title: 'components.ProductEditDrawerTable.name',
    isSortable: true,
    render: NameCell,
  },
  {
    key: 'role',
    title: 'components.ProductEditDrawerTable.role',
    render: RoleCell,
    align: 'right',
  },
];

const ProductEditDrawerTable: React.FC<{
  roles: UserRole[];
  users: InvitedOrExistingMember[];
  product: Product;
  products: Product[];
  onRowClick: (rowData: InvitedOrExistingMember) => void;
}> = ({ roles, users, product, products, onRowClick }) => {
  const { t } = useTranslation();

  const sortedUsers = useMemo(() => {
    const verifiedUsers = users.filter(
      (verifiedUser) => verifiedUser.status === MemberStatusEnum.verified
    );
    const pendingUsers = users.filter(
      (verifiedUser) => verifiedUser.status !== MemberStatusEnum.verified
    );

    verifiedUsers.sort((a, b) => {
      const nameA = a.firstname ? a.firstname.toLowerCase() : a.email;
      const nameB = b.firstname ? b.firstname.toLowerCase() : b.email;

      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }

      return 0;
    });

    return [...pendingUsers, ...verifiedUsers];
  }, [users]);

  const onRowClickCB = useCallback(
    (user: InvitedOrExistingMember) => {
      const { isSelectionDisabled } = getAssignmentCriteria(
        product,
        user,
        products
      );

      if (!isSelectionDisabled) {
        onRowClick(user);
      }
    },
    [onRowClick, product, products]
  );

  const context: ProductEditDrawerTableContext = useMemo(
    () => ({ product, products, roles }),
    [product, products, roles]
  );

  return (
    <Box
      sx={{
        position: 'relative',
        pt: 1,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          ml: 6.25,
          textTransform: 'initial',
        }}
      >
        {t('components.ProductEditDrawerTable.listOfUsers')}
      </Typography>
      <Box
        sx={{
          height: '1px',
          mt: 3,
          backgroundColor: 'grey.A200',
        }}
      />
      <BaseTable
        columns={COLUMNS}
        context={context}
        data={sortedUsers}
        rowSx={(rowData: InvitedOrExistingMember) => {
          const { isAssignedToUser, isSelectionDisabled } =
            getAssignmentCriteria(product, rowData, products);

          let textColor;
          if (isAssignedToUser) {
            textColor = 'white';
          } else if (isSelectionDisabled) {
            textColor = 'grey.400';
          }

          return {
            backgroundColor: isAssignedToUser ? 'primary.main' : undefined,
            '.MuiTypography-root, svg': {
              color: textColor,
            },
            ':hover': {
              backgroundColor: isAssignedToUser ? '#586790' : undefined,
            },
            '&:nth-of-type(odd)': {
              backgroundColor: 'none', // Remove default striped table behaviour
            },
          };
        }}
        onTableRowClick={onRowClickCB}
        rowPropsGenerator={(rowData: InvitedOrExistingMember, rowIndex) => {
          const {
            remainingSeats,
            isSelectionDisabled,
            isToolOrDesktopAssigned,
            isSameProductAlreadyAssigned,
          } = getAssignmentCriteria(product, rowData, products);

          const tooltipText = t(
            getAssignmentTooltip({
              remainingSeats: remainingSeats,
              isSameProductAlreadyAssigned: isSameProductAlreadyAssigned,
              isToolOrDesktopAssigned: isToolOrDesktopAssigned,
            })
          );

          if (isSelectionDisabled) {
            return {
              tooltip: { title: tooltipText, followCursor: true },
              disabled: true,
            };
          }
          return {
            tooltip: { title: '', followCursor: true },
            disabled: false,
          };
        }}
      />
    </Box>
  );
};

export default ProductEditDrawerTable;
