import { Case, CaseMemberRole, Graph } from '../../graph-store-client/model';

export enum MaltegoFileType {
  CASE = 'Case',
  GRAPH = 'Graph',
}

export type MaltegoFile<T> = T & { type: MaltegoFileType } & {
  userRole?: CaseMemberRole;
};

export class MaltegoFileFactory {
  public static withType<T>(
    obj: T,
    type: MaltegoFileType,
    role?: CaseMemberRole
  ): MaltegoFile<T> {
    return { ...obj, type: type, userRole: role };
  }

  public static fromCase(
    maltegoCase: Case,
    role?: CaseMemberRole
  ): MaltegoFile<Case> {
    return MaltegoFileFactory.withType(maltegoCase, MaltegoFileType.CASE, role);
  }

  public static fromGraph(
    graph: Graph,
    role?: CaseMemberRole
  ): MaltegoFile<Graph> {
    return MaltegoFileFactory.withType(graph, MaltegoFileType.GRAPH, role);
  }
}
