import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Box } from '@mui/system';
import { MTextField } from '@maltego/mui-core';
import ValidatedEmailChip from './ValidatedEmailChip';

interface BulkEmailInputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClick: (deletedEmail: string) => void;
  onDelete: (deletedEmail: string) => void;
  value: string;
  label: string;
  error?: string;
  validEmails: string[];
}

const BulkEmailInput: React.FC<BulkEmailInputProps> = ({
  onChange,
  onKeyUp,
  value,
  label,
  error,
  validEmails,
  onClick,
  onDelete,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          pb: 1,
        }}
      >
        {validEmails.map((email) => (
          <ValidatedEmailChip
            key={email}
            email={email}
            onClick={() => onClick(email)}
            onDelete={() => onDelete(email)}
          />
        ))}
      </Box>
      <MTextField
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        label={label}
        name="emails"
        error={error}
      />
    </Box>
  );
};

export default BulkEmailInput;
