import axios from 'axios';
import { signIn } from 'next-auth/react';

export const switchOrganizations = async (
  organizationId?: string,
  redirectUrl?: string
) => {
  await axios.get('/api/auth/background-logout'); // Logout of keycloak for force refresh of token
  try {
    await signIn(
      'keycloak',
      redirectUrl ? { callbackUrl: redirectUrl } : {},
      organizationId ? { switchToOrgId: organizationId } : {}
    );
  } catch (error) {
    console.error(error);
  }
};
