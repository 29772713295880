import { RemoveIcon } from '../../icons';
import { IconButton, IconButtonProps, SvgIcon, Tooltip } from '@mui/material';
import React from 'react';
import { getHoverTransition } from '../../utils';

interface DeleteIconButtonProps extends IconButtonProps {
  dataTestId?: string;
  icon?: React.ElementType;
  tooltipTitle?: string;
}

const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({
  onClick,
  disabled,
  color,
  dataTestId,
  icon = RemoveIcon,
  tooltipTitle = '',
}: DeleteIconButtonProps) => {
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <IconButton
        disabled={disabled}
        data-testid={dataTestId}
        size="small"
        sx={{
          ':hover > svg': {
            color: 'error.main',
          },
        }}
        onClick={onClick}
      >
        <SvgIcon
          component={icon}
          viewBox="0 0 20 20"
          sx={(theme) => ({
            fontSize: 20,
            fill: 'none',
            color: !disabled ? color ?? 'primary.dark' : undefined,
            transition: getHoverTransition('color', theme),
          })}
        />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteIconButton;
