import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Tooltip, Typography } from '@mui/material';
import { LockGreyIcon, LockIcon } from '../../../icons';
import { Case, Graph } from '../../../graph-store-client/model';
import { isMaltegoGraphFile } from '../../../types/typeGuards';
import { MaltegoFile } from '../MaltegoFile';
import { toPascalCase } from '../../../utilities/string-fomatter';
import { useSession } from 'next-auth/react';
import { CaseContext } from '../CasesTable/CasesTable';
import {
  isLocked,
  isLockOwner,
  resolveLockOwnerTooltipText,
} from '../CasesTable/utils/CaseAccessUtils';

const GraphInUse = ({
  row,
}: BaseTableCellProps<MaltegoFile<Case | Graph>, CaseContext>) => {
  const { data } = useSession();

  const isLockedFile = isMaltegoGraphFile(row) ? isLocked(row) : false;
  const iAmLockOwner = isLockOwner(row, data || undefined);
  const resolveLockAuthorTooltipText = `This file is Locked for others. Currently edited by: You`;

  const lockOwnerText = isMaltegoGraphFile(row)
    ? iAmLockOwner
      ? resolveLockAuthorTooltipText
      : resolveLockOwnerTooltipText(row)
    : undefined;
  const readOnly = 'Read Only';
  const lockedByYou = 'Locked by you';
  return (
    <>
      {isLockedFile && (
        <Tooltip title={lockOwnerText} placement="top-start" arrow>
          <Box sx={{ display: 'flex', columnGap: '4px' }}>
            {!iAmLockOwner && (
              <>
                <LockIcon height="20" width="20" />
                <Typography sx={{ color: '#CE3737' }}>
                  {toPascalCase(readOnly)}
                </Typography>
              </>
            )}
            {iAmLockOwner && (
              <>
                <LockGreyIcon height="20" width="20" />
                <Typography>{toPascalCase(lockedByYou)}</Typography>
              </>
            )}
          </Box>
        </Tooltip>
      )}
    </>
  );
};
export default GraphInUse;
