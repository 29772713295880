import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';

const UserMenuItem: React.FC<{
  icon: React.FC;
  label: string;
  onClick: () => void;
}> = ({ icon, label, onClick }) => {
  const { t } = useTranslation();
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        pl: 1.75,
        fontSize: 12,
        fontWeight: 500,
        height: 42,
        '&:hover': {
          backgroundColor: 'primary.main',
          color: 'background.default',
        },
        '&:hover svg': {
          color: 'common.white',
        },
      }}
    >
      <SvgIcon
        sx={{
          mt: 0.5,
          mr: 1,
          color: 'primary.main',
          fontSize: 20,
        }}
        viewBox="0 0 20 20"
        component={icon}
      />
      {t(label)}
    </MenuItem>
  );
};

export default UserMenuItem;
