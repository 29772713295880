import BrokenConnectionError from '@/components/BrokeConnectionError/BrokenConnectionError';
import { useAuthentication } from '@maltego/common-contexts';
import {
  APIError,
  CustomSession,
  OrganizationContext,
  OrganizationContextValue,
  SnackBarDuration,
  useSnackbar,
} from '@maltego/ui';
import { Box } from '@mui/material';
import API_URLs from '@/utils/apiURLs';
import { getSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import CustomerTopBar from '@/layout/CustomerTopBar/CustomerTopBar';
import { FullPageLoader } from '@maltego/mui-core';
import CustomSockJsClient from './CustomSockJsClient';
import { switchOrganizations } from '@/utils/switchOrganization';
import { AccountDto } from '@portal/api/src/gw/model';

export function CustomerPortalOrganizationProvider({ children }) {
  const [isDisplayLoader, setIsDisplayLoader] = useState(false);
  const [customHeaders, setCustomHeaders] = useState({ 'X-Authorization': '' });
  const { claims, login } = useAuthentication();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    data = [],
  } = useQuery<AccountDto[], APIError>([API_URLs.organizations], {
    enabled: !!claims, // Only run query once currentOrgID is set
  });

  const organizations: AccountDto[] = useMemo(() => {
    if (isError || isLoading) {
      return [];
    }
    const organizationsData = [...data];
    organizationsData.sort((a: AccountDto, b: AccountDto) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return organizationsData;
  }, [isError, isLoading, data]);

  const isTheOnlyOrganization = organizations.length === 1;
  const currentOrganization: AccountDto = organizations.find(
    // @ts-ignore
    (organization) => organization.id === claims?.organization?.id
  );

  const refreshHeaders = useCallback(() => {
    getSession()
      .then((data: CustomSession) => {
        setCustomHeaders({ 'X-Authorization': data.accessToken });
      })
      .catch((err) => {
        console.error(err);
        login();
      });
  }, [login]);

  useEffect(() => {
    if (currentOrganization) {
      refreshHeaders();
    }
  }, [currentOrganization, refreshHeaders]);

  const value = useMemo<OrganizationContextValue>(() => {
    const setCurrentOrganizationWithAccountSwitch = async (organizationID) => {
      setIsDisplayLoader(true);
      await switchOrganizations(organizationID);
    };

    return {
      currentOrganization,
      organizations,
      setCurrentOrganizationID: setCurrentOrganizationWithAccountSwitch,
      isLoading,
      isError,
    };
  }, [currentOrganization, organizations, isLoading, isError]);

  const reloadPage = useCallback(() => {
    setIsDisplayLoader(true);
    location.reload();
  }, []);

  const onMessageReceived = useCallback(
    (message) => {
      if (
        message.accountId === currentOrganization.id &&
        message.messageType === 'REMOVED'
      ) {
        queryClient.invalidateQueries([API_URLs.organizations]).then(() => {
          /* Ignore */
        });
      } else if (
        message.accountId === currentOrganization.id &&
        message.messageType === 'ROLE_CHANGED'
      ) {
        queryClient.invalidateQueries([API_URLs.myRole]).then(() => {
          /* Ignore */
        });

        showSnackbar(
          t('components.CustomerPortalOrganizationProvider.roleChanged'),
          'warning',
          SnackBarDuration.MANUAL,
          reloadPage
        );
      }
    },
    [currentOrganization?.id, queryClient, reloadPage, showSnackbar, t]
  );

  const onConnectionClose = useCallback(() => {
    // To avoid providing expired token, clean the headers to completely reset the CustomSockJsClient when Websocket is disconnected
    setTimeout(() => {
      setCustomHeaders({ 'X-Authorization': '' });
      refreshHeaders();
    }, 5000);
  }, [refreshHeaders]);

  if (isDisplayLoader || !claims) {
    // after triggering acknowledgeRemoval fn in the BrokenConnectionError, the active account is set to null
    // add the condition !account to prevent returning OrganizationContext with null value
    return <FullPageLoader />;
  }

  if (currentOrganization && currentOrganization.userDisconnected) {
    return (
      <OrganizationContext.Provider value={value}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <CustomerTopBar hideTopBar />
          <Box
            sx={{
              flex: '1 0 0',
              backgroundColor: 'common.white',
              px: {
                xs: 2,
                lg: 8,
              },
              py: 4,
              overflow: 'auto',
            }}
          >
            <BrokenConnectionError
              disconnectedOrg={currentOrganization}
              isTheOnlyOrganization={isTheOnlyOrganization}
            />
          </Box>
        </Box>
      </OrganizationContext.Provider>
    );
  }

  return (
    <OrganizationContext.Provider value={value}>
      {customHeaders['X-Authorization'] && (
        <CustomSockJsClient
          headers={customHeaders}
          onMessage={onMessageReceived}
          onConnectionClose={onConnectionClose}
        />
      )}
      {children}
    </OrganizationContext.Provider>
  );
}
