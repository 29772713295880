import { Box } from '@mui/material';
import React, { useState } from 'react';

interface HubIcon {
  base64: string;
  url: string;
}

export const getSourceUrl = (icon: HubIcon): string => {
  if (icon.url) {
    return icon.url;
  }
  if (icon.base64.slice(0, 22).toLowerCase() === 'data:image/png;base64,') {
    return icon.base64;
  }
  return `data:image/png;base64,${icon.base64}`;
};

const HubItemImage: React.FC<{ leftbound?: boolean; icon: HubIcon }> = ({
  leftbound,
  icon,
}) => {
  const [isSquareImage, setIsSquareImage] = useState(false);

  const onLoad = (event) => {
    const img = event.target;
    const aspectRatio = img.naturalWidth / img.naturalHeight;

    aspectRatio < 1.2 && setIsSquareImage(true);
  };

  const hubIcon = getSourceUrl(icon);

  return (
    <Box
      sx={{
        width: '100%',
        height: '62px',
        pt: 0.75,
        display: 'flex',
        justifyContent: leftbound ? 'flex-start' : 'center',
      }}
    >
      {}
      <img
        src={hubIcon}
        style={{
          maxHeight: isSquareImage ? '62px' : '52px',
          maxWidth: isSquareImage ? '62px' : '200px',
          objectFit: 'contain',
        }}
        onLoad={onLoad}
        alt="hubItemicon"
      />
    </Box>
  );
};

export default HubItemImage;
