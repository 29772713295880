import { BaseTableCellProps, MTextField } from '@maltego/mui-core';
import { Box, Typography } from '@mui/material';
import { toPascalCase } from '../../../../utilities/string-fomatter';
import { CaseContext } from '../../CasesTable/CasesTable';
import { useSession } from 'next-auth/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  GraphVersion,
  GraphVersionUpdate,
} from '../../../../graph-store-client/model';
import { useState } from 'react';

const EDIT_TAG_SCHEMA = yup.object({
  tag: yup.string().required(),
});

const GraphHistoryTag = ({
  row,
}: BaseTableCellProps<GraphVersion, CaseContext>) => {
  const { data } = useSession();
  const iAmAuthor = row.authorEmail === data?.user?.email;

  const {
    register,
    formState: { errors },
  } = useForm<GraphVersionUpdate>({
    resolver: yupResolver(EDIT_TAG_SCHEMA),
    reValidateMode: 'onChange',
  });

  const [tag, setTag] = useState(row.tag);

  const tagHandler = (e: any) => {
    row.tag = e.target.value;
    setTag(e.target.value);
  };

  return (
    <>
      {iAmAuthor && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <MTextField
              error={errors?.tag?.message}
              {...register('tag')}
              onChange={tagHandler}
              value={toPascalCase(row.tag)}
            ></MTextField>
          </Box>
        </>
      )}
      {!iAmAuthor && (
        <>
          <Box sx={{ display: 'flex', columnGap: '4px' }}>
            <Typography>{toPascalCase(row.tag)}</Typography>
          </Box>
        </>
      )}
    </>
  );
};
export default GraphHistoryTag;
