export enum SortOrder {
  ASC = 1,
  DSC = 2,
}

export function sortBy<T>(
  unsortedData: Array<T>,
  sortFieldName: string,
  sortOrder = SortOrder.ASC
): Array<T> {
  if (sortOrder === SortOrder.ASC) {
    // @ts-ignore
    return unsortedData.sort((p, c) =>
      p[sortFieldName] >= c[sortFieldName] ? 1 : -1
    );
  }
  // @ts-ignore
  return unsortedData.sort((p, c) =>
    p[sortFieldName] <= c[sortFieldName] ? 1 : -1
  );
}
