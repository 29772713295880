import { ComingSoon } from '@maltego/mui-core';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

interface EmptyStateProps {
  icon: React.ElementType;
  isButtonShown?: boolean;
  buttonLabel?: string;
  title: string;
  description?: string;
  href?: string;
  onClick?: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  isButtonShown,
  buttonLabel,
  title,
  description,
  href,
  onClick,
}) => (
  <Box
    sx={{
      minHeight: '100%',
      m: 'auto',
    }}
  >
    <Box
      sx={{
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SvgIcon
        component={icon}
        sx={{
          fontSize: 60,
          fill: 'none',
        }}
        viewBox="0 0 65 65"
      />
      <Typography
        variant="h4"
        sx={{
          fontSize: 16,
          fontWeight: 400,
          mt: 3.75,
          mb: 1.5,
          lineHeight: 1.3,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      {isButtonShown && (
        <>
          {href ? (
            <ComingSoon>
              <Link href={href}>
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1.5,
                  }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </ComingSoon>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{
                mb: 1.5,
              }}
              onClick={onClick}
            >
              {buttonLabel}
            </Button>
          )}
        </>
      )}
      <Typography
        variant="h5"
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color: 'grey.600',
          lineHeight: 1.5,
          textAlign: 'center',
        }}
      >
        {description}
      </Typography>
    </Box>
  </Box>
);

export default EmptyState;
