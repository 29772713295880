import React from 'react';
import { MDialog } from '@maltego/mui-core';
import { DialogContent, DialogContentText } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useDuplicateGraph,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';
import { useQueryClient } from '@tanstack/react-query';

interface DuplicateGraphDialogProps {
  caseId?: string;
  graphId: string;
  graphName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DuplicateGraphDialog = ({
  caseId,
  graphId,
  graphName,
  onConfirm,
  onCancel,
}: DuplicateGraphDialogProps) => {
  const { t } = useTranslation();
  const duplicateGraphMutation = useDuplicateGraph();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleDuplicateGraph = () => {
    duplicateGraphMutation.mutate(
      { graphId },
      {
        onSuccess: () => {
          const successMessage = t(
            'components.DuplicateGraphDialog.successMessage',
            { graphName }
          );
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
          onConfirm();
        },
        onError: (error) => {
          console.log(error);
          showSnackbar(
            error.response?.data.detail as string,
            'error',
            SnackBarDuration.LONG
          );
          onConfirm();
        },
        onSettled: () => {
          if (caseId) {
            void queryClient.invalidateQueries({
              queryKey: getGetCaseGraphsQueryKey(caseId),
            });
          } else {
            void queryClient.invalidateQueries({
              queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
            });
          }
        },
      }
    );
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <Trans i18nKey="components.DuplicateGraphDialog.duplicateGraph" />
      </MDialog.Title>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 16, color: 'text.primary' }}>
          <Trans
            i18nKey="components.DuplicateGraphDialog.duplicateGraphMessage"
            values={{ graphName }}
          />
        </DialogContentText>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.duplicate')}
        onCancel={onCancel}
        onConfirm={handleDuplicateGraph}
        isLoading={duplicateGraphMutation.isLoading}
      />
    </>
  );
};
export default DuplicateGraphDialog;
