import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, MenuItem, SvgIcon, Typography } from '@mui/material';
import { ExternalLinkIcon } from '@maltego/ui';

const AppMenuItem: React.FC<{
  description: string;
  icon: React.FC;
  isUnclickable?: boolean;
  label: string;
  link?: string;
}> = ({ description, icon, isUnclickable = false, label, link }) => {
  const { t } = useTranslation();

  return (
    <Link
      target="_blank"
      underline="none"
      href={link}
      rel="noreferrer noopener"
    >
      <MenuItem
        disableRipple={isUnclickable}
        sx={{
          pl: 1.75,
          height: 52,
          cursor: isUnclickable ? 'default' : undefined,
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'background.default',
            '& div > :last-child': {
              color: 'background.default',
            },
            // Target the ExternalLinkIcon SVG when hovered
            '& div > div > svg:last-child': {
              color: 'grey.500',
            },
          },
        }}
      >
        <SvgIcon
          sx={{
            mr: 1,
            fontSize: 24,
          }}
          viewBox="0 0 24 24"
          component={icon}
        />
        <Box>
          <Box display="flex" sx={{ alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 13,
                fontWeight: 600,
                textTransform: 'uppercase',
                mr: 0.5,
              }}
            >
              {label}
            </Typography>
            {!isUnclickable && (
              <SvgIcon
                sx={{
                  fill: 'none',
                  color: 'background.default',
                  fontSize: 13,
                  mr: 1,
                  mb: 2 / 8,
                }}
                component={ExternalLinkIcon}
              />
            )}
          </Box>
          <Typography
            sx={{
              fontSize: 11,
              fontWeight: 400,
              color: 'grey.600',
            }}
          >
            {t(description)}
          </Typography>
        </Box>
      </MenuItem>
    </Link>
  );
};

export default AppMenuItem;
