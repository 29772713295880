import { MCheckbox, MDialog } from '@maltego/mui-core';
import { DialogContent, DialogContentText } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useDeleteGraph,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface DeleteGraphDialogProps {
  caseId?: string;
  graphId: string;
  graphName: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteGraphDialog = ({
  caseId,
  graphId,
  graphName,
  onConfirm,
  onCancel,
}: DeleteGraphDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteGraphMutation = useDeleteGraph();
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const { showSnackbar } = useSnackbar();

  const confirmDeleteHandler = () => {
    const deleteGraphPayload = { graphId };
    deleteGraphMutation.mutate(deleteGraphPayload, {
      onSuccess: () => {
        const successMessage = t(
          'components.DeleteGraphDialog.deleteSuccessMessage',
          { graphName }
        );
        showSnackbar(successMessage, 'warning', SnackBarDuration.STANDARD);

        if (caseId) {
          void queryClient.invalidateQueries({
            queryKey: getGetCaseGraphsQueryKey(caseId),
          });
        } else {
          void queryClient.invalidateQueries({
            queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
          });
        }
        onConfirm();
      },
      onError: (error) => {
        showSnackbar(
          error.response?.data.detail as string,
          'error',
          SnackBarDuration.LONG
        );
        onConfirm();
      },
    });
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        <Trans i18nKey="components.DeleteGraphDialog.confirmDelete" />
      </MDialog.Title>

      <DialogContent>
        <DialogContentText>
          <MCheckbox
            value={isConfirmChecked}
            color="error"
            sx={{ pl: 0 }}
            onClick={(e) => setIsConfirmChecked(!isConfirmChecked)}
          />
          <Trans
            i18nKey="components.DeleteGraphDialog.warningMessage"
            values={{ graphName }}
          />
        </DialogContentText>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.delete')}
        isConfirmDisabled={!isConfirmChecked}
        onCancel={onCancel}
        onConfirm={confirmDeleteHandler}
        isLoading={deleteGraphMutation.isLoading}
        confirmBtnColor="error"
      />
    </>
  );
};
export default DeleteGraphDialog;
