import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';

// Copied from @maltego-ui
export default interface CustomSession extends Session {
  accessToken: string;
  refreshToken: string;
  error: string;
  id_token: string;
}

export const GW_AXIOS_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GATEWAY_URL,
});

export const BSI_AXIOS_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BUSINESS_INTEGRATION_URL,
});

export const CCS_AXIOS_INSTANCE = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CCS_URL,
});

export const addAuthHeaderToConfig = async (config: AxiosRequestConfig) => {
  const internalSession = (await getSession()) as CustomSession;
  if (internalSession) {
    const headers = config.headers || {};
    headers.Authorization = `Bearer ${internalSession.accessToken}`;
    config.headers = headers;
  }
};

const getRequestPromiseUsingInstance = async <T>(
  axiosInstance: AxiosInstance,
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  await addAuthHeaderToConfig(config);

  const axiosCancelToken = axios.CancelToken.source(); // Notify axios when the query is cancelled
  const requestPromise = axiosInstance({
    ...config,
    ...options,
    cancelToken: axiosCancelToken.token,
  }).then(({ data }) => data);

  // @ts-ignore
  requestPromise.cancel = () => {
    axiosCancelToken.cancel('Query was cancelled');
  };

  return await requestPromise;
};

export const ccsRequestHandler = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return getRequestPromiseUsingInstance(CCS_AXIOS_INSTANCE, config, options);
};

export const gwRequestHandler = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return getRequestPromiseUsingInstance(GW_AXIOS_INSTANCE, config, options);
};

export const bsiRequestHandler = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  return getRequestPromiseUsingInstance(BSI_AXIOS_INSTANCE, config, options);
};
export type ErrorType<Error> = AxiosError<Error>;
