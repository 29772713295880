import Typography from '@mui/material/Typography';
import React from 'react';
import { SxProps } from '@mui/system';

const BaseCount: React.FC<{
  sx?: SxProps;
  label: string;
  count: number;
}> = ({ sx, label, count }) => (
  <Typography
    sx={{
      px: 2,
      m: 0,
      lineHeight: '27px',
      ...sx,
    }}
    variant="body2"
    color="textSecondary"
    gutterBottom
  >
    {label}: {count}
  </Typography>
);

export default BaseCount;
