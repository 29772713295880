import { yupResolver } from '@hookform/resolvers/yup';
import { MDialog, MTextField, WarningIcon } from '@maltego/mui-core';
import { Box, DialogContent, SvgIcon } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { Mtgl36 } from '../../../icons';
import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useUpdateGraph,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { GraphUpdate } from '../../../graph-store-client/model';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

const RENAME_CASE_SCHEMA = yup.object({
  name: yup.string().required(),
});

interface RenameGraphDialogProps {
  graphName: string;
  caseId: string | null;
  graphId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const RenameGraphDialog = ({
  graphName,
  caseId,
  graphId,
  onConfirm,
  onCancel,
}: RenameGraphDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const DEFAULT_CREATE_CASE: GraphUpdate = {
    name: graphName,
  };
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<GraphUpdate>({
    resolver: yupResolver(RENAME_CASE_SCHEMA),
    reValidateMode: 'onChange',
    // mode: 'onTouched',
    defaultValues: DEFAULT_CREATE_CASE,
  });
  useEffect(() => {
    setFocus('name');
  }, [setFocus]);
  const updateCaseMutation = useUpdateGraph();
  const error = updateCaseMutation.error;
  const errorDisplay = useMemo(
    () => (error ? error.response?.data?.title : null),
    [error]
  );
  const { showSnackbar } = useSnackbar();

  const updateGraph = (data: GraphUpdate) => {
    updateCaseMutation.mutate(
      {
        graphId: graphId,
        data: data,
      },
      {
        onSuccess: () => {
          const successMessage = t(
            'components.RenameGraphDialog.renameSuccessMessage',
            { graphName: graphName, newGraphName: data.name }
          );
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
          onConfirm();
        },
        onSettled: () => {
          if (caseId) {
            void queryClient.invalidateQueries({
              queryKey: getGetCaseGraphsQueryKey(caseId),
            });
          } else {
            void queryClient.invalidateQueries({
              queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
            });
          }
        },
      }
    );
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        {t('pages.cases.renameGraph')}
      </MDialog.Title>
      <DialogContent>
        <form onSubmit={handleSubmit(updateGraph)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <Mtgl36 height={36} width={36} />
            <MTextField
              label={t('pages.cases.name')}
              error={errors?.name?.message}
              {...register('name')}
              type="search"
            ></MTextField>
          </Box>
        </form>
      </DialogContent>
      {errorDisplay && (
        <MDialog.Alert
          startIcon={
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ fill: 'none !important' }}
              component={WarningIcon}
            />
          }
        >
          {errorDisplay}
        </MDialog.Alert>
      )}
      <MDialog.Actions
        confirmText={t('common.button.confirm')}
        onCancel={onCancel}
        isLoading={updateCaseMutation.isLoading}
        onConfirm={handleSubmit(updateGraph)}
      />
    </>
  );
};
