import { AccountDto } from '@portal/api/src/gw/model';

const organizationMock: AccountDto = {
  id: '1',
  name: 'Organization 1',
  defaultOrg: true,
  userDisconnected: false,
};

export default organizationMock;
