import { BaseTableCellProps, MDialog } from '@maltego/mui-core';
import { MoreHoriz } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CaseManagementDialogStyles } from '../dialog/CaseManagementDialog';
import { Graph } from '../../../graph-store-client/model';
import {
  DeleteIcon,
  EditIcon,
  ExternalLink,
  MoveIcon,
  UnlockIcon,
  VersionHistoryIcon,
  DuplicateIcon,
} from '../../../icons';
import { CaseContext } from '../CasesTable/CasesTable';
import DeleteGraphDialog from '../dialog/DeleteGraphDialog';
import { MaltegoFile } from '../MaltegoFile';
import { MoveGraphDialog } from '../dialog/MoveGraphDialog';
import { RenameGraphDialog } from '../dialog/RenameGraphDialog';
import UnlockGraphDialog from '../dialog/UnlockGraphDialog';
import GraphHistoryTableDialog from '../dialog/GraphHistoryTableDialog';
import LockGraphDialog from '../dialog/LockGraphDialog';
import { isMaltegoGraphFile } from '../../../types/typeGuards';
import {
  isCaseContributor,
  isCaseOwner,
  isGraphAuthor,
  isLocked,
  isLockOwner,
} from '../CasesTable/utils/CaseAccessUtils';
import DuplicateGraphDialog from '../dialog/DuplicateGraphDialog';
import openGraphInWebViewer from '../links/openGraphInWebViewer';
import { useTableRowClickCaseGraph } from '../CaseAndGraphTableRowHook';
import useUserPreferences from '../../../hooks/useUserPreferences';

const CaseGraphMoreOptions = ({
  row,
}: BaseTableCellProps<MaltegoFile<Graph>, CaseContext>) => {
  const { data } = useSession();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isRenameGraphDialogOpen, setIsRenameGraphDialogOpen] =
    React.useState<boolean>(open);
  const [isMoveDialogOpen, setIsMoveDialogOpen] = React.useState<boolean>(open);
  const [isDuplicateDialogOpen, setisDuplicateDialogOpen] =
    React.useState<boolean>(open);
  const [isDeleteGraphDialogOpen, setIsDeleteGraphDialogOpen] =
    React.useState(open);
  const [isLockGraphDialogOpen, setIsLockGraphDialogOpen] =
    React.useState(open);
  const [isUnlockGraphDialogOpen, setIsUnlockGraphDialogOpen] =
    React.useState(open);
  const [isGraphHistoryDialogOpen, setIsGraphHistoryDialogOpen] =
    React.useState(open);

  const iAmGraphAuthor = isGraphAuthor(row, data || undefined);
  const iAmLockOwner = isLockOwner(row, data || undefined);
  const iAmContributor = isCaseContributor(row, data || undefined);
  const iAmCaseOwner = isCaseOwner(row, data || undefined);
  const isFileLocked = isMaltegoGraphFile(row) ? isLocked(row) : false;

  const disableEditInMaltego =
    !(iAmCaseOwner || iAmGraphAuthor || iAmContributor) ||
    (isFileLocked && !iAmLockOwner);
  const disableUnlockGraph =
    !(iAmCaseOwner || iAmGraphAuthor || iAmLockOwner) || !isFileLocked;
  const disableMutateGraph = !iAmGraphAuthor || (isFileLocked && !iAmLockOwner);
  const { isLockGraphDialogDeactivated } = useUserPreferences();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onEditOrOpenClicked = useTableRowClickCaseGraph();
  const editInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    if (!isLockGraphDialogDeactivated) {
      setIsLockGraphDialogOpen(true);
    } else {
      onEditOrOpenClicked(row);
    }
  };
  const viewInMaltego = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    onEditOrOpenClicked(row);
  };
  const openInWebViewer = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    openGraphInWebViewer(row.id);
  };
  const unlockGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsUnlockGraphDialogOpen(true);
  };
  const graphHistory = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsGraphHistoryDialogOpen(true);
  };
  const move = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsMoveDialogOpen(true);
  };
  const duplicateGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setisDuplicateDialogOpen(true);
  };
  const rename = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsRenameGraphDialogOpen(true);
  };
  const deleteGraph = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    setIsDeleteGraphDialogOpen(true);
  };

  return (
    <>
      <div>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem disabled={disableEditInMaltego} onClick={editInMaltego}>
            <ListItemIcon>
              <ExternalLink height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.editInMaltego')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={viewInMaltego}>
            <ListItemIcon>
              <ExternalLink height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.viewInMaltego')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={openInWebViewer}>
            <ListItemIcon>
              <ExternalLink height={20} width={20} />
            </ListItemIcon>
            <ListItemText> {t('pages.cases.openInWebViewer')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={graphHistory}>
            <ListItemIcon>
              <VersionHistoryIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.graphHistory')}</ListItemText>
          </MenuItem>
          <MenuItem disabled={disableUnlockGraph} onClick={unlockGraph}>
            <ListItemIcon>
              <UnlockIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.unlockGraph')}</ListItemText>
          </MenuItem>
          <MenuItem disabled={disableMutateGraph} onClick={move}>
            <ListItemIcon>
              <MoveIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.move')}</ListItemText>
          </MenuItem>
          <MenuItem disabled={disableMutateGraph} onClick={duplicateGraph}>
            <ListItemIcon>
              <DuplicateIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.duplicate')}</ListItemText>
          </MenuItem>
          <MenuItem disabled={disableMutateGraph} onClick={rename}>
            <ListItemIcon>
              <EditIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.rename')}</ListItemText>
          </MenuItem>
          {isMaltegoGraphFile(row) && iAmGraphAuthor && (
            <Divider variant={'middle'} />
          )}
          <MenuItem
            disabled={disableMutateGraph || isFileLocked}
            onClick={deleteGraph}
          >
            <ListItemIcon>
              <DeleteIcon height={20} width={20} />
            </ListItemIcon>
            <ListItemText>{t('pages.cases.deleteGraph')}</ListItemText>
          </MenuItem>
        </Menu>
      </div>

      <MDialog
        sx={CaseManagementDialogStyles}
        open={isRenameGraphDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <RenameGraphDialog
          caseId={(row as MaltegoFile<Graph>).caseId}
          graphName={row.name}
          graphId={row.id}
          onConfirm={() => setIsRenameGraphDialogOpen(false)}
          onCancel={() => setIsRenameGraphDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isMoveDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <MoveGraphDialog
          graphData={row}
          onConfirm={() => setIsMoveDialogOpen(false)}
          onCancel={() => setIsMoveDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isDuplicateDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <DuplicateGraphDialog
          caseId={row.caseId}
          graphId={row.id}
          graphName={row.name}
          onConfirm={() => setisDuplicateDialogOpen(false)}
          onCancel={() => setisDuplicateDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isDeleteGraphDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <DeleteGraphDialog
          caseId={row.caseId || ''}
          graphId={row.id}
          graphName={row.name}
          onConfirm={() => setIsDeleteGraphDialogOpen(false)}
          onCancel={() => setIsDeleteGraphDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isLockGraphDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <LockGraphDialog
          caseId={row.caseId || ''}
          graphId={row.id}
          graphName={row.name}
          onConfirm={() => setIsLockGraphDialogOpen(false)}
          onCancel={() => setIsLockGraphDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isUnlockGraphDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'md'}
      >
        <UnlockGraphDialog
          caseId={row.caseId || ''}
          graphId={row.id}
          graphName={row.name}
          onConfirm={() => setIsUnlockGraphDialogOpen(false)}
          onCancel={() => setIsUnlockGraphDialogOpen(false)}
        />
      </MDialog>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isGraphHistoryDialogOpen}
        onClick={(e) => e.stopPropagation()}
        size={'lg'}
      >
        <GraphHistoryTableDialog
          graph={row}
          graphId={row.id}
          graphName={row.name}
          onConfirm={() => setIsGraphHistoryDialogOpen(false)}
          onCancel={() => setIsGraphHistoryDialogOpen(false)}
        />
      </MDialog>
    </>
  );
};
export default CaseGraphMoreOptions;
