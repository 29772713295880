import { Box } from '@mui/system';
import {
  capitalize,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  SxProps,
  Typography,
} from '@mui/material';
import { OwnerIcon, ReaderContributorIcon } from '../../../icons';
import {
  CaseMember,
  CaseMemberCreateRole,
  CaseMemberRole,
} from '../../../graph-store-client/model';
import React from 'react';
import { useTranslation } from 'react-i18next';

const memberListOuterStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const memberInfoStyles: SxProps = {
  width: '70px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
const memberRolesStaticStyles: SxProps = {
  fontWeight: 400,
  fontSize: '12px',
  pr: 2,
  py: '16.5px',
};
const memberRoleSelectStyles: SxProps = {
  width: '160px',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  textAlign: 'right',
};
const memberRoleStyles: SxProps = {
  fontWeight: 400,
  fontSize: '12px',
};
const memberRoleDeleteStyles: SxProps = {
  fontWeight: 400,
  fontSize: '12px',
  color: 'error.main',
};

const CaseMemberRoleSelect = (props: {
  member: CaseMember;
  memberGraphPresent: boolean;
  updateMembership: (member: CaseMember) => (e: SelectChangeEvent) => void;
}) => {
  const { member, memberGraphPresent, updateMembership } = props;
  const { t } = useTranslation();

  return (
    <Select
      sx={memberRoleSelectStyles}
      defaultValue={member.role || CaseMemberCreateRole.CONTRIBUTOR}
      onChange={updateMembership(member)}
    >
      {Object.entries(CaseMemberCreateRole).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          <Typography sx={memberRoleStyles}>
            {capitalize(value.toLowerCase())}
          </Typography>
        </MenuItem>
      ))}

      <MenuItem disabled={memberGraphPresent} key="DELETE" value="DELETE">
        <Typography sx={memberRoleDeleteStyles}>
          {t('pages.cases.revokeAccess')}
        </Typography>
      </MenuItem>
    </Select>
  );
};
const CaseMemberRoleDisplay = (props: { member: CaseMember }) => {
  const { member } = props;

  return (
    <Typography sx={memberRolesStaticStyles}>
      {capitalize(member.role.toLowerCase())}
    </Typography>
  );
};

export const CaseMemberDialogRow = (props: {
  member: CaseMember;
  iAmOwner: boolean;
  memberGraphPresent: boolean;
  updateMembership: (member: CaseMember) => (e: SelectChangeEvent) => void;
}) => {
  const { member, iAmOwner, memberGraphPresent, updateMembership } = props;

  const OWNER = CaseMemberRole.OWNER;

  const isMemberOwner = member.role === CaseMemberRole.OWNER;

  return (
    <Box sx={memberListOuterStyles}>
      <Box sx={memberInfoStyles}>
        <SvgIcon
          sx={{ mr: 2 }}
          component={member.role === OWNER ? OwnerIcon : ReaderContributorIcon}
        />
        <Typography>{member.userEmail}</Typography>
      </Box>
      {!iAmOwner || isMemberOwner ? (
        <CaseMemberRoleDisplay member={member} />
      ) : (
        <CaseMemberRoleSelect
          member={member}
          memberGraphPresent={memberGraphPresent}
          updateMembership={updateMembership}
        />
      )}
    </Box>
  );
};
