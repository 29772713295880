/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  Case,
  CaseMember,
  CaseMemberCreate,
  CreateCase,
  CreateGraphContentBlobBody,
  CreateReport,
  DeleteGraphContentBlobParams,
  DeleteGraphParams,
  DownloadGraphAttachmentParams,
  DownloadGraphParams,
  GetAllGraphsParams,
  GetCaseGraphsParams,
  GetGraphAttachmentsParams,
  GetGraphContentAttachmentParams,
  GetGraphContentBlobParams,
  Graph,
  GraphAttachmentVersion,
  GraphReference,
  GraphUpdate,
  GraphVersion,
  GraphVersionUpdate,
  MaltegoGraph,
  ProblemDetail,
  Report,
  UpdateCase,
  UpdateGraphContentBlobBody,
  UpdateReport,
  UploadGraphBody,
} from './model';
import { customInstance } from '../utilities/custom-instance';
import type { ErrorType } from '../utilities/custom-instance';

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllCases = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Case[]>(
    { url: `/cases`, method: 'GET', signal },
    options
  );
};

export const getGetAllCasesQueryKey = () => {
  return [`/cases`] as const;
};

export const getGetAllCasesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllCases>>,
  TError = ErrorType<ProblemDetail>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAllCases>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllCasesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCases>>> = ({
    signal,
  }) => getAllCases(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllCases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllCasesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllCases>>
>;
export type GetAllCasesQueryError = ErrorType<ProblemDetail>;

export const useGetAllCases = <
  TData = Awaited<ReturnType<typeof getAllCases>>,
  TError = ErrorType<ProblemDetail>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAllCases>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllCasesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCase = (
  createCase: CreateCase,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Case>(
    {
      url: `/cases`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCase,
    },
    options
  );
};

export const getCreateCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCase>>,
    TError,
    { data: CreateCase },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCase>>,
  TError,
  { data: CreateCase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCase>>,
    { data: CreateCase }
  > = (props) => {
    const { data } = props ?? {};

    return createCase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCase>>
>;
export type CreateCaseMutationBody = CreateCase;
export type CreateCaseMutationError = ErrorType<ProblemDetail>;

export const useCreateCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCase>>,
    TError,
    { data: CreateCase },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getCreateCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteCaseById = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/cases/${caseId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteCaseByIdMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaseById>>,
    TError,
    { caseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCaseById>>,
  TError,
  { caseId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCaseById>>,
    { caseId: string }
  > = (props) => {
    const { caseId } = props ?? {};

    return deleteCaseById(caseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCaseByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCaseById>>
>;

export type DeleteCaseByIdMutationError = ErrorType<ProblemDetail>;

export const useDeleteCaseById = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCaseById>>,
    TError,
    { caseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteCaseByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const findCaseById = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Case>(
    { url: `/cases/${caseId}`, method: 'GET', signal },
    options
  );
};

export const getFindCaseByIdQueryKey = (caseId: string) => {
  return [`/cases/${caseId}`] as const;
};

export const getFindCaseByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof findCaseById>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findCaseById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindCaseByIdQueryKey(caseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findCaseById>>> = ({
    signal,
  }) => findCaseById(caseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof findCaseById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindCaseByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof findCaseById>>
>;
export type FindCaseByIdQueryError = ErrorType<ProblemDetail>;

export const useFindCaseById = <
  TData = Awaited<ReturnType<typeof findCaseById>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findCaseById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindCaseByIdQueryOptions(caseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateCase = (
  caseId: string,
  updateCase: UpdateCase,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Case>(
    {
      url: `/cases/${caseId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateCase,
    },
    options
  );
};

export const getUpdateCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCase>>,
    TError,
    { caseId: string; data: UpdateCase },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCase>>,
  TError,
  { caseId: string; data: UpdateCase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCase>>,
    { caseId: string; data: UpdateCase }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return updateCase(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCase>>
>;
export type UpdateCaseMutationBody = UpdateCase;
export type UpdateCaseMutationError = ErrorType<ProblemDetail>;

export const useUpdateCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCase>>,
    TError,
    { caseId: string; data: UpdateCase },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCaseGraphs = (
  caseId: string,
  params?: GetCaseGraphsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Graph[]>(
    { url: `/cases/${caseId}/graphs`, method: 'GET', params, signal },
    options
  );
};

export const getGetCaseGraphsQueryKey = (
  caseId: string,
  params?: GetCaseGraphsParams
) => {
  return [`/cases/${caseId}/graphs`, ...(params ? [params] : [])] as const;
};

export const getGetCaseGraphsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaseGraphs>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  params?: GetCaseGraphsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseGraphs>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaseGraphsQueryKey(caseId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaseGraphs>>> = ({
    signal,
  }) => getCaseGraphs(caseId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaseGraphs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaseGraphsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaseGraphs>>
>;
export type GetCaseGraphsQueryError = ErrorType<ProblemDetail>;

export const useGetCaseGraphs = <
  TData = Awaited<ReturnType<typeof getCaseGraphs>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  params?: GetCaseGraphsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseGraphs>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaseGraphsQueryOptions(caseId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addGraphToCase = (
  caseId: string,
  graphReference: GraphReference,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Graph>(
    {
      url: `/cases/${caseId}/graphs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: graphReference,
    },
    options
  );
};

export const getAddGraphToCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addGraphToCase>>,
    TError,
    { caseId: string; data: GraphReference },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addGraphToCase>>,
  TError,
  { caseId: string; data: GraphReference },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addGraphToCase>>,
    { caseId: string; data: GraphReference }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return addGraphToCase(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGraphToCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof addGraphToCase>>
>;
export type AddGraphToCaseMutationBody = GraphReference;
export type AddGraphToCaseMutationError = ErrorType<ProblemDetail>;

export const useAddGraphToCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addGraphToCase>>,
    TError,
    { caseId: string; data: GraphReference },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getAddGraphToCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const removeGraphFromCase = (
  caseId: string,
  graphId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/cases/${caseId}/graphs/${graphId}`, method: 'DELETE' },
    options
  );
};

export const getRemoveGraphFromCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGraphFromCase>>,
    TError,
    { caseId: string; graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeGraphFromCase>>,
  TError,
  { caseId: string; graphId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeGraphFromCase>>,
    { caseId: string; graphId: string }
  > = (props) => {
    const { caseId, graphId } = props ?? {};

    return removeGraphFromCase(caseId, graphId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveGraphFromCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeGraphFromCase>>
>;

export type RemoveGraphFromCaseMutationError = ErrorType<ProblemDetail>;

export const useRemoveGraphFromCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeGraphFromCase>>,
    TError,
    { caseId: string; graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRemoveGraphFromCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCaseGraph = (
  caseId: string,
  graphId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Graph>(
    { url: `/cases/${caseId}/graphs/${graphId}`, method: 'GET', signal },
    options
  );
};

export const getGetCaseGraphQueryKey = (caseId: string, graphId: string) => {
  return [`/cases/${caseId}/graphs/${graphId}`] as const;
};

export const getGetCaseGraphQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaseGraph>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseGraph>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaseGraphQueryKey(caseId, graphId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaseGraph>>> = ({
    signal,
  }) => getCaseGraph(caseId, graphId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(caseId && graphId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaseGraph>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaseGraphQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaseGraph>>
>;
export type GetCaseGraphQueryError = ErrorType<ProblemDetail>;

export const useGetCaseGraph = <
  TData = Awaited<ReturnType<typeof getCaseGraph>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseGraph>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaseGraphQueryOptions(caseId, graphId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const removeSelfFromCase = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/cases/${caseId}/leave`, method: 'DELETE' },
    options
  );
};

export const getRemoveSelfFromCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeSelfFromCase>>,
    TError,
    { caseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeSelfFromCase>>,
  TError,
  { caseId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeSelfFromCase>>,
    { caseId: string }
  > = (props) => {
    const { caseId } = props ?? {};

    return removeSelfFromCase(caseId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveSelfFromCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeSelfFromCase>>
>;

export type RemoveSelfFromCaseMutationError = ErrorType<ProblemDetail>;

export const useRemoveSelfFromCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeSelfFromCase>>,
    TError,
    { caseId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRemoveSelfFromCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const removeMembersFromCase = (
  caseId: string,
  removeMembersFromCaseBody: string[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/cases/${caseId}/members`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: removeMembersFromCaseBody,
    },
    options
  );
};

export const getRemoveMembersFromCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeMembersFromCase>>,
    TError,
    { caseId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeMembersFromCase>>,
  TError,
  { caseId: string; data: string[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeMembersFromCase>>,
    { caseId: string; data: string[] }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return removeMembersFromCase(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveMembersFromCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeMembersFromCase>>
>;
export type RemoveMembersFromCaseMutationBody = string[];
export type RemoveMembersFromCaseMutationError = ErrorType<ProblemDetail>;

export const useRemoveMembersFromCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeMembersFromCase>>,
    TError,
    { caseId: string; data: string[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRemoveMembersFromCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCaseMembers = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CaseMember[]>(
    { url: `/cases/${caseId}/members`, method: 'GET', signal },
    options
  );
};

export const getGetCaseMembersQueryKey = (caseId: string) => {
  return [`/cases/${caseId}/members`] as const;
};

export const getGetCaseMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaseMembers>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaseMembersQueryKey(caseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaseMembers>>> = ({
    signal,
  }) => getCaseMembers(caseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaseMembers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaseMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaseMembers>>
>;
export type GetCaseMembersQueryError = ErrorType<ProblemDetail>;

export const useGetCaseMembers = <
  TData = Awaited<ReturnType<typeof getCaseMembers>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaseMembersQueryOptions(caseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const changeCaseMemberRole = (
  caseId: string,
  caseMemberCreate: CaseMemberCreate,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CaseMember>(
    {
      url: `/cases/${caseId}/members`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: caseMemberCreate,
    },
    options
  );
};

export const getChangeCaseMemberRoleMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeCaseMemberRole>>,
    TError,
    { caseId: string; data: CaseMemberCreate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeCaseMemberRole>>,
  TError,
  { caseId: string; data: CaseMemberCreate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeCaseMemberRole>>,
    { caseId: string; data: CaseMemberCreate }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return changeCaseMemberRole(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeCaseMemberRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof changeCaseMemberRole>>
>;
export type ChangeCaseMemberRoleMutationBody = CaseMemberCreate;
export type ChangeCaseMemberRoleMutationError = ErrorType<ProblemDetail>;

export const useChangeCaseMemberRole = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeCaseMemberRole>>,
    TError,
    { caseId: string; data: CaseMemberCreate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getChangeCaseMemberRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const addMembersToCase = (
  caseId: string,
  caseMemberCreate: CaseMemberCreate[],
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CaseMember[]>(
    {
      url: `/cases/${caseId}/members`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: caseMemberCreate,
    },
    options
  );
};

export const getAddMembersToCaseMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMembersToCase>>,
    TError,
    { caseId: string; data: CaseMemberCreate[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addMembersToCase>>,
  TError,
  { caseId: string; data: CaseMemberCreate[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addMembersToCase>>,
    { caseId: string; data: CaseMemberCreate[] }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return addMembersToCase(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddMembersToCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof addMembersToCase>>
>;
export type AddMembersToCaseMutationBody = CaseMemberCreate[];
export type AddMembersToCaseMutationError = ErrorType<ProblemDetail>;

export const useAddMembersToCase = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMembersToCase>>,
    TError,
    { caseId: string; data: CaseMemberCreate[] },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getAddMembersToCaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCaseMembership = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CaseMember>(
    { url: `/cases/${caseId}/members/me`, method: 'GET', signal },
    options
  );
};

export const getGetCaseMembershipQueryKey = (caseId: string) => {
  return [`/cases/${caseId}/members/me`] as const;
};

export const getGetCaseMembershipQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaseMembership>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseMembership>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCaseMembershipQueryKey(caseId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCaseMembership>>
  > = ({ signal }) => getCaseMembership(caseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaseMembership>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaseMembershipQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaseMembership>>
>;
export type GetCaseMembershipQueryError = ErrorType<ProblemDetail>;

export const useGetCaseMembership = <
  TData = Awaited<ReturnType<typeof getCaseMembership>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseMembership>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaseMembershipQueryOptions(caseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCaseOwner = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<CaseMember>(
    { url: `/cases/${caseId}/members/owner`, method: 'GET', signal },
    options
  );
};

export const getGetCaseOwnerQueryKey = (caseId: string) => {
  return [`/cases/${caseId}/members/owner`] as const;
};

export const getGetCaseOwnerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaseOwner>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseOwner>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCaseOwnerQueryKey(caseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaseOwner>>> = ({
    signal,
  }) => getCaseOwner(caseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCaseOwner>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCaseOwnerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaseOwner>>
>;
export type GetCaseOwnerQueryError = ErrorType<ProblemDetail>;

export const useGetCaseOwner = <
  TData = Awaited<ReturnType<typeof getCaseOwner>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCaseOwner>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaseOwnerQueryOptions(caseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAllReports = (
  caseId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Report[]>(
    { url: `/cases/${caseId}/reports`, method: 'GET', signal },
    options
  );
};

export const getGetAllReportsQueryKey = (caseId: string) => {
  return [`/cases/${caseId}/reports`] as const;
};

export const getGetAllReportsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllReports>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllReports>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllReportsQueryKey(caseId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllReports>>> = ({
    signal,
  }) => getAllReports(caseId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!caseId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllReports>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllReportsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllReports>>
>;
export type GetAllReportsQueryError = ErrorType<ProblemDetail>;

export const useGetAllReports = <
  TData = Awaited<ReturnType<typeof getAllReports>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllReports>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllReportsQueryOptions(caseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createReport = (
  caseId: string,
  createReport: CreateReport,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Report>(
    {
      url: `/cases/${caseId}/reports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createReport,
    },
    options
  );
};

export const getCreateReportMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReport>>,
    TError,
    { caseId: string; data: CreateReport },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReport>>,
  TError,
  { caseId: string; data: CreateReport },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReport>>,
    { caseId: string; data: CreateReport }
  > = (props) => {
    const { caseId, data } = props ?? {};

    return createReport(caseId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReport>>
>;
export type CreateReportMutationBody = CreateReport;
export type CreateReportMutationError = ErrorType<ProblemDetail>;

export const useCreateReport = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReport>>,
    TError,
    { caseId: string; data: CreateReport },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getCreateReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteReport = (
  caseId: string,
  reportId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/cases/${caseId}/reports/${reportId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteReportMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { caseId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReport>>,
  TError,
  { caseId: string; reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReport>>,
    { caseId: string; reportId: string }
  > = (props) => {
    const { caseId, reportId } = props ?? {};

    return deleteReport(caseId, reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReport>>
>;

export type DeleteReportMutationError = ErrorType<ProblemDetail>;

export const useDeleteReport = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { caseId: string; reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteReportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getReport = (
  caseId: string,
  reportId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Report>(
    { url: `/cases/${caseId}/reports/${reportId}`, method: 'GET', signal },
    options
  );
};

export const getGetReportQueryKey = (caseId: string, reportId: string) => {
  return [`/cases/${caseId}/reports/${reportId}`] as const;
};

export const getGetReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getReport>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  reportId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReportQueryKey(caseId, reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReport>>> = ({
    signal,
  }) => getReport(caseId, reportId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(caseId && reportId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReport>>
>;
export type GetReportQueryError = ErrorType<ProblemDetail>;

export const useGetReport = <
  TData = Awaited<ReturnType<typeof getReport>>,
  TError = ErrorType<ProblemDetail>
>(
  caseId: string,
  reportId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportQueryOptions(caseId, reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateReport = (
  caseId: string,
  reportId: string,
  updateReport: UpdateReport,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Report>(
    {
      url: `/cases/${caseId}/reports/${reportId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateReport,
    },
    options
  );
};

export const getUpdateReportMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReport>>,
    TError,
    { caseId: string; reportId: string; data: UpdateReport },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReport>>,
  TError,
  { caseId: string; reportId: string; data: UpdateReport },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReport>>,
    { caseId: string; reportId: string; data: UpdateReport }
  > = (props) => {
    const { caseId, reportId, data } = props ?? {};

    return updateReport(caseId, reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReport>>
>;
export type UpdateReportMutationBody = UpdateReport;
export type UpdateReportMutationError = ErrorType<ProblemDetail>;

export const useUpdateReport = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReport>>,
    TError,
    { caseId: string; reportId: string; data: UpdateReport },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateReportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @deprecated
 */
export const downloadGraphAttachment = (
  graphId: string,
  attachmentIndex: number,
  params?: DownloadGraphAttachmentParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Blob>(
    {
      url: `/download/attachment/${graphId}/${attachmentIndex}`,
      method: 'POST',
      params,
      responseType: 'blob',
    },
    options
  );
};

export const getDownloadGraphAttachmentMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadGraphAttachment>>,
    TError,
    {
      graphId: string;
      attachmentIndex: number;
      params?: DownloadGraphAttachmentParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadGraphAttachment>>,
  TError,
  {
    graphId: string;
    attachmentIndex: number;
    params?: DownloadGraphAttachmentParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadGraphAttachment>>,
    {
      graphId: string;
      attachmentIndex: number;
      params?: DownloadGraphAttachmentParams;
    }
  > = (props) => {
    const { graphId, attachmentIndex, params } = props ?? {};

    return downloadGraphAttachment(
      graphId,
      attachmentIndex,
      params,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadGraphAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof downloadGraphAttachment>>
>;

export type DownloadGraphAttachmentMutationError = ErrorType<ProblemDetail>;

/**
 * @deprecated
 */
export const useDownloadGraphAttachment = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadGraphAttachment>>,
    TError,
    {
      graphId: string;
      attachmentIndex: number;
      params?: DownloadGraphAttachmentParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDownloadGraphAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @deprecated
 */
export const downloadGraph = (
  graphId: string,
  params?: DownloadGraphParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    { url: `/download/graph/${graphId}`, method: 'POST', params },
    options
  );
};

export const getDownloadGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadGraph>>,
    TError,
    { graphId: string; params?: DownloadGraphParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof downloadGraph>>,
  TError,
  { graphId: string; params?: DownloadGraphParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof downloadGraph>>,
    { graphId: string; params?: DownloadGraphParams }
  > = (props) => {
    const { graphId, params } = props ?? {};

    return downloadGraph(graphId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DownloadGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof downloadGraph>>
>;

export type DownloadGraphMutationError = ErrorType<ProblemDetail>;

/**
 * @deprecated
 */
export const useDownloadGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof downloadGraph>>,
    TError,
    { graphId: string; params?: DownloadGraphParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDownloadGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAllGraphs = (
  params?: GetAllGraphsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Graph[]>(
    { url: `/graphs`, method: 'GET', params, signal },
    options
  );
};

export const getGetAllGraphsQueryKey = (params?: GetAllGraphsParams) => {
  return [`/graphs`, ...(params ? [params] : [])] as const;
};

export const getGetAllGraphsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllGraphs>>,
  TError = ErrorType<ProblemDetail>
>(
  params?: GetAllGraphsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllGraphs>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllGraphsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllGraphs>>> = ({
    signal,
  }) => getAllGraphs(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllGraphs>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllGraphsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllGraphs>>
>;
export type GetAllGraphsQueryError = ErrorType<ProblemDetail>;

export const useGetAllGraphs = <
  TData = Awaited<ReturnType<typeof getAllGraphs>>,
  TError = ErrorType<ProblemDetail>
>(
  params?: GetAllGraphsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllGraphs>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllGraphsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteGraph = (
  graphId: string,
  params?: DeleteGraphParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/graphs/${graphId}`, method: 'DELETE', params },
    options
  );
};

export const getDeleteGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGraph>>,
    TError,
    { graphId: string; params?: DeleteGraphParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGraph>>,
  TError,
  { graphId: string; params?: DeleteGraphParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGraph>>,
    { graphId: string; params?: DeleteGraphParams }
  > = (props) => {
    const { graphId, params } = props ?? {};

    return deleteGraph(graphId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGraph>>
>;

export type DeleteGraphMutationError = ErrorType<ProblemDetail>;

export const useDeleteGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGraph>>,
    TError,
    { graphId: string; params?: DeleteGraphParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const findGraph = (
  graphId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Graph>(
    { url: `/graphs/${graphId}`, method: 'GET', signal },
    options
  );
};

export const getFindGraphQueryKey = (graphId: string) => {
  return [`/graphs/${graphId}`] as const;
};

export const getFindGraphQueryOptions = <
  TData = Awaited<ReturnType<typeof findGraph>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findGraph>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindGraphQueryKey(graphId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findGraph>>> = ({
    signal,
  }) => findGraph(graphId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!graphId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof findGraph>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FindGraphQueryResult = NonNullable<
  Awaited<ReturnType<typeof findGraph>>
>;
export type FindGraphQueryError = ErrorType<ProblemDetail>;

export const useFindGraph = <
  TData = Awaited<ReturnType<typeof findGraph>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findGraph>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindGraphQueryOptions(graphId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateGraph = (
  graphId: string,
  graphUpdate: GraphUpdate,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Graph>(
    {
      url: `/graphs/${graphId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: graphUpdate,
    },
    options
  );
};

export const getUpdateGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraph>>,
    TError,
    { graphId: string; data: GraphUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGraph>>,
  TError,
  { graphId: string; data: GraphUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGraph>>,
    { graphId: string; data: GraphUpdate }
  > = (props) => {
    const { graphId, data } = props ?? {};

    return updateGraph(graphId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGraph>>
>;
export type UpdateGraphMutationBody = GraphUpdate;
export type UpdateGraphMutationError = ErrorType<ProblemDetail>;

export const useUpdateGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraph>>,
    TError,
    { graphId: string; data: GraphUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGraphAttachments = (
  graphId: string,
  params?: GetGraphAttachmentsParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GraphAttachmentVersion[]>(
    { url: `/graphs/${graphId}/attachments`, method: 'GET', params, signal },
    options
  );
};

export const getGetGraphAttachmentsQueryKey = (
  graphId: string,
  params?: GetGraphAttachmentsParams
) => {
  return [
    `/graphs/${graphId}/attachments`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetGraphAttachmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getGraphAttachments>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  params?: GetGraphAttachmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphAttachments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGraphAttachmentsQueryKey(graphId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGraphAttachments>>
  > = ({ signal }) =>
    getGraphAttachments(graphId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!graphId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGraphAttachments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGraphAttachmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGraphAttachments>>
>;
export type GetGraphAttachmentsQueryError = ErrorType<ProblemDetail>;

export const useGetGraphAttachments = <
  TData = Awaited<ReturnType<typeof getGraphAttachments>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  params?: GetGraphAttachmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphAttachments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGraphAttachmentsQueryOptions(
    graphId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteGraphContentBlob = (
  graphId: string,
  params?: DeleteGraphContentBlobParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/graphs/${graphId}/content`, method: 'DELETE', params },
    options
  );
};

export const getDeleteGraphContentBlobMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGraphContentBlob>>,
    TError,
    { graphId: string; params?: DeleteGraphContentBlobParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGraphContentBlob>>,
  TError,
  { graphId: string; params?: DeleteGraphContentBlobParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGraphContentBlob>>,
    { graphId: string; params?: DeleteGraphContentBlobParams }
  > = (props) => {
    const { graphId, params } = props ?? {};

    return deleteGraphContentBlob(graphId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGraphContentBlobMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGraphContentBlob>>
>;

export type DeleteGraphContentBlobMutationError = ErrorType<ProblemDetail>;

export const useDeleteGraphContentBlob = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGraphContentBlob>>,
    TError,
    { graphId: string; params?: DeleteGraphContentBlobParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteGraphContentBlobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGraphContentAttachmentByHash = (
  graphId: string,
  hash: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Blob>(
    {
      url: `/graphs/${graphId}/content/attachments/hash/${hash}`,
      method: 'GET',
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getGetGraphContentAttachmentByHashQueryKey = (
  graphId: string,
  hash: string
) => {
  return [`/graphs/${graphId}/content/attachments/hash/${hash}`] as const;
};

export const getGetGraphContentAttachmentByHashQueryOptions = <
  TData = Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  hash: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGraphContentAttachmentByHashQueryKey(graphId, hash);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>
  > = ({ signal }) =>
    getGraphContentAttachmentByHash(graphId, hash, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(graphId && hash),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGraphContentAttachmentByHashQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>
>;
export type GetGraphContentAttachmentByHashQueryError =
  ErrorType<ProblemDetail>;

export const useGetGraphContentAttachmentByHash = <
  TData = Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  hash: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentAttachmentByHash>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGraphContentAttachmentByHashQueryOptions(
    graphId,
    hash,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGraphContentAttachment = (
  graphId: string,
  id: string,
  params?: GetGraphContentAttachmentParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<Blob>(
    {
      url: `/graphs/${graphId}/content/attachments/${id}`,
      method: 'GET',
      params,
      responseType: 'blob',
      signal,
    },
    options
  );
};

export const getGetGraphContentAttachmentQueryKey = (
  graphId: string,
  id: string,
  params?: GetGraphContentAttachmentParams
) => {
  return [
    `/graphs/${graphId}/content/attachments/${id}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetGraphContentAttachmentQueryOptions = <
  TData = Awaited<ReturnType<typeof getGraphContentAttachment>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  id: string,
  params?: GetGraphContentAttachmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentAttachment>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGraphContentAttachmentQueryKey(graphId, id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGraphContentAttachment>>
  > = ({ signal }) =>
    getGraphContentAttachment(graphId, id, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(graphId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGraphContentAttachment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGraphContentAttachmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGraphContentAttachment>>
>;
export type GetGraphContentAttachmentQueryError = ErrorType<ProblemDetail>;

export const useGetGraphContentAttachment = <
  TData = Awaited<ReturnType<typeof getGraphContentAttachment>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  id: string,
  params?: GetGraphContentAttachmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentAttachment>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGraphContentAttachmentQueryOptions(
    graphId,
    id,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGraphContentBlob = (
  graphId: string,
  params?: GetGraphContentBlobParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<MaltegoGraph>(
    { url: `/graphs/${graphId}/content/blob`, method: 'GET', params, signal },
    options
  );
};

export const getGetGraphContentBlobQueryKey = (
  graphId: string,
  params?: GetGraphContentBlobParams
) => {
  return [
    `/graphs/${graphId}/content/blob`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetGraphContentBlobQueryOptions = <
  TData = Awaited<ReturnType<typeof getGraphContentBlob>>,
  TError = ErrorType<ProblemDetail | MaltegoGraph>
>(
  graphId: string,
  params?: GetGraphContentBlobParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentBlob>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGraphContentBlobQueryKey(graphId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGraphContentBlob>>
  > = ({ signal }) =>
    getGraphContentBlob(graphId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!graphId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGraphContentBlob>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGraphContentBlobQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGraphContentBlob>>
>;
export type GetGraphContentBlobQueryError = ErrorType<
  ProblemDetail | MaltegoGraph
>;

export const useGetGraphContentBlob = <
  TData = Awaited<ReturnType<typeof getGraphContentBlob>>,
  TError = ErrorType<ProblemDetail | MaltegoGraph>
>(
  graphId: string,
  params?: GetGraphContentBlobParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphContentBlob>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGraphContentBlobQueryOptions(
    graphId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createGraphContentBlob = (
  graphId: string,
  createGraphContentBlobBody: CreateGraphContentBlobBody,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = new FormData();
  if (createGraphContentBlobBody.attachments !== undefined) {
    createGraphContentBlobBody.attachments.forEach((value) =>
      formData.append('attachments', value)
    );
  }
  formData.append('graph', createGraphContentBlobBody.graph);

  return customInstance<Graph>(
    {
      url: `/graphs/${graphId}/content/blob`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getCreateGraphContentBlobMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGraphContentBlob>>,
    TError,
    { graphId: string; data: CreateGraphContentBlobBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createGraphContentBlob>>,
  TError,
  { graphId: string; data: CreateGraphContentBlobBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createGraphContentBlob>>,
    { graphId: string; data: CreateGraphContentBlobBody }
  > = (props) => {
    const { graphId, data } = props ?? {};

    return createGraphContentBlob(graphId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGraphContentBlobMutationResult = NonNullable<
  Awaited<ReturnType<typeof createGraphContentBlob>>
>;
export type CreateGraphContentBlobMutationBody = CreateGraphContentBlobBody;
export type CreateGraphContentBlobMutationError = ErrorType<ProblemDetail>;

export const useCreateGraphContentBlob = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createGraphContentBlob>>,
    TError,
    { graphId: string; data: CreateGraphContentBlobBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getCreateGraphContentBlobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateGraphContentBlob = (
  graphId: string,
  updateGraphContentBlobBody: UpdateGraphContentBlobBody,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = new FormData();
  if (updateGraphContentBlobBody.attachments !== undefined) {
    updateGraphContentBlobBody.attachments.forEach((value) =>
      formData.append('attachments', value)
    );
  }
  formData.append('graph', updateGraphContentBlobBody.graph);

  return customInstance<GraphVersion>(
    {
      url: `/graphs/${graphId}/content/blob`,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getUpdateGraphContentBlobMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphContentBlob>>,
    TError,
    { graphId: string; data: UpdateGraphContentBlobBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGraphContentBlob>>,
  TError,
  { graphId: string; data: UpdateGraphContentBlobBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGraphContentBlob>>,
    { graphId: string; data: UpdateGraphContentBlobBody }
  > = (props) => {
    const { graphId, data } = props ?? {};

    return updateGraphContentBlob(graphId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGraphContentBlobMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGraphContentBlob>>
>;
export type UpdateGraphContentBlobMutationBody = UpdateGraphContentBlobBody;
export type UpdateGraphContentBlobMutationError = ErrorType<ProblemDetail>;

export const useUpdateGraphContentBlob = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphContentBlob>>,
    TError,
    { graphId: string; data: UpdateGraphContentBlobBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateGraphContentBlobMutationOptions(options);

  return useMutation(mutationOptions);
};

export const duplicateGraph = (
  graphId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Graph>(
    { url: `/graphs/${graphId}/duplicate`, method: 'POST' },
    options
  );
};

export const getDuplicateGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplicateGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof duplicateGraph>>,
  TError,
  { graphId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof duplicateGraph>>,
    { graphId: string }
  > = (props) => {
    const { graphId } = props ?? {};

    return duplicateGraph(graphId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DuplicateGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof duplicateGraph>>
>;

export type DuplicateGraphMutationError = ErrorType<ProblemDetail>;

export const useDuplicateGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplicateGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDuplicateGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGraphHistory = (
  graphId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GraphVersion[]>(
    { url: `/graphs/${graphId}/history`, method: 'GET', signal },
    options
  );
};

export const getGetGraphHistoryQueryKey = (graphId: string) => {
  return [`/graphs/${graphId}/history`] as const;
};

export const getGetGraphHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getGraphHistory>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGraphHistoryQueryKey(graphId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGraphHistory>>> = ({
    signal,
  }) => getGraphHistory(graphId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!graphId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGraphHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGraphHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGraphHistory>>
>;
export type GetGraphHistoryQueryError = ErrorType<ProblemDetail>;

export const useGetGraphHistory = <
  TData = Awaited<ReturnType<typeof getGraphHistory>>,
  TError = ErrorType<ProblemDetail>
>(
  graphId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getGraphHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGraphHistoryQueryOptions(graphId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateGraphVersionById = (
  graphId: string,
  versionId: string,
  graphVersionUpdate: GraphVersionUpdate,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GraphVersion>(
    {
      url: `/graphs/${graphId}/history/${versionId}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: graphVersionUpdate,
    },
    options
  );
};

export const getUpdateGraphVersionByIdMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphVersionById>>,
    TError,
    { graphId: string; versionId: string; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGraphVersionById>>,
  TError,
  { graphId: string; versionId: string; data: GraphVersionUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGraphVersionById>>,
    { graphId: string; versionId: string; data: GraphVersionUpdate }
  > = (props) => {
    const { graphId, versionId, data } = props ?? {};

    return updateGraphVersionById(graphId, versionId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGraphVersionByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGraphVersionById>>
>;
export type UpdateGraphVersionByIdMutationBody = GraphVersionUpdate;
export type UpdateGraphVersionByIdMutationError = ErrorType<ProblemDetail>;

export const useUpdateGraphVersionById = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphVersionById>>,
    TError,
    { graphId: string; versionId: string; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateGraphVersionByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const restoreGraphVersionById = (
  graphId: string,
  versionId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/graphs/${graphId}/history/${versionId}/rollback`, method: 'PUT' },
    options
  );
};

export const getRestoreGraphVersionByIdMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphVersionById>>,
    TError,
    { graphId: string; versionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreGraphVersionById>>,
  TError,
  { graphId: string; versionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreGraphVersionById>>,
    { graphId: string; versionId: string }
  > = (props) => {
    const { graphId, versionId } = props ?? {};

    return restoreGraphVersionById(graphId, versionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreGraphVersionByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreGraphVersionById>>
>;

export type RestoreGraphVersionByIdMutationError = ErrorType<ProblemDetail>;

export const useRestoreGraphVersionById = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphVersionById>>,
    TError,
    { graphId: string; versionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRestoreGraphVersionByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateGraphVersionByIndex = (
  graphId: string,
  versionIndex: number,
  graphVersionUpdate: GraphVersionUpdate,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GraphVersion>(
    {
      url: `/graphs/${graphId}/history/${versionIndex}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: graphVersionUpdate,
    },
    options
  );
};

export const getUpdateGraphVersionByIndexMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphVersionByIndex>>,
    TError,
    { graphId: string; versionIndex: number; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGraphVersionByIndex>>,
  TError,
  { graphId: string; versionIndex: number; data: GraphVersionUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGraphVersionByIndex>>,
    { graphId: string; versionIndex: number; data: GraphVersionUpdate }
  > = (props) => {
    const { graphId, versionIndex, data } = props ?? {};

    return updateGraphVersionByIndex(
      graphId,
      versionIndex,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGraphVersionByIndexMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGraphVersionByIndex>>
>;
export type UpdateGraphVersionByIndexMutationBody = GraphVersionUpdate;
export type UpdateGraphVersionByIndexMutationError = ErrorType<ProblemDetail>;

export const useUpdateGraphVersionByIndex = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphVersionByIndex>>,
    TError,
    { graphId: string; versionIndex: number; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateGraphVersionByIndexMutationOptions(options);

  return useMutation(mutationOptions);
};

export const restoreGraphVersionByIndex = (
  graphId: string,
  versionIndex: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/graphs/${graphId}/history/${versionIndex}/rollback`,
      method: 'PUT',
    },
    options
  );
};

export const getRestoreGraphVersionByIndexMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphVersionByIndex>>,
    TError,
    { graphId: string; versionIndex: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreGraphVersionByIndex>>,
  TError,
  { graphId: string; versionIndex: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreGraphVersionByIndex>>,
    { graphId: string; versionIndex: number }
  > = (props) => {
    const { graphId, versionIndex } = props ?? {};

    return restoreGraphVersionByIndex(graphId, versionIndex, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreGraphVersionByIndexMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreGraphVersionByIndex>>
>;

export type RestoreGraphVersionByIndexMutationError = ErrorType<ProblemDetail>;

export const useRestoreGraphVersionByIndex = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphVersionByIndex>>,
    TError,
    { graphId: string; versionIndex: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRestoreGraphVersionByIndexMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Use updateGraphVersionById or updateGraphVersionByIndex
 * @deprecated
 */
export const updateGraphHistory = (
  graphId: string,
  version: number,
  graphVersionUpdate: GraphVersionUpdate,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<GraphVersion>(
    {
      url: `/graphs/${graphId}/history/${version}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: graphVersionUpdate,
    },
    options
  );
};

export const getUpdateGraphHistoryMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphHistory>>,
    TError,
    { graphId: string; version: number; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGraphHistory>>,
  TError,
  { graphId: string; version: number; data: GraphVersionUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGraphHistory>>,
    { graphId: string; version: number; data: GraphVersionUpdate }
  > = (props) => {
    const { graphId, version, data } = props ?? {};

    return updateGraphHistory(graphId, version, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGraphHistoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGraphHistory>>
>;
export type UpdateGraphHistoryMutationBody = GraphVersionUpdate;
export type UpdateGraphHistoryMutationError = ErrorType<ProblemDetail>;

/**
 * @deprecated
 */
export const useUpdateGraphHistory = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGraphHistory>>,
    TError,
    { graphId: string; version: number; data: GraphVersionUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUpdateGraphHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Use restoreGraphVersionById or restoreGraphVersionByIndex
 * @deprecated
 */
export const restoreGraphHistory = (
  graphId: string,
  version: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/graphs/${graphId}/history/${version}/rollback`, method: 'PUT' },
    options
  );
};

export const getRestoreGraphHistoryMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphHistory>>,
    TError,
    { graphId: string; version: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreGraphHistory>>,
  TError,
  { graphId: string; version: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreGraphHistory>>,
    { graphId: string; version: number }
  > = (props) => {
    const { graphId, version } = props ?? {};

    return restoreGraphHistory(graphId, version, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreGraphHistoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof restoreGraphHistory>>
>;

export type RestoreGraphHistoryMutationError = ErrorType<ProblemDetail>;

/**
 * @deprecated
 */
export const useRestoreGraphHistory = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreGraphHistory>>,
    TError,
    { graphId: string; version: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getRestoreGraphHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const unlockGraph = (
  graphId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Graph>(
    { url: `/graphs/${graphId}/lock`, method: 'DELETE' },
    options
  );
};

export const getUnlockGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlockGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof unlockGraph>>,
  TError,
  { graphId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unlockGraph>>,
    { graphId: string }
  > = (props) => {
    const { graphId } = props ?? {};

    return unlockGraph(graphId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnlockGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof unlockGraph>>
>;

export type UnlockGraphMutationError = ErrorType<ProblemDetail>;

export const useUnlockGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlockGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUnlockGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const lockGraph = (
  graphId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Graph>(
    { url: `/graphs/${graphId}/lock`, method: 'PUT' },
    options
  );
};

export const getLockGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lockGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof lockGraph>>,
  TError,
  { graphId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lockGraph>>,
    { graphId: string }
  > = (props) => {
    const { graphId } = props ?? {};

    return lockGraph(graphId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LockGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof lockGraph>>
>;

export type LockGraphMutationError = ErrorType<ProblemDetail>;

export const useLockGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lockGraph>>,
    TError,
    { graphId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getLockGraphMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getColleagueEmails = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<string[]>(
    { url: `/organization/colleagues`, method: 'GET', signal },
    options
  );
};

export const getGetColleagueEmailsQueryKey = () => {
  return [`/organization/colleagues`] as const;
};

export const getGetColleagueEmailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getColleagueEmails>>,
  TError = ErrorType<ProblemDetail>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getColleagueEmails>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetColleagueEmailsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getColleagueEmails>>
  > = ({ signal }) => getColleagueEmails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getColleagueEmails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetColleagueEmailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getColleagueEmails>>
>;
export type GetColleagueEmailsQueryError = ErrorType<ProblemDetail>;

export const useGetColleagueEmails = <
  TData = Awaited<ReturnType<typeof getColleagueEmails>>,
  TError = ErrorType<ProblemDetail>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getColleagueEmails>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetColleagueEmailsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const uploadGraph = (
  graphId: string,
  uploadGraphBody: UploadGraphBody,
  options?: SecondParameter<typeof customInstance>
) => {
  const formData = new FormData();
  if (uploadGraphBody.attachments !== undefined) {
    uploadGraphBody.attachments.forEach((value) =>
      formData.append('attachments', value)
    );
  }
  formData.append('graph', uploadGraphBody.graph);

  return customInstance<string>(
    {
      url: `/upload/graph/${graphId}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getUploadGraphMutationOptions = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGraph>>,
    TError,
    { graphId: string; data: UploadGraphBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadGraph>>,
  TError,
  { graphId: string; data: UploadGraphBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadGraph>>,
    { graphId: string; data: UploadGraphBody }
  > = (props) => {
    const { graphId, data } = props ?? {};

    return uploadGraph(graphId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadGraphMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadGraph>>
>;
export type UploadGraphMutationBody = UploadGraphBody;
export type UploadGraphMutationError = ErrorType<ProblemDetail>;

/**
 * @deprecated
 */
export const useUploadGraph = <
  TError = ErrorType<ProblemDetail>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadGraph>>,
    TError,
    { graphId: string; data: UploadGraphBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getUploadGraphMutationOptions(options);

  return useMutation(mutationOptions);
};
