import { BaseTableCellProps } from '@maltego/mui-core';
import { Box, Typography } from '@mui/material';
import { toPascalCase } from '../../../../utilities/string-fomatter';
import { CaseContext } from '../../CasesTable/CasesTable';
import { GraphVersion } from '../../../../graph-store-client/model';

const GraphHistoryDate = ({
  row,
}: BaseTableCellProps<GraphVersion, CaseContext>) => {
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const date = row.createdAt;
  // @ts-ignore
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  return (
    <>
      <Box sx={{ display: 'flex', columnGap: '4px' }}>
        <Typography>{toPascalCase(formattedDate)}</Typography>
      </Box>
    </>
  );
};
export default GraphHistoryDate;
