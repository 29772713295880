import React, { useState } from 'react';
import { WarningIcon, MDialog } from '@maltego/mui-core';
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  SvgIcon,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const RemoveAssignmentDialog: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
  unassignedUserEmails: string[];
}> = ({ onClose, onConfirm, unassignedUserEmails }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <MDialog.Title onClose={onClose}>
        {t('components.RemoveAssignmentDialog.removeDataAssignments')}
      </MDialog.Title>

      <MDialog.Alert
        startIcon={
          <SvgIcon
            component={WarningIcon}
            sx={{ fill: 'none', color: 'grey.700' }}
          />
        }
        sx={{ backgroundColor: 'background.paper' }}
      >
        {t('components.RemoveAssignmentDialog.warning')}
      </MDialog.Alert>
      <DialogContent
        sx={{ backgroundColor: 'background.default', pt: '20px !important' }}
      >
        <Trans
          i18nKey="components.RemoveAssignmentDialog.removeAssignmentDescription"
          values={{
            emails: unassignedUserEmails.join(', '),
          }}
        />
        <FormControlLabel
          label={t('components.RemoveAssignmentDialog.removeConfirmation')}
          control={
            <Checkbox
              color="error"
              onChange={(event) => setIsChecked(event.target.checked)}
            />
          }
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <MDialog.Actions
        onCancel={onClose}
        onConfirm={onConfirm}
        isConfirmDisabled={!isChecked}
        confirmBtnColor="error"
      />
    </>
  );
};

export default RemoveAssignmentDialog;
