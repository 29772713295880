import React, { useEffect, useState } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import API_URLs from '@/utils/apiURLs';

const CustomSockJsClient = ({ headers, onConnectionClose, onMessage }) => {
  const [stompClient, setStompClient] = useState<Client | undefined>(undefined);

  useEffect(() => {
    let isConnected = false;
    let isShouldDeactivate = false;

    const connect = () => {
      const client = new Client();
      const socket = new SockJS(API_URLs.wsConnection);
      client.webSocketFactory = () => socket;
      client.connectHeaders = headers;

      client.onConnect = () => {
        if (!isConnected) {
          isConnected = true;
          client.subscribe('/user/client.queue/events', (message) => {
            const ParsedMessageBody = JSON.parse(message.body);
            onMessage(ParsedMessageBody);
          });
        }
      };

      client.onWebSocketClose = () => {
        isShouldDeactivate = true;
        onConnectionClose();
      };

      setStompClient(client);
      client.activate();
    };

    const establishConnection = () => {
      if (!stompClient) {
        connect();
      }
    };

    establishConnection(); // Establish initial connection

    return () => {
      if (stompClient && isShouldDeactivate) {
        stompClient.deactivate();
      }
    };
  }, [headers, onConnectionClose, onMessage, stompClient]);

  return <></>;
};

export default CustomSockJsClient;
