import { MaltegoFile, MaltegoFileType } from '../components/cases/MaltegoFile';
import { Case, Graph } from '../graph-store-client/model';

export const isMaltegoGraphFile = (
  file: MaltegoFile<unknown>
): file is MaltegoFile<Graph> => {
  return file.type == MaltegoFileType.GRAPH;
};
export const isMaltegoCaseFile = (
  file: MaltegoFile<unknown>
): file is MaltegoFile<Case> => {
  return file.type == MaltegoFileType.CASE;
};
