import React from 'react';
import { FormControl, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const isDevEnv = ['dev', 'development'].includes(process.env.NEXT_PUBLIC_APP_ENV);

const TopbarLanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const onChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang).then(() => {
      /* Ignore */
    });
  };

  const valueForSelect = i18n.language.split('-')[0].toLowerCase(); // en-US -> en

  return (
    <Box>
      <FormControl variant="standard">
        <Select
          disableUnderline
          variant="standard"
          labelId="language-select-label"
          id="language-select"
          value={valueForSelect}
          label={t('Language')}
          onChange={onChange}
          sx={{
            display: isDevEnv ? undefined : 'none',
            fontWeight: 700,
            '.MuiInput-input:focus': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <MenuItem value="en">EN</MenuItem>
          <MenuItem value="de">DE</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TopbarLanguageSelector;
