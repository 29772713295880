import React, { useMemo, useRef } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  getValidProducts,
  Product,
  ProductChip,
  useColorMode,
} from '@maltego/ui';
import { useTranslation } from 'react-i18next';
import InvitedOrExistingMember, {
  MemberStatusEnum,
} from '../../types/InvitedOrExistingMember';
import {
  BaseTable,
  BaseTableCellProps,
  TableColumnDefinition,
  DetailsButton,
  DetailsButtonParentHoverSx,
} from '@maltego/mui-core';
import { CustomerRolesEnum, UserRole } from '../../types';
import { AccessRoleDto } from '@portal/api/src/gw/model/accessRoleDto';
import BaseTag from '../BaseTag/BaseTag';
import BetaTagWrapper from '../BetaTagWrapper/BetaTagWrapper';
import customerRolesEnum from '../../types/CustomerRolesEnum';

interface SharedUsersTableContext {
  roles: UserRole[];
  products: Product[];
}

export const NameCell = ({
  row,
}: BaseTableCellProps<InvitedOrExistingMember>) => {
  const { t } = useTranslation();
  const isInvitedUserOrPendingUser =
    row.status === MemberStatusEnum.invited ||
    row.status === MemberStatusEnum.shadow;
  const emailElementRef = useRef<HTMLDivElement>();

  const isEmailTooltipRequired =
    emailElementRef.current &&
    emailElementRef.current.offsetWidth !== emailElementRef.current.scrollWidth;

  const isBetaUser = row.roles.some(
    (role) => role === CustomerRolesEnum.BETA_USER
  );

  return (
    <>
      <Box
        sx={{
          verticalAlign: 'middle',
          pt: 0.5,
        }}
      >
        {(row.firstname || row.lastname) && !isInvitedUserOrPendingUser && (
          <BetaTagWrapper
            isBetaShown={isBetaUser}
            sx={{ mb: isBetaUser ? 0 : 0.5 }}
          >
            <Typography
              variant="body1"
              sx={{
                lineHeight: 0.75,
              }}
            >
              {row.firstname} {row.lastname}
            </Typography>
          </BetaTagWrapper>
        )}
        <Tooltip
          title={row.email || <div />}
          arrow
          disableHoverListener={!isEmailTooltipRequired}
        >
          <BetaTagWrapper
            isBetaShown={isBetaUser && isInvitedUserOrPendingUser}
          >
            <Typography
              ref={emailElementRef}
              variant="body1"
              sx={{
                lineHeight: 1.2,
                mb: 0.25,
                fontSize: isInvitedUserOrPendingUser ? 14 : 11,
                opacity: isInvitedUserOrPendingUser ? 1 : 0.4,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {row.email}
            </Typography>
          </BetaTagWrapper>
        </Tooltip>
        {isInvitedUserOrPendingUser && (
          <BaseTag tagDescription={t('users.table.pending')} />
        )}
      </Box>
    </>
  );
};

export const RoleCell = ({
  row,
  context,
}: BaseTableCellProps<InvitedOrExistingMember, SharedUsersTableContext>) => {
  const excludeBetaRoles = row.roles.filter(
    (role) => role !== customerRolesEnum.BETA_USER
  );
  const roleId = excludeBetaRoles[0];
  const roleDisplay =
    context.roles.find((r) => r.id === roleId)?.name || roleId;
  return <Typography variant="body2">{roleDisplay}</Typography>;
};

const ProductsCell = ({
  row,
  context,
}: BaseTableCellProps<InvitedOrExistingMember, SharedUsersTableContext>) => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const validProducts = getValidProducts(context.products);
  const validAssignments = validProducts.filter((product) =>
    product.assignees.find((assignee) => assignee.id === row.id)
  );

  if (validAssignments.length === 0) {
    return (
      <Typography
        variant="caption"
        noWrap
        sx={{
          fontSize: 13.5,
          color: colorMode === 'dark' ? 'grey.A100' : 'grey.400',
          borderColor: 'grey.400',
          px: 0.5,
          py: 0.4,
          borderTop: 1,
          borderBottom: 1,
          borderLeft: 'none',
          borderRight: 'none',
        }}
      >
        {t('users.table.noProducts')}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexWrap: 'nowrap',
      }}
    >
      {!validAssignments
        ? null // Can't render product chip, while products are being loaded
        : validAssignments.map((validAssignment) => (
            <ProductChip
              key={validAssignment.id}
              productName={validAssignment.productPlan.name}
              productId={validAssignment.product.id}
            />
          ))}
    </Box>
  );
};

export const EditCell = () => {
  return <DetailsButton label="components.SharedUsersTable.details" />;
};

const COLUMNS: TableColumnDefinition<
  InvitedOrExistingMember,
  SharedUsersTableContext
>[] = [
  {
    key: 'email',
    title: 'components.SharedUsersTable.name',
    render: NameCell,
  },
  {
    key: 'roles',
    title: 'components.SharedUsersTable.roles',
    render: RoleCell,
  },
  {
    key: 'products',
    title: 'components.SharedUsersTable.products',
    render: ProductsCell,
  },
  {
    key: 'id',
    title: 'components.SharedUsersTable.edit',
    render: EditCell,
    align: 'right',
  },
];

interface IMembersTableProps {
  roles: UserRole[] | AccessRoleDto[];
  users: InvitedOrExistingMember[];
  products: Product[];
  onTableRowClick: (tableRow: InvitedOrExistingMember) => void;
}

const SharedUsersTable: React.FC<IMembersTableProps> = ({
  roles,
  users,
  products,
  onTableRowClick,
}) => {
  const sortedUsers = useMemo(() => {
    users.sort((a, b) => {
      if (a.status === 'VERIFIED') {
        return 1;
      }
      if (b.status === 'VERIFIED') {
        return -1;
      }
      return 0;
    });
    return users;
  }, [users]);

  if (!users?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        '.MuiTable-root': {
          tableLayout: 'fixed', // Prevent table from exceeding 100% width
        },
        'th:nth-of-type(1)': {
          width: 200,
        },
        'th:nth-of-type(2)': {
          width: 140,
        },
        'th:nth-of-type(4)': {
          width: 115,
        },
      }}
    >
      <BaseTable
        columns={COLUMNS}
        context={{ roles, products }}
        data={sortedUsers}
        onTableRowClick={onTableRowClick}
        rowSx={{
          '&:hover': {
            border: '1px solid',
            td: {
              borderTop: '1px solid',
              borderBottom: '1px solid',
            },
            ...DetailsButtonParentHoverSx['&:hover'],
          },
        }} // Enable hover on row to show DetailsButton
      />
    </Box>
  );
};

export default SharedUsersTable;
