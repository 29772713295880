export const lightModeProductColors = {
  text: '#303849',
  // default color for Mock HubItems and not assigned products: Same as Maltego color
  default: {
    borderColor: '#B5BDCC',
    backgroundColor: '#EBEDF1',
  },
  mintGreen: {
    borderColor: '#B5D9C6',
    backgroundColor: '#EBF5F0',
  },
  teal: {
    borderColor: '#B5D5D9',
    backgroundColor: '#ECF4F5',
  },
  sky: {
    borderColor: '#B9D5E9',
    backgroundColor: '#EEF6FC',
  },
  blue: {
    borderColor: '#BED0F3',
    backgroundColor: '#F0F5FF',
  },
  azure: {
    borderColor: '#C9CEFA',
    backgroundColor: '#F0F2FF',
  },
  navy: {
    borderColor: '#C7C3EF',
    backgroundColor: '#F1F0FF',
  },
  iris: {
    borderColor: '#CDBFF4',
    backgroundColor: '#F4F0FF',
  },
  purple: {
    borderColor: '#D2BEEB',
    backgroundColor: '#F4ECFB',
  },
  lila: {
    borderColor: '#DDB6E6',
    backgroundColor: '#FBF2FC',
  },
  pink: {
    borderColor: '#E1B8D9',
    backgroundColor: '#FDF4FA',
  },
  rose: {
    borderColor: '#E1B8C7',
    backgroundColor: '#FCF1F5',
  },
  rouge: {
    borderColor: '#E1B8B8',
    backgroundColor: '#FCF1F1',
  },
  salmon: {
    borderColor: '#E2C1B6',
    backgroundColor: '#FCF4F1',
  },
  peach: {
    borderColor: '#E1C9B8',
    backgroundColor: '#FCF6F1',
  },
  orange: {
    borderColor: '#DECCB1',
    backgroundColor: '#FCF8F1',
  },
  banana: {
    borderColor: '#DED4B1',
    backgroundColor: '#FCFAF1',
  },
  olive: {
    borderColor: '#D7D5A7',
    backgroundColor: '#FCFCF1',
  },
  limette: {
    borderColor: '#D1D7A7',
    backgroundColor: '#FAFCF1',
  },
  guacamole: {
    borderColor: '#C5D7A7',
    backgroundColor: '#F8FCF1',
  },
  fern: {
    borderColor: '#BCD3B2',
    backgroundColor: '#F5FCF1',
  },
  emerald: {
    borderColor: '#B2D3B3',
    backgroundColor: '#F2FCF1',
  },
};

export const darkModeProductColors = {
  text: '#ffffff',
  // default color for Mock HubItems and not assigned products: Same as Maltego color
  default: {
    borderColor: '#5B616C',
    backgroundColor: '#303849',
  },
  mintGreen: {
    borderColor: '#617B6E',
    backgroundColor: '#343C38',
  },
  teal: {
    borderColor: '#61787B',
    backgroundColor: '#344142',
  },
  sky: {
    borderColor: '#4F657D',
    backgroundColor: '#37444B',
  },
  blue: {
    borderColor: '#48698F',
    backgroundColor: '#384453',
  },
  azure: {
    borderColor: '#48548F',
    backgroundColor: '#363C51',
  },
  navy: {
    borderColor: '#525498',
    backgroundColor: '#37374E',
  },
  iris: {
    borderColor: '#615093',
    backgroundColor: '#39334B',
  },
  purple: {
    borderColor: '#6F4C8B',
    backgroundColor: '#382E43',
  },
  lila: {
    borderColor: '#72417A',
    backgroundColor: '#3B2D44',
  },
  pink: {
    borderColor: '#7A4565',
    backgroundColor: '#43303C',
  },
  rose: {
    borderColor: '#86465D',
    backgroundColor: '#402B34',
  },
  rouge: {
    borderColor: '#834646',
    backgroundColor: '#432A2A',
  },
  salmon: {
    borderColor: '#835446',
    backgroundColor: '#41312D',
  },
  peach: {
    borderColor: '#835C46',
    backgroundColor: '#3F342E',
  },
  orange: {
    borderColor: '#866A41',
    backgroundColor: '#3F342A',
  },
  banana: {
    borderColor: '#867741',
    backgroundColor: '#3E3727',
  },
  olive: {
    borderColor: '#7E8042',
    backgroundColor: '#383829',
  },
  limette: {
    borderColor: '#618042',
    backgroundColor: '#353C2F',
  },
  guacamole: {
    borderColor: '#478042',
    backgroundColor: '#2F3D2C',
  },
  fern: {
    borderColor: '#428053',
    backgroundColor: '#323F33',
  },
  emerald: {
    borderColor: '#428069',
    backgroundColor: '#273731',
  },
};
