import TopbarLanguageSelector from '@/components/layout/TopbarLanguageSelector';
import OrganizationController from '@/components/organization/OrganizationController';
import { MaltegoLogoDarkSmall, ToggleColorMode, TopBarLogo } from '@maltego/ui';
import { Box } from '@mui/material';
import React from 'react';
import CustomerUserMenu from './CustomerUserMenu';
import { TopBar } from '@maltego/mui-core';
import { useRole } from '@/context/RoleProvider/RoleProvider';

interface CustomerTopBarProps {
  hideTopBar?: boolean;
}

const CustomerTopBar: React.FC<CustomerTopBarProps> = ({
  hideTopBar = false,
}) => {
  const isDev: boolean =
    process.env.NEXT_PUBLIC_APP_ENV.toLowerCase() === 'dev';
  const { isBetaUser } = useRole();

  return (
    <TopBar>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <TopBarLogo
          label="layout.customerTopBar.title"
          logo={MaltegoLogoDarkSmall}
          isBeta={isBetaUser}
        />
        <Box
          sx={{
            width: 250,
            alignItems: 'center',
          }}
        >
          {!hideTopBar && <OrganizationController />}
        </Box>

        <Box
          sx={{
            color: 'text.primary',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TopbarLanguageSelector />
          {isDev && <ToggleColorMode />}
          <Box sx={{ width: 15 }} />
          <Box
            sx={{
              height: 36,
              width: 42,
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CustomerUserMenu />
          </Box>
        </Box>
      </Box>
    </TopBar>
  );
};

export default CustomerTopBar;
