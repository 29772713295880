import React, { useContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/system';

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
  colorMode: 'light' || 'dark',
});

export const ColorAndThemeProvider: React.FC<{
  children: React.ReactNode;
  darkTheme;
  lightTheme;
  defaultMode: 'light' | 'dark';
}> = ({ children, darkTheme, lightTheme, defaultMode }) => {
  const [mode, setMode] = useState(defaultMode);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      colorMode: mode,
    }),
    [mode]
  );
  const theme = mode === 'light' ? lightTheme : darkTheme;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => useContext(ColorModeContext);
