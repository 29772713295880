import React, { ChangeEventHandler, useState } from 'react';
import {
  Box,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
import {
  CustomerRolesEnum,
  InvitedOrExistingMember,
  MemberStatusEnum,
} from '../../types';
import useResendInvitationMutation, {
  useResendShadowInvitationMutation,
} from './useResendInvitationMutation';
import { ResendIcon } from '../../icons';
import DeleteUserOrInvitationDialog from './DeleteUserOrInvitationDialog';
import UserRolesSelect from '../UserRolesSelect/UserRolesSelect';
import { APIIconButton, MDialog } from '@maltego/mui-core';
import { APIError, getHoverTransition } from '../../utils';
import DeleteIconButton from '../DeleteIconButton/DeleteIconButton';
import BetaSwitch from '../BetaSwitch/BetaSwitch';
import { SnackBarDuration, useSnackbar } from '../../contexts';
import { AccountDto } from '@portal/api/src/gw/model';
import {
  useAddUserToBeta,
  useRemoveUserFromBeta,
} from '@portal/api/src/gw/roles';
import { useQueryClient } from '@tanstack/react-query';

const CustomWidthTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: 'center',
  },
});

interface ResendInvitationButtonProps {
  isLoading: boolean;
  error: APIError | undefined;
  onClick: () => void;
}

const ResendInvitationButton = ({
  isLoading,
  error,
  onClick,
}: ResendInvitationButtonProps) => (
  <APIIconButton
    testId="resendInvitation-btn"
    isLoading={isLoading}
    errorMessage={error?.displayMessage}
    onClick={onClick}
  >
    <SvgIcon
      component={ResendIcon}
      viewBox="0 0 16 16"
      sx={(theme) => ({
        fontSize: 20,
        fill: 'none',
        color: 'primary.dark',
        transition: getHoverTransition('color', theme),
      })}
    />
  </APIIconButton>
);

interface UserDrawerDetailsHeaderProps {
  isTheOnlyAdmin: boolean;
  user: InvitedOrExistingMember;
  currentOrganization: AccountDto;
  numAssignedProducts: number;
  onRoleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClose: () => void;
  queryOrganizationsURL: string;
  queryRolesURL: string;
  queryUsersURL: string;
  removeInvitationURL: string;
  removeUserByAdminURL: string;
  removeUserBySelfURL: string;
  resendUserInvitationURL: string;
  resendShadowUserInvitationURL: string;
}

const UserEditDrawerHeader: React.FC<UserDrawerDetailsHeaderProps> = ({
  isTheOnlyAdmin,
  user,
  currentOrganization,
  numAssignedProducts,
  onRoleChange,
  onClose,
  queryOrganizationsURL,
  queryRolesURL,
  queryUsersURL,
  removeInvitationURL,
  removeUserByAdminURL,
  removeUserBySelfURL,
  resendUserInvitationURL,
  resendShadowUserInvitationURL,
}) => {
  const [
    isDeleteUserOrInvitationDialogOpen,
    setIsDeleteUserOrInvitationDialogOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isBetaUser, setIsBetaUser] = useState(
    !!user.roles.find((role) => role === CustomerRolesEnum.BETA_USER)
  );
  const isInvited = user.status === MemberStatusEnum.invited;
  const isShadow = user.status === MemberStatusEnum.shadow;
  const isInvitedOrShadow = isInvited || isShadow;
  const isOrgInBetaProgram = currentOrganization.beta;

  const resendInvitationMutation = useResendInvitationMutation(
    resendUserInvitationURL,
    onClose
  );

  const resendShadowInvitationMutation = useResendShadowInvitationMutation(
    resendShadowUserInvitationURL,
    user.email,
    onClose
  );

  const addUserToBetaMutation = useAddUserToBeta({
    mutation: {
      onSuccess: () => {
        setIsBetaUser(true);
        showSnackbar(
          t('components.UserEditDrawerHeader.addUserToBetaSuccess'),
          'success',
          SnackBarDuration.STANDARD
        );
      },
      onError: (error) => {
        console.error(error);
        showSnackbar(
          t('components.UserEditDrawerHeader.addUserToBetaError'),
          'error',
          SnackBarDuration.STANDARD
        );
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryUsersURL]);
      },
    },
  });

  const removeUserFromBetaMutation = useRemoveUserFromBeta({
    mutation: {
      onSuccess: () => {
        setIsBetaUser(false);
        showSnackbar(
          t('components.UserEditDrawerHeader.removeUserFromBetaSuccess'),
          'success',
          SnackBarDuration.STANDARD
        );
      },
      onError: (error) => {
        console.error(error);
        showSnackbar(
          t('components.UserEditDrawerHeader.removeUserFromBetaError'),
          'error',
          SnackBarDuration.STANDARD
        );
      },
      onSettled: () => {
        void queryClient.invalidateQueries([queryUsersURL]);
      },
    },
  });

  const onBetaSwitchClick = () => {
    if (isBetaUser) {
      removeUserFromBetaMutation.mutate({
        organizationId: currentOrganization.id,
        userId: user.id,
      });
    } else {
      addUserToBetaMutation.mutate({
        organizationId: currentOrganization.id,
        userId: user.id,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mt: 0.5,
          mb: 2,
          width: '100%',
        }}
      >
        <Box>
          {isInvitedOrShadow ? (
            <Typography variant="h4" sx={{ variant: 'h4', lineHeight: '30px' }}>
              {user.email}
            </Typography>
          ) : (
            <>
              <Typography variant="h4" sx={{ pt: '5px' }}>
                {`${user.firstname} ${user.lastname}`}
              </Typography>
              <Typography variant="h5" sx={{ mt: 0.75, fontWeight: 400 }}>
                {user.email}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          sx={{ ml: 2 }}
        >
          {isOrgInBetaProgram && (
            <BetaSwitch
              checked={isBetaUser}
              disabled={
                addUserToBetaMutation.isLoading ||
                removeUserFromBetaMutation.isLoading
              }
              onChange={onBetaSwitchClick}
            />
          )}

          <Box sx={{ px: 2 }}>
            {isInvitedOrShadow && (
              <Tooltip
                title={t('components.UserEditDrawerHeader.resendInvitation')}
                placement="top"
                arrow
              >
                <Box
                /* Box is needed for tooltip to position correctly */
                >
                  {isInvited && (
                    <ResendInvitationButton
                      isLoading={resendInvitationMutation.isLoading}
                      error={resendShadowInvitationMutation.error}
                      onClick={() => {
                        resendInvitationMutation.mutate({
                          invitationId: user.id,
                        });
                      }}
                    />
                  )}
                  {isShadow && (
                    <ResendInvitationButton
                      isLoading={resendShadowInvitationMutation.isLoading}
                      error={resendShadowInvitationMutation.error}
                      onClick={() =>
                        resendShadowInvitationMutation.mutate(null)
                      }
                    />
                  )}
                </Box>
              </Tooltip>
            )}
          </Box>

          <Box>
            <CustomWidthTooltip
              title={
                isTheOnlyAdmin
                  ? t('components.UserEditDrawerHeader.removeTheOnlyAdmin')
                  : t('components.UserEditDrawerHeader.removeUser')
              }
              placement="top"
              arrow
            >
              <span>
                {/* span is needed for disabled button */}
                <DeleteIconButton
                  onClick={() => setIsDeleteUserOrInvitationDialogOpen(true)}
                  disabled={isTheOnlyAdmin}
                  dataTestId="delete-user-btn"
                />
              </span>
            </CustomWidthTooltip>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: '0 1 270px' }}>
          {isTheOnlyAdmin ? (
            <>
              <CustomWidthTooltip
                title={t('components.UserEditDrawerHeader.theOnlyAdminRole')}
                placement="top"
                arrow
              >
                <span>
                  <UserRolesSelect
                    queryRolesURL={queryRolesURL}
                    onChange={onRoleChange}
                    defaultValue={user.roles[0]}
                    disabled
                  />
                </span>
              </CustomWidthTooltip>
            </>
          ) : (
            <UserRolesSelect
              queryRolesURL={queryRolesURL}
              onChange={onRoleChange}
              defaultValue={user.roles[0]}
            />
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography color="grey.600">
            {t('components.UserEditDrawerHeader.assignedProducts')}
          </Typography>
          <Typography
            sx={{
              display: 'inline-block',
              textAlign: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '50%',
              width: 30,
              height: 30,
              lineHeight: '30px',
              ml: 1,
            }}
          >
            {numAssignedProducts}
          </Typography>
        </Box>
      </Box>
      <MDialog
        open={isDeleteUserOrInvitationDialogOpen}
        onClose={() => setIsDeleteUserOrInvitationDialogOpen(false)}
        size="md"
      >
        <DeleteUserOrInvitationDialog
          queryOrganizationsURL={queryOrganizationsURL}
          queryUsersURL={queryUsersURL}
          removeInvitationURL={removeInvitationURL}
          removeUserByAdminURL={removeUserByAdminURL}
          removeUserBySelfURL={removeUserBySelfURL}
          user={user}
          currentOrganization={currentOrganization}
          onClose={() => setIsDeleteUserOrInvitationDialogOpen(false)}
          closeDrawer={onClose}
        />
      </MDialog>
    </>
  );
};

export default UserEditDrawerHeader;
