import KeycloakProvider from 'next-auth/providers/keycloak';
import { CustomSession } from '../../types';
import type { JWT } from 'next-auth/jwt';
import axios from 'axios';

const REFRESH_AHEAD_SECS = 15;

const refreshAccessToken = async (token: JWT) => {
  try {
    if (Date.now() > (token.refreshTokenExpired as number) * 1000)
      throw 'Refresh token already expired';

    const details = {
      client_id: process.env.KEYCLOAK_ID,
      grant_type: 'refresh_token',
      refresh_token: token.refreshToken,
    };
    const formBody = Object.entries(details).map(
      ([key, value]: [string, any]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        return encodedKey + '=' + encodedValue;
      }
    );
    const formData = formBody.join('&');

    const url = `${process.env.KEYCLOAK_ISSUER}/protocol/openid-connect/token`;
    const response = await axios.post(url, formData);
    const refreshedTokens = response.data;

    if (response.status !== 200) throw refreshedTokens;
    return {
      ...token,
      accessToken: refreshedTokens.access_token,
      accessTokenExpired:
        Date.now() + (refreshedTokens.expires_in - REFRESH_AHEAD_SECS) * 1000,
      refreshToken: refreshedTokens.refresh_token ?? token.refreshToken,
      refreshTokenExpired:
        Date.now() +
        (refreshedTokens.refresh_expires_in - REFRESH_AHEAD_SECS) * 1000,
      id_token: refreshedTokens.id_token,
    };
  } catch (error) {
    console.error(error);
    return {
      ...token,
      error: 'RefreshAccessTokenError',
    };
  }
};

export const nextAuthOptions = (scope?: string) => {
  const baseOptions = {
    clientId: process.env.KEYCLOAK_ID as string,
    clientSecret: process.env.KEYCLOAK_SECRET as string,
    issuer: process.env.KEYCLOAK_ISSUER,
  };

  const keycloakProviderOptions = scope
    ? {
        ...baseOptions,
        authorization: {
          params: { scope: scope },
        },
      }
    : baseOptions;

  return {
    providers: [KeycloakProvider(keycloakProviderOptions)],
    pages: {
      signIn: '/auth/signin',
      error: '/auth/error', // Error code passed in query string as ?error=
    },
    callbacks: {
      jwt: async function ({ token, account, user }) {
        // Initial sign in
        if (account && user) {
          // Add access_token, refresh_token and expirations to the token right after signin
          token.accessToken = account.access_token;
          token.refreshToken = account.refresh_token;
          token.accessTokenExpired = (account.expires_at as number) * 1000;
          token.refreshTokenExpired =
            Date.now() +
            ((account.refresh_expires_in as number) - REFRESH_AHEAD_SECS) *
              1000;
          token.user = user;
          token.id_token = account.id_token;
          return token;
        }

        // Return previous token if the access token has not expired yet
        if (Date.now() < (token.accessTokenExpired as number)) {
          return token;
        }

        // Access token has expired, try to update it
        return refreshAccessToken(token);
      },
      async session({ session, token }) {
        // Send properties to the client, like an access_token from a provider.
        const customSession = session as CustomSession;
        customSession.accessToken = token.accessToken as string;
        customSession.error = token.error as string;
        customSession.id_token = token.id_token as string;
        return customSession;
      },
    },
  };
};
