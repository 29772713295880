import {
  CaseMember,
  CaseMemberCreate,
  CaseMemberCreateRole,
  CaseMemberRole,
} from '../../../graph-store-client/model';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import {
  IconButton,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  SxProps,
  Typography,
} from '@mui/material';
import { MTextField } from '@maltego/mui-core';
import { PlusIconB } from '../../../icons';
import React from 'react';
import * as yup from 'yup';
// appears that this needs to be imported after yup ¯\_(ツ)_/¯
import { yupResolver } from '@hookform/resolvers/yup';
import { lightTheme } from '../../../constants/theme';

const paperNameInputOuterStyles: SxProps = {
  maxWidth: '350px',
  backgroundColor: 'background.paper',
  height: '55px',
  display: 'flex',
  mb: 6,
  '&:focus-within': {
    outline: `${lightTheme.palette.secondary.main} 2px solid`,
  },
};
const paperNameInputFieldStyles: SxProps = {
  backgroundColor: 'background.default',
  width: '220px',
  height: '100%',
  '.MuiFilledInput-root': {
    backgroundColor: 'background.paper',
    height: '55px',
    mb: 0,
  },
};
const paperRoleSelectionStyles: SxProps = {
  width: '120px',
  height: '55px',
  flex: '0 1 120px',
  backgroundColor: 'background.paper',
  border: 'none',
  '.MuiPaper-root': { width: '120px' },
};
const selectRoleStyles: SxProps = {
  width: '120px',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
};
const inviteMemberButtonBgStyles: SxProps = {
  height: '55px',
  width: '55px',
  backgroundColor: 'secondary.main',
  borderRadius: '50%',
  '&:hover': { backgroundColor: 'primary.main' },
  '&:hover .plusIconB': { color: 'background.default' },
};
const inviteMemberIconButtonStyles: SxProps = {
  width: '70px',
  height: '55px',
  '&:hover': { backgroundColor: 'background.default' },
};

export const AddMemberSection = (props: {
  onAddMember: (member: CaseMemberCreate) => void;
  members: CaseMember[];
}) => {
  const { onAddMember, members } = props;
  const { t } = useTranslation();

  const SHARE_CASE_SCHEMA = yup.object({
    userEmail: yup
      .string()
      .email()
      .required('pages.cases.emailError')
      .test(
        'Unique Member',
        'pages.cases.memberAlreadyExists',
        (emailAddress) => {
          return !members.find((member) => member.userEmail === emailAddress);
        }
      ),
    role: yup.mixed().oneOf(Object.values(CaseMemberCreateRole)).required(),
  });

  const DEFAULT_ROLE = CaseMemberRole.CONTRIBUTOR;
  const DEFAULT_CREATE_CASE: CaseMemberCreate = {
    userEmail: '',
    role: DEFAULT_ROLE,
  };
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<CaseMemberCreate>({
    resolver: yupResolver(SHARE_CASE_SCHEMA),
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: DEFAULT_CREATE_CASE,
  });

  const errors = formState.errors;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Paper
        className="outerInputContainer"
        elevation={0}
        sx={{
          ...{
            outline: formState.errors?.userEmail?.message
              ? 'red 1px solid'
              : 'none',
          },
          ...paperNameInputOuterStyles,
        }}
      >
        <Paper elevation={0} sx={paperNameInputFieldStyles}>
          <MTextField
            color="primary"
            label={t('pages.cases.email')}
            error={errors?.userEmail?.message}
            sx={{ border: 'none' }}
            {...register('userEmail')}
          ></MTextField>
        </Paper>
        <Paper elevation={0} sx={paperRoleSelectionStyles}>
          <Select
            sx={selectRoleStyles}
            defaultValue={DEFAULT_ROLE}
            {...register('role')}
          >
            {Object.entries(CaseMemberCreateRole).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                <Typography
                  variant="overline"
                  sx={{
                    fontWeight: 400,
                    fontSize: '11px',
                  }}
                >
                  {value}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Paper>
      </Paper>
      <IconButton
        sx={inviteMemberIconButtonStyles}
        type={'button'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSubmit((data) => {
            onAddMember(data);
            resetForm();
          })(e);
        }}
      >
        <Box sx={inviteMemberButtonBgStyles}>
          <SvgIcon
            className="plusIconB"
            viewBox="0 0 16 16"
            component={PlusIconB}
            sx={{ color: 'primary.main', mt: 1.75 }}
          />
        </Box>
      </IconButton>
    </Box>
  );
};
