import React, { ReactNode } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

const BaseTag: React.FC<{
  sx?: SxProps;
  tagDescription: string;
  tooltipTitle?: string | ReactNode;
}> = ({ sx, tagDescription, tooltipTitle }) => (
  <Box sx={{ ...sx }}>
    <Tooltip
      disableHoverListener={!tooltipTitle}
      title={tooltipTitle}
      placement="top"
      arrow
    >
      <Typography
        component="span"
        display="inline"
        variant="caption"
        sx={{
          fontSize: 8,
          fontWeight: 500,
          color: 'grey.500',
          textTransform: 'uppercase',
          border: 1,
          borderColor: 'grey.400',
          px: 0.5,
          py: 0.25,
        }}
      >
        {tagDescription}
      </Typography>
    </Tooltip>
  </Box>
);

export default BaseTag;
