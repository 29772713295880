import { Product, ProductTypeEnum } from './Product';

const mockAvailableProducts: Product[] = [
  {
    product: {
      id: '3f87063e-57e4-4277-bce2-9ecf7c48d2d6',
      name: 'Shodan',
      type: ProductTypeEnum.HUB_ITEM,
    },
    productPlan: {
      name: 'Shodan',
      seats: 1000000,
    },
    entitlementPeriod: {
      validFrom: 1639663382,
      validUntil: 1642255382,
    },
    assignees: [
      {
        id: 'id-aw',
        email: 'aw@maltego.com',
      },
      {
        id: 'firstMockUser',
        email: '1mu@mail.com',
      },
      {
        id: 'thirdMockUser',
        email: '3mu@mail.com',
      },
    ],
    id: '1',
  },
  {
    product: {
      id: '3f87063e-57e4-4444-bce2-9ecf7c48d2d6',
      name: 'Domain Tools',
      type: ProductTypeEnum.HUB_ITEM,
    },
    productPlan: {
      name: 'Domain Tools',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1653255382,
      validUntil: 1662255382,
    },
    assignees: [
      {
        id: 'id-aw',
        email: 'aw@maltego.com',
      },
    ],
    id: '2',
  },
  {
    product: {
      id: '3f87066e-57e4-4277-bce2-9ecf7c48d2d6',
      name: 'CipherTrace',
      type: ProductTypeEnum.HUB_ITEM,
    },
    productPlan: {
      name: 'CipherTrace Medium',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1639663382,
      validUntil: 1639663382,
    },
    assignees: [
      {
        id: 'secondMockUser',
        email: '2mu@mail.com',
      },
      {
        id: 'thirdMockUser',
        email: '3mu@mail.com',
      },
    ],
    id: '3',
  },
  {
    product: {
      id: '3f87066e-57e4-4277-bce2-9ecf7c48d2d6',
      name: 'CipherTrace',
      type: ProductTypeEnum.HUB_ITEM,
    },
    productPlan: {
      name: 'CipherTrace Large',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1639663382,
      validUntil: 1639663382,
    },
    assignees: [],
    id: '4',
  },
  {
    product: {
      id: '3f87044e-57e4-4277-bce2-9ecf7c48d2d6',
      name: 'VirusTotal',
      type: ProductTypeEnum.HUB_ITEM,
    },
    productPlan: {
      name: 'VirusTotal',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1639663382,
      validUntil: 1662255382,
    },
    assignees: [
      {
        id: 'fourthMockUser',
        email: '4mu@mail.com',
      },
    ],
    id: '5',
  },
  {
    product: {
      id: '52bb4cc3-5259-43ee-949c-15d5391a0fa1',
      name: 'Maltego Social',
      type: ProductTypeEnum.DESKTOP,
    },
    productPlan: {
      name: 'Maltego Social',
      seats: 2,
    },
    entitlementPeriod: {
      validFrom: 1639663407,
      validUntil: 1642255407,
    },
    assignees: [
      {
        id: 'id-shadow-user',
        email: 'newuser@maltego.com',
      },
      {
        id: 'firstMockUser',
        email: '1mu@mail.com',
      },
    ],
    id: '6',
  },
  {
    product: {
      id: '459cb69f-9bc0-4963-a429-6cce0a633e75',
      name: 'Maltego Infrastructure',
      type: ProductTypeEnum.DESKTOP,
    },
    productPlan: {
      name: 'Maltego Infrastructure',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1641218483,
      validUntil: 1641218483,
    },
    assignees: [
      {
        id: '7fadd581-d5d5-425a-bce8-60512846d6ad',
        email: 'user@maltego.com',
      },
      {
        id: 'secondMockUser',
        email: '2mu@mail.com',
      },
    ],
    id: '7',
  },
  {
    product: {
      id: '8adcd99282e891850182ef1edf2a0df9',
      name: 'Maltego Enterprise',
      type: ProductTypeEnum.DESKTOP,
    },
    productPlan: {
      name: 'Maltego Enterprise',
      seats: 10,
    },
    entitlementPeriod: {
      validFrom: 1641218483,
      validUntil: 1693353600,
    },
    assignees: [
      {
        id: 'secondMockUser',
        email: '2mu@mail.com',
      },
      {
        id: 'thirdMockUser',
        email: '3mu@mail.com',
      },
    ],
    id: '8',
  },
];
export default mockAvailableProducts;
