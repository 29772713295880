import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getSession } from 'next-auth/react';
import CustomSession from '../types/CustomSession';

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_VAULT_BACKEND_URL,
});

export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const internalSession = (await getSession()) as CustomSession;
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${internalSession.accessToken}`;
  const source = axios.CancelToken.source();
  const promise = axiosInstance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
