/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Maltego Graph Store
 * OpenAPI spec version: 1.0.0
 */

export type CaseMemberCreateRole =
  (typeof CaseMemberCreateRole)[keyof typeof CaseMemberCreateRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseMemberCreateRole = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  READER: 'READER',
} as const;
