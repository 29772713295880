import { MPagination, SkeletonLoader, TableError } from '@maltego/mui-core';
import React, { ChangeEvent } from 'react';
import { PaginationProps } from '@mui/material';

export interface WithPaginationProps {
  isLoading: boolean;
  isError: boolean;
  page: number;
  pageCount: number;
  onPageChange: (event: ChangeEvent, newPageNumber: number) => void;
}

const withPagination = <TWrappedComponentProps extends WithPaginationProps>(
  WrappedComponent: (props: TWrappedComponentProps) => JSX.Element,
  PaginationComponent: (props: PaginationProps) => JSX.Element = MPagination,
  LoadingComponent: JSX.Element = <SkeletonLoader />,
  ErrorComponent: JSX.Element = <TableError />
) => {
  const WrappedComponentWithPagination = (props: TWrappedComponentProps) => {
    const { isLoading, isError, page, pageCount, onPageChange } = props;
    if (isError) {
      return ErrorComponent;
    }

    if (isLoading) {
      return LoadingComponent;
    }

    return (
      <>
        <WrappedComponent {...props} />
        {!isLoading && (
          <MPagination page={page} count={pageCount} onChange={onPageChange} />
        )}
      </>
    );
  };

  WrappedComponentWithPagination.displayName = `withPagination(${
    // @ts-ignore
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WrappedComponentWithPagination;
};

export default withPagination;
