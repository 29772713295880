import {
  MutationFunction,
  QueryFunctionContext,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { APIError, getDisplayMessageFromError } from './apiCalls';
import { useCallback, useMemo } from 'react';
import { getSession } from 'next-auth/react';
import { CustomSession } from '../types';

export const getAuthHeaders = async () => {
  const internalSession = (await getSession()) as CustomSession;

  if (!internalSession) {
    /**
     * The same react query function is used for unauthenticaed requests (E.g. rsvp endpoints)
     */
    return {};
  }

  return { Authorization: `Bearer ${internalSession.accessToken}` };
};

export const defaultReactQueryFunc = async (context: QueryFunctionContext) => {
  /**
   * Expects the queryKey to be the absolute URL that must be called.
   */
  const headers = await getAuthHeaders();
  const [url, _] = context.queryKey;

  return axios
    .get(url as string, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      const response = error.response;
      throw new APIError(
        error.message,
        getDisplayMessageFromError(error),
        response.status
      );
    });
};

export function appendMutationVariablesToQueryData(
  oldState,
  mutationVariables
) {
  if (Array.isArray(mutationVariables)) {
    return [...oldState, ...mutationVariables];
  } else {
    return [...oldState, mutationVariables];
  }
}

interface IUseNonOptimisticListMutationParams<MutationDataType, QueryDataType> {
  queryKey: string;
  mutationKey?: string;
  mutationMethod?: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  getNewQueryState?: (
    oldData: QueryDataType[],
    mutationVariables: MutationDataType
  ) => QueryDataType[];
  onSuccess?: (data: unknown, mutationVariables?: MutationDataType) => void;
  onError?: (err) => void;
  onSettled?: () => void;
}

export const useMutationFunc = <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  params?: { [key: string]: string }
) => {
  return useCallback<MutationFunction<any, T>>(
    (mutationVariables: any) => {
      return getAuthHeaders().then((headers) => {
        return axios({
          url,
          params,
          method,
          data: mutationVariables,
          headers,
        });
      });
    },
    [url, params, method]
  );
};

export function useStandardMutation<MutationDataType, QueryDataType>({
  queryKey,
  mutationKey,
  mutationMethod = 'POST',
  getNewQueryState,
  onSuccess,
  onError,
  onSettled,
}: IUseNonOptimisticListMutationParams<MutationDataType, QueryDataType>) {
  /**
   * Opinionated mutation hook for mutating data from an endpoint that returns a list of MutationDataType[].
   */
  const queryClient = useQueryClient();
  const mutationEndpointFinal = mutationKey || queryKey;

  const mutationFn = useCallback(
    (newItem: MutationDataType) => {
      return getAuthHeaders().then((headers) => {
        return axios({
          url: mutationEndpointFinal,
          method: mutationMethod,
          data: newItem,
          headers,
        });
      });
    },
    [mutationEndpointFinal, mutationMethod]
  );

  const mutationOptions = useMemo(
    () => ({
      onSuccess: (data, mutationVariables: MutationDataType) => {
        if (getNewQueryState) {
          // Optionally provide an 'optimistic' query update
          queryClient.setQueryData([queryKey], (oldState: QueryDataType[]) =>
            getNewQueryState(oldState, mutationVariables)
          );
        }
        onSuccess && onSuccess(data, mutationVariables);
      },
      onError: (err) => {
        onError && onError(err);
      },
      onSettled: () => {
        onSettled && onSettled();
        queryClient.invalidateQueries([queryKey]).then(() => {
          /* Ignore */
        });
      },
    }),
    [getNewQueryState, onError, onSettled, onSuccess, queryClient, queryKey]
  );

  return useMutation<unknown, APIError, MutationDataType, unknown>(
    mutationFn,
    mutationOptions
  );
}
