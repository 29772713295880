import React, { useCallback, useMemo } from 'react';
import { Box, SvgIcon, Tooltip } from '@mui/material/';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  BaseTable,
  BaseTableCellProps,
  MCheckbox,
  TableColumnDefinition,
} from '@maltego/mui-core';
import { InvitedOrExistingMember, Product } from '../../types';
import ProductChip from '../Product/ProductChip';
import { RenewIcon } from '../../icons';
import { getAssignmentCriteria, getAssignmentTooltip } from '../../utils';

interface UserEditDrawerTableContext {
  user: InvitedOrExistingMember;
  products: Product[];
}

const RenderCheckbox = ({
  row,
  context,
}: BaseTableCellProps<Product, UserEditDrawerTableContext>) => {
  const { isAssignedToUser, isSelectionDisabled } = getAssignmentCriteria(
    row,
    context.user,
    context.products
  );

  return (
    <Box sx={{ p: 1 }}>
      <MCheckbox
        sx={{ p: 0 }}
        disabled={isSelectionDisabled}
        checked={isAssignedToUser}
      />
    </Box>
  );
};

const RenderProduct = ({
  row,
  context,
}: BaseTableCellProps<Product, UserEditDrawerTableContext>) => {
  const { isAssignedToUser, isSelectionDisabled } = getAssignmentCriteria(
    row,
    context.user,
    context.products
  );

  return (
    <Box
      sx={{
        display: 'flex',
        '.MuiTypography-root': {
          color: isAssignedToUser ? 'white' : undefined,
          backgroundColor:
            isAssignedToUser || isSelectionDisabled ? 'transparent' : undefined,
        },
      }}
    >
      <ProductChip
        productName={row.productPlan.name}
        productId={row.product.id}
      />
    </Box>
  );
};

const RenderSeats = ({
  row,
  context,
}: BaseTableCellProps<Product, UserEditDrawerTableContext>) => {
  const { t } = useTranslation();
  const { remainingSeats } = getAssignmentCriteria(
    row,
    context.user,
    context.products
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box /* Force tooltip to align right */ sx={{ flexGrow: 1 }} />
      <Tooltip
        title={t('users.table.unlimitedAssignments')}
        placement="top"
        arrow
        disableHoverListener={row.productPlan.seats < 1000000}
      >
        <Typography variant="body2">
          {row.productPlan.seats >= 1000000 ? '∞' : remainingSeats}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const RenderExpires = ({
  row,
}: BaseTableCellProps<Product, UserEditDrawerTableContext>) => {
  if (!row.entitlementPeriod.validUntil) {
    // Indicates that auto-renewal is on
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Box /* Force tooltip to align right */ sx={{ flexGrow: 1 }} />
        <Tooltip title="Auto Renew Enabled" placement="top-end" arrow>
          <Box sx={{ display: 'flex' }}>
            <SvgIcon
              component={RenewIcon}
              viewBox="0 0 16 16"
              sx={{
                fontSize: 16,
                fill: 'none',
                color: 'inherit',
              }}
            />
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Typography variant="body2">
      {dayjs(new Date(row.entitlementPeriod.validUntil * 1000)).format(
        'D MMM YYYY'
      )}
    </Typography>
  );
};

const COLUMNS: TableColumnDefinition<Product, UserEditDrawerTableContext>[] = [
  {
    key: 'checkbox',
    title: '',
    cellSx: {
      width: 40,
      px: 0,
    },
    render: RenderCheckbox,
  },
  {
    key: 'product',
    title: 'components.UserEditDrawerTable.product',
    render: RenderProduct,
  },
  {
    key: 'seats',
    title: 'components.UserEditDrawerTable.seats',
    render: RenderSeats,
    align: 'right',
  },
  {
    key: 'validUntil',
    title: 'components.UserEditDrawerTable.expires',
    render: RenderExpires,
    align: 'right',
  },
];

const UserEditDrawerTable: React.FC<{
  user: InvitedOrExistingMember;
  products: Product[];
  onRowClick: (rowData: Product) => void;
}> = ({ user, products, onRowClick }) => {
  const { t } = useTranslation();

  const sortedProducts = useMemo(() => {
    const newData = [...products];

    newData.sort((a, b) => {
      // Sort alphabetically, with Maltego products first
      if (
        a.product.name.toLowerCase().startsWith('maltego') &&
        !b.product.name.toLowerCase().startsWith('maltego')
      ) {
        return -1;
      }
      if (
        !a.product.name.toLowerCase().startsWith('maltego') &&
        b.product.name.toLowerCase().startsWith('maltego')
      ) {
        return 1;
      }

      if (a.product.name > b.product.name) {
        return 1;
      }
      if (a.product.name < b.product.name) {
        return -1;
      }

      return 0;
    });

    return newData;
  }, [products]);

  const onRowClickCB = useCallback(
    (product: Product) => {
      const { isSelectionDisabled } = getAssignmentCriteria(
        product,
        user,
        products
      );

      if (!isSelectionDisabled) {
        onRowClick(product);
      }
    },
    [onRowClick, products, user]
  );

  const context: UserEditDrawerTableContext = useMemo(
    () => ({ user, products }),
    [user, products]
  );

  return (
    <Box
      sx={{
        position: 'relative',
        pt: 1,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          ml: 6.25,
          textTransform: 'initial',
        }}
      >
        {t('components.UserEditDrawerTable.assignedProducts')}
      </Typography>
      <Box
        sx={{
          height: '1px',
          mt: 3,
          backgroundColor: 'grey.A200',
        }}
      />
      <BaseTable
        columns={COLUMNS}
        context={context}
        data={sortedProducts}
        rowSx={(rowData: Product) => {
          const { isAssignedToUser, isSelectionDisabled } =
            getAssignmentCriteria(rowData, user, products);

          let textColor;
          if (isAssignedToUser) {
            textColor = 'white';
          } else if (isSelectionDisabled) {
            textColor = 'grey.300';
          }

          return {
            backgroundColor: isAssignedToUser ? 'primary.main' : undefined,
            '.MuiTypography-root, svg': {
              color: textColor,
            },
            ':hover': {
              backgroundColor: isAssignedToUser ? '#586790' : undefined,
            },
            '&:nth-of-type(odd)': {
              backgroundColor: 'none', // Remove default striped table behaviour
            },
          };
        }}
        onTableRowClick={onRowClickCB}
        rowPropsGenerator={(rowData: Product, rowIndex) => {
          const {
            remainingSeats,
            isSelectionDisabled,
            isToolOrDesktopAssigned,
            isSameProductAlreadyAssigned,
          } = getAssignmentCriteria(rowData, user, products);

          const tooltipText = t(
            getAssignmentTooltip({
              remainingSeats: remainingSeats,
              isSameProductAlreadyAssigned: isSameProductAlreadyAssigned,
              isToolOrDesktopAssigned: isToolOrDesktopAssigned,
            })
          );

          if (isSelectionDisabled) {
            return {
              tooltip: { title: tooltipText, followCursor: true },
              disabled: true,
            };
          }
          return {
            tooltip: { title: '', followCursor: true },
            disabled: false,
          };
        }}
      />
    </Box>
  );
};

export default UserEditDrawerTable;
