import React, { useContext, useEffect, useMemo } from 'react';
import { CustomerRolesEnum, useOrganization } from '@maltego/ui';
import Error403 from '@/pages/error/403';
import { useQuery } from '@tanstack/react-query';
import API_URLs from '@/utils/apiURLs';
import { FullPageLoader } from '@maltego/mui-core';

export const RoleContext = React.createContext<CustomerRolesEnum[]>([]);

const getRolesEnumFromString = (role: string): CustomerRolesEnum => {
  for (let enumName of Object.keys(CustomerRolesEnum)) {
    if (enumName === role) {
      return role as CustomerRolesEnum;
    }
  }
  console.error(`Could not find RolesEnum for ${role}`);
  return CustomerRolesEnum.MEMBER;
};

export const RoleContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentOrganization } = useOrganization();
  const {
    refetch,
    data: rolesFromBackend,
    isError,
  } = useQuery<string[]>([API_URLs.myRole], { enabled: false });

  const roles = useMemo(() => {
    if (!rolesFromBackend || rolesFromBackend.length === 0) return null;

    return rolesFromBackend.map((role) => getRolesEnumFromString(role));
  }, [rolesFromBackend]);

  useEffect(() => {
    if (
      currentOrganization &&
      !currentOrganization.userDisconnected &&
      !roles
    ) {
      // Only fetch role once the organization is available
      refetch().then(() => {
        /* Ignore */
      });
    }
  }, [currentOrganization, roles, refetch]);

  if (isError) {
    return <Error403 />;
  }

  if (!roles) {
    return <FullPageLoader />;
  }

  return <RoleContext.Provider value={roles}>{children}</RoleContext.Provider>;
};

export const useRole = () => {
  const roles = useContext(RoleContext);
  const isOrgAdmin = roles.includes(CustomerRolesEnum.ORGANIZATION_ADMIN);
  const isBetaUser = roles.includes(CustomerRolesEnum.BETA_USER);

  return { roles, isOrgAdmin, isBetaUser } as const;
};
