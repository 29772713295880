import { MDialog } from '@maltego/mui-core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import React from 'react';
import { EditIcon, Folder36, OsintProfilerIcon } from '../../icons';
import { CaseManagementDialogStyles } from './dialog/CaseManagementDialog';
import { RenameCaseDialog } from './dialog/RenameCaseDialog';
import StyledMenu from './StyledMenu';

interface CaseDetailsMenuProps {
  caseId: string;
  caseName: string;
  iAmOwner: boolean;
}
const CaseDetailsMenu = ({
  caseId,
  caseName,
  iAmOwner,
}: CaseDetailsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isRenameDialogOpen, setIsRenameDialogOpen] =
    React.useState<boolean>(open);

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const rename = (event: React.MouseEvent<HTMLElement>) => {
    setIsRenameDialogOpen(true);
    handleClose(event);
  };

  // TODO: REACTIVATE WHEN PROFILER FILES CAN BE STORED IN CLOUD BASED CASE MANAGEMENT
  // const profiler = (event:React.MouseEvent<HTMLElement>) => {
  //     handleClose(event);
  //     window.open(process.env.NEXT_PUBLIC_OSINT_PROFILER_URL, '_blank')
  // }
  return (
    <>
      <Button
        id="case-details-menu-button"
        aria-controls={open ? 'case-details-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuButtonClick}
      >
        <MenuIcon />
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        id="case-details-menu"
        MenuListProps={{
          'aria-labelledby': 'case-details-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/*<MenuItem>*/}
        {/*    <ListItemIcon><Folder36 height={20} width={20} /></ListItemIcon>*/}
        {/*    <ListItemText>Create Folder</ListItemText>*/}
        {/*</MenuItem>*/}
        {/* TODO: REACTIVATE WHEN PROFILER FILES CAN BE STORED IN CLOUD BASED CASE MANAGEMENT */}
        {/* <MenuItem onClick={profiler}>*/}
        {/*    <ListItemIcon>*/}
        {/*        <SvgIcon component={OsintProfilerIcon} viewBox="0 0 16 16" sx={{width: 20, height: 20, fill: 'primary.main'}} />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText>Open OSINT Profiler</ListItemText>*/}
        {/*</MenuItem>*/}
        <MenuItem disabled={!iAmOwner} onClick={rename}>
          <ListItemIcon>
            <EditIcon height={20} width={20} />
          </ListItemIcon>
          <ListItemText>Rename Case</ListItemText>
        </MenuItem>
      </StyledMenu>
      <MDialog
        sx={CaseManagementDialogStyles}
        open={isRenameDialogOpen}
        size={'md'}
      >
        <RenameCaseDialog
          caseId={caseId}
          caseName={caseName}
          onConfirm={() => setIsRenameDialogOpen(false)}
          onCancel={() => setIsRenameDialogOpen(false)}
        />
      </MDialog>
    </>
  );
};
export default CaseDetailsMenu;
