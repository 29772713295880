import { MDialog, WarningIcon } from '@maltego/mui-core';
import { DialogContent, SelectChangeEvent, SvgIcon } from '@mui/material';
import { useSession } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetCaseMembersQueryKey,
  useAddMembersToCase,
  useChangeCaseMemberRole,
  useGetAllCases,
  useGetCaseGraphs,
  useGetCaseMembers,
  useRemoveMembersFromCase,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import {
  CaseMember,
  CaseMemberCreate,
  CaseMemberCreateRole,
} from '../../../graph-store-client/model';
import { CaseMemberDialogRow } from './CaseMemberDialogRow';
import { AddMemberSection } from './AddMemberSection';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface caseMemberCreateDialogProps {
  caseId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ShareCaseDialog: React.FC<caseMemberCreateDialogProps> = ({
  caseId,
  onConfirm,
  onCancel,
}: caseMemberCreateDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data } = useSession();
  const { data: members } = useGetCaseMembers(caseId);
  const { data: graphs } = useGetCaseGraphs(caseId);
  const { data: cases } = useGetAllCases();
  const { showSnackbar } = useSnackbar();

  const owner = members?.find((member) => member.role === 'OWNER');
  const iAmOwner = owner?.userEmail === data?.user?.email;

  const memberGraphPresentInCase = (member: CaseMember) => {
    return !!graphs?.find((graph) => graph.ownerEmail === member.userEmail);
  };

  const addMembersMutation = useAddMembersToCase();
  const updateMemberMutation = useChangeCaseMemberRole();
  const removeMembersMutation = useRemoveMembersFromCase();

  const error = addMembersMutation.error;
  const errorDisplay = error?.response?.data?.title;

  const updateMembership = (member: CaseMember) => {
    return (e: SelectChangeEvent<string>) => {
      e.stopPropagation();

      if (e.target.value === 'DELETE') {
        removeMembers([member.userEmail]);
        return;
      }

      if (Object.keys(CaseMemberCreateRole).includes(e.target.value)) {
        updateRole({
          ...member,
          role: e.target.value as CaseMemberCreateRole,
        });
        return;
      }

      console.warn(
        `e.target.value was expected to be 'DELETE' or ${Object.keys(
          CaseMemberCreateRole
        )} but was ${e.target.value}`
      );
    };
  };
  const removeMembers = (membersToRemove: string[]) => {
    removeMembersMutation.mutate(
      {
        caseId,
        data: membersToRemove,
      },
      {
        onSuccess: () => {
          const successMessage = `${membersToRemove} is removed from ${
            cases?.find((c) => c.id === caseId)?.name
          } successfully.`;
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);

          queryClient.setQueryData<CaseMember[]>(
            getGetCaseMembersQueryKey(caseId),
            (oldMembers) => [
              ...(oldMembers ?? []).filter(
                (oldMember) => !membersToRemove.includes(oldMember.userEmail)
              ),
            ]
          );
        },
      }
    );
  };
  const updateRole = (newMember: CaseMemberCreate) => {
    updateMemberMutation.mutate(
      {
        caseId,
        data: newMember,
      },
      {
        onSuccess: (updatedMember) => {
          const successMessage = `${
            newMember.userEmail
          } is changed to new role ${newMember.role} for ${
            cases?.find((c) => c.id === caseId)?.name
          } successfully`;
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);

          queryClient.setQueryData<CaseMember[]>(
            getGetCaseMembersQueryKey(caseId),
            (members) => {
              return (members ?? []).map((member) =>
                member.userEmail === updatedMember.userEmail
                  ? updatedMember
                  : member
              );
            }
          );
        },
      }
    );
  };
  const caseMemberCreate = (data: CaseMemberCreate) => {
    addMembersMutation.mutate(
      {
        caseId,
        data: [data],
      },
      {
        onSuccess: (newMembers) => {
          const successMessage = `${data.userEmail} is added to ${
            cases?.find((c) => c.id === caseId)?.name
          } as ${data.role} successfully.`;
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);

          queryClient.setQueryData<CaseMember[]>(
            getGetCaseMembersQueryKey(caseId),
            (members) => [...(members ?? []), ...newMembers]
          );
          onConfirm();
        },
      }
    );
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        {iAmOwner
          ? t('pages.cases.sharingOptions')
          : t('pages.cases.sharingInfo')}
      </MDialog.Title>
      <DialogContent>
        {iAmOwner && (
          <AddMemberSection
            onAddMember={caseMemberCreate}
            members={members ?? []}
          />
        )}
        {members?.map((member) => (
          <CaseMemberDialogRow
            key={member.userEmail}
            member={member}
            iAmOwner={iAmOwner}
            memberGraphPresent={memberGraphPresentInCase(member)}
            updateMembership={updateMembership}
          />
        ))}
      </DialogContent>
      {errorDisplay && (
        <MDialog.Alert
          startIcon={
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ fill: 'none !important' }}
              component={WarningIcon}
            />
          }
        >
          {errorDisplay}
        </MDialog.Alert>
      )}
    </>
  );
};
