import { createContext, useContext } from 'react';
import { AccountDto } from '@portal/api/src/gw/model';

export interface OrganizationContextValue {
  currentOrganization?: AccountDto;
  organizations: AccountDto[];

  setCurrentOrganizationID: (organizationID?: string) => void;

  // React-Query
  isLoading: boolean;
  isError: boolean;
}

export const OrganizationContext =
  createContext<OrganizationContextValue>(undefined);

export function useOrganization(): OrganizationContextValue {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within a CustomerOrganizationProvider'
    );
  }
  return context;
}
