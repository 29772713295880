import {
  getGetAllGraphsQueryKey,
  getGetCaseGraphsQueryKey,
  useLockGraph,
} from '../graph-store-client/maltegoCaseManagementAPI';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useGraphLockHandler = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const lockGraphMutation = useLockGraph();

  const lockGraph = useCallback(
    (graphId: string, graphName?: string, caseId?: string) =>
      lockGraphMutation.mutate(
        { graphId },
        {
          onSuccess: () => {
            const successMessage = t(
              'hooks.useGraphLockHandler.lockSuccessMessage',
              { graphName: graphName ?? '' }
            );
            showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);
          },
          onError: (error) => {
            showSnackbar(
              error.response?.data.detail as string,
              'error',
              SnackBarDuration.STANDARD
            );
          },
          onSettled: () => {
            if (caseId) {
              void queryClient.invalidateQueries({
                queryKey: getGetCaseGraphsQueryKey(caseId),
              });
            } else {
              void queryClient.invalidateQueries({
                queryKey: getGetAllGraphsQueryKey({ unassigned: true }),
              });
            }
          },
        }
      ),
    [queryClient, lockGraphMutation, t, showSnackbar]
  );

  return { lockGraph } as const;
};
