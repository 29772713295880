import React, { useCallback, useMemo, useState } from 'react';
import { Box, SvgIcon } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import useUserEditMutation, { CombinedMutation } from './useUserEditMutation';
import UserEditDrawerHeader from './UserEditDrawerHeader';
import UserEditDrawerTable from './UserEditDrawerTable';
import { Product } from '../../types';
import { InvitedOrExistingMember } from '../../types';
import { useOrganization } from '../../contexts';
import { MDrawer } from '@maltego/mui-core';
import { WarningIcon } from '../../icons';
import { isProductDesktopOrTool } from '../../utils';

const getProductIdsAssignedToUser = (user, products) => {
  const productIdsAssignedToUser = products.reduce((acc, product) => {
    if (!!product.assignees.find((assignee) => assignee.id === user.id)) {
      acc.push(product.id);
    }
    return acc;
  }, [] as string[]);
  productIdsAssignedToUser.sort(); // Sort assigned products, so we can detect when assignments change
  return productIdsAssignedToUser;
};

export const areStringArraysEqual = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
};

export const getValidProducts = (products: Product[]) =>
  products.filter(
    (product) =>
      !product.entitlementPeriod.validUntil ||
      product.entitlementPeriod.validUntil * 1000 > Date.now()
  );

interface UserDrawerProps {
  isTheOnlyAdmin: boolean;
  selectedUser: InvitedOrExistingMember;
  products: Product[];
  onClose: () => void;
  queryOrganizationsURL: string;
  queryProductsURL: string;
  queryRolesURL: string;
  queryUsersURL: string;
  updateUserRolesURL: string;
  updateUserAssignmentsURL: string;
  removeInvitationURL: string;
  removeUserByAdminURL: string;
  removeUserBySelfURL: string;
  resendUserInvitationURL: string;
  resendShadowUserInvitationURL: string;
}

const UserEditDrawer = ({
  isTheOnlyAdmin,
  selectedUser,
  products: originalProducts,
  onClose,
  queryOrganizationsURL,
  queryProductsURL,
  queryRolesURL,
  queryUsersURL,
  updateUserRolesURL,
  updateUserAssignmentsURL,
  removeInvitationURL,
  removeUserByAdminURL,
  removeUserBySelfURL,
  resendUserInvitationURL,
  resendShadowUserInvitationURL,
}: UserDrawerProps) => {
  const { t } = useTranslation();
  const startUserRole = selectedUser?.roles[0];
  const [role, setRole] = useState(startUserRole);
  const [products, setProducts] = useState<Product[]>(originalProducts);
  const { currentOrganization } = useOrganization();

  const validProducts = useMemo(() => getValidProducts(products), [products]);

  const mutation = useUserEditMutation(
    queryUsersURL,
    queryProductsURL,
    updateUserRolesURL,
    updateUserAssignmentsURL,
    onClose
  );

  const originalAssignments = useMemo(
    () => getProductIdsAssignedToUser(selectedUser, originalProducts),
    [selectedUser, originalProducts]
  );
  const currentAssignments = useMemo(
    () => getProductIdsAssignedToUser(selectedUser, validProducts),
    [selectedUser, validProducts]
  );

  const hasRoleChanged = role !== startUserRole;
  const hasAssignmentsChanged = useMemo(
    () => !areStringArraysEqual(originalAssignments, currentAssignments),
    [originalAssignments, currentAssignments]
  );

  const isAssignmentWithoutMaltegoLicense = useMemo(() => {
    const getMaltegoLicenseAssignment = currentAssignments.find((assignment) =>
      validProducts.find(
        (product) =>
          product.id === assignment && isProductDesktopOrTool(product)
      )
    );
    return (
      currentAssignments.length > 0 && getMaltegoLicenseAssignment === undefined
    );
  }, [currentAssignments, validProducts]);

  const onProductClick = useCallback(
    (clickedProduct: Product) => {
      const newProducts = validProducts.map((product: Product) => {
        if (clickedProduct.id !== product.id) {
          return product;
        } else {
          // Create copy to prevent modifying original products data
          const updatedProduct = { ...product };
          const isCurrentlyAssigned = !!updatedProduct.assignees.find(
            (assignee) => assignee.id === selectedUser.id
          );

          if (isCurrentlyAssigned) {
            updatedProduct.assignees = updatedProduct.assignees.filter(
              (assignee) => assignee.id !== selectedUser.id
            );
          } else {
            const hasAvailableSeats =
              updatedProduct.productPlan.seats -
                updatedProduct.assignees.length >
              0; // unlimited seats is 1 million.

            if (hasAvailableSeats) {
              updatedProduct.assignees = [
                ...updatedProduct.assignees,
                {
                  id: selectedUser.id,
                  email: selectedUser.email,
                },
              ];
            }
          }
          return updatedProduct;
        }
      });

      setProducts(newProducts);
    },
    [validProducts, selectedUser.id, selectedUser.email]
  );

  const onConfirm = useCallback(() => {
    const mutationVariables: CombinedMutation = {
      userId: selectedUser.id,
      accountId: currentOrganization.id,
      newRole: hasRoleChanged ? role : null,
      newAssignments: hasAssignmentsChanged ? currentAssignments : null,
    };
    mutation.mutate(mutationVariables);
  }, [
    selectedUser.id,
    currentOrganization.id,
    hasRoleChanged,
    role,
    hasAssignmentsChanged,
    currentAssignments,
    mutation,
  ]);

  return (
    <>
      <MDrawer.Title
        drawerTitle="components.UserEditDrawer.userInfo"
        onClose={onClose}
      />

      <>
        <MDrawer.Body size="lg" disablePadding>
          <Box sx={{ px: 8.25, pb: 4 }}>
            <UserEditDrawerHeader
              queryOrganizationsURL={queryOrganizationsURL}
              queryRolesURL={queryRolesURL}
              queryUsersURL={queryUsersURL}
              removeInvitationURL={removeInvitationURL}
              removeUserByAdminURL={removeUserByAdminURL}
              removeUserBySelfURL={removeUserBySelfURL}
              resendUserInvitationURL={resendUserInvitationURL}
              resendShadowUserInvitationURL={resendShadowUserInvitationURL}
              isTheOnlyAdmin={isTheOnlyAdmin}
              user={selectedUser}
              currentOrganization={currentOrganization}
              numAssignedProducts={currentAssignments.length}
              onRoleChange={(e) => setRole(e.target.value)}
              onClose={onClose}
            />
          </Box>

          <Box
            sx={{
              px: 2,
              pb: 4,
            }}
          >
            <UserEditDrawerTable
              user={selectedUser}
              products={validProducts}
              onRowClick={onProductClick}
            />
          </Box>
        </MDrawer.Body>
      </>
      {isAssignmentWithoutMaltegoLicense && (
        <MDrawer.Hint
          sx={{ backgroundColor: 'grey.A100' }}
          startIcon={
            <SvgIcon
              component={WarningIcon}
              sx={{ fill: 'none !important' }}
              viewBox="0 0 24 24"
            />
          }
        >
          {t('components.UserEditDrawer.hintForUpdateAssignment')}
        </MDrawer.Hint>
      )}
      <MDrawer.Actions
        confirmText="common.button.update"
        confirmDisabled={
          (!hasRoleChanged && !hasAssignmentsChanged) ||
          isAssignmentWithoutMaltegoLicense
        }
        isLoading={mutation.isLoading}
        errorMessage={
          mutation.error?.displayMessage || mutation.error?.toString()
        }
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
};

export default UserEditDrawer;
