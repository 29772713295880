import { ThemeProvider } from '@mui/system';
import { lightMaltegoTheme } from '@maltego/mui-core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({ ...lightMaltegoTheme });

const QueryClientProviderMock = ({ children }) => {
  const queryClient = new QueryClient({});
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

const ThemeProviderMock = ({ children }) => {
  return <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>;
};

interface CustomMockProvidersProps {
  query?: boolean;
  theme?: boolean;
  children: React.ReactNode;
}

export const CustomMockProviders = ({
  query,
  theme,
  children,
}: CustomMockProvidersProps) => {
  const mocks = [
    ...(query ? [QueryClientProviderMock] : []),
    ...(theme ? [ThemeProviderMock] : []),
  ];
  if (mocks.length === 0) return <>{children}</>;

  return (
    <>
      {mocks.reduce(
        (previousElement, currentElement) =>
          React.createElement(currentElement, null, previousElement),
        children
      )}
    </>
  );
};

export const AllMockProviders = ({ children }) => {
  return (
    <CustomMockProviders query theme>
      {children}
    </CustomMockProviders>
  );
};
