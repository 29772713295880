import { $createCodeNode } from '@lexical/code';
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text';
import { $setBlocksType_experimental } from '@lexical/selection';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  LexicalEditor,
} from 'lexical';
import DropDown, { DropDownItem } from '../../../ui/DropDown';
import { blockTypeToBlockName } from '../Constants';

function dropDownActiveClass(active: boolean) {
  if (active) return 'active dropdown-item-active';
  else return '';
}

const BlockFormatDropDown = ({
  editor,
  blockType,
  disabled = false,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
  disabled?: boolean;
}): JSX.Element => {
  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        )
          $setBlocksType_experimental(selection, () => $createParagraphNode());
      });
    }
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType_experimental(selection, () =>
            $createHeadingNode(headingSize)
          );
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType_experimental(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          if (selection.isCollapsed()) {
            $setBlocksType_experimental(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection))
              selection.insertRawText(textContent);
          }
        }
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={'icon block-type ' + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
        onClick={formatParagraph}
      >
        <i className="icon paragraph" />
        <span className="text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h1')}
        onClick={() => formatHeading('h1')}
      >
        <i className="icon h1" />
        <span className="text">Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h2')}
        onClick={() => formatHeading('h2')}
      >
        <i className="icon h2" />
        <span className="text">Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'h3')}
        onClick={() => formatHeading('h3')}
      >
        <i className="icon h3" />
        <span className="text">Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'bullet')}
        onClick={formatBulletList}
      >
        <i className="icon bullet-list" />
        <span className="text">Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'number')}
        onClick={formatNumberedList}
      >
        <i className="icon numbered-list" />
        <span className="text">Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'check')}
        onClick={formatCheckList}
      >
        <i className="icon check-list" />
        <span className="text">Check List</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'quote')}
        onClick={formatQuote}
      >
        <i className="icon quote" />
        <span className="text">Quote</span>
      </DropDownItem>
      <DropDownItem
        className={'item ' + dropDownActiveClass(blockType === 'code')}
        onClick={formatCode}
      >
        <i className="icon code" />
        <span className="text">Code Block</span>
      </DropDownItem>
    </DropDown>
  );
};

export default BlockFormatDropDown;

// import {
//     $createCodeNode
// } from '@lexical/code';
// import {
//     INSERT_CHECK_LIST_COMMAND,
//     INSERT_ORDERED_LIST_COMMAND,
//     INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND
// } from '@lexical/list';
// import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text";
// import { $setBlocksType_experimental } from "@lexical/selection";
// import { ListItem, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
// import { $createParagraphNode, $getSelection, $isRangeSelection, DEPRECATED_$isGridSelection, LexicalEditor } from "lexical";
// import { useState } from 'react';
// import { blockTypeToBlockName } from "../Constants";

// const BlockFormatDropDown = ({
//     editor,
//     blockType,
//     disabled = false,
// }: {
//     blockType: keyof typeof blockTypeToBlockName;
//     editor: LexicalEditor;
//     disabled?: boolean;
// }): JSX.Element => {
//     const formatParagraph = () => {
//         if (blockType !== 'paragraph') {
//             editor.update(() => {
//                 const selection = $getSelection();
//                 if (
//                     $isRangeSelection(selection) ||
//                     DEPRECATED_$isGridSelection(selection)
//                 )
//                     $setBlocksType_experimental(selection, () => $createParagraphNode());
//             });
//         }
//     };

//     const formatHeading = (headingSize: HeadingTagType) => {
//         if (blockType !== headingSize) {
//             editor.update(() => {
//                 const selection = $getSelection();
//                 if (
//                     $isRangeSelection(selection) ||
//                     DEPRECATED_$isGridSelection(selection)
//                 ) {
//                     $setBlocksType_experimental(selection, () =>
//                         $createHeadingNode(headingSize),
//                     );
//                 }
//             });
//         }
//     };

//     const formatBulletList = () => {
//         if (blockType !== 'bullet') {
//             editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
//         } else {
//             editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
//         }
//     };

//     const formatCheckList = () => {
//         if (blockType !== 'check') {
//             editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
//         } else {
//             editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
//         }
//     };

//     const formatNumberedList = () => {
//         if (blockType !== 'number') {
//             editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
//         } else {
//             editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
//         }
//     };

//     const formatQuote = () => {
//         if (blockType !== 'quote') {
//             editor.update(() => {
//                 const selection = $getSelection();
//                 if (
//                     $isRangeSelection(selection) ||
//                     DEPRECATED_$isGridSelection(selection)
//                 ) {
//                     $setBlocksType_experimental(selection, () => $createQuoteNode());
//                 }
//             });
//         }
//     };

//     const formatCode = () => {
//         if (blockType !== 'code') {
//             editor.update(() => {
//                 let selection = $getSelection();

//                 if (
//                     $isRangeSelection(selection) ||
//                     DEPRECATED_$isGridSelection(selection)
//                 ) {
//                     if (selection.isCollapsed()) {
//                         $setBlocksType_experimental(selection, () => $createCodeNode());
//                     } else {
//                         const textContent = selection.getTextContent();
//                         const codeNode = $createCodeNode();
//                         selection.insertNodes([codeNode]);
//                         selection = $getSelection();
//                         if ($isRangeSelection(selection))
//                             selection.insertRawText(textContent);
//                     }
//                 }
//             });
//         }
//     };

//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//     const [selectedIndex, setSelectedIndex] = useState("h1");
//     const open = Boolean(anchorEl);
//     const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleMenuItemClick = (
//         event: SelectChangeEvent,
//         // index: string,
//     ) => {
//         setSelectedIndex(event.target.value as string);
//         setAnchorEl(null);
//     };

//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     return (

//         <Select
//             value={selectedIndex}
//             onChange={handleMenuItemClick}
//             style={{ height: "50px" }}
//         >
//             <MenuItem value={'paragraph'} onClick={formatParagraph}>
//                 <ListItem>
//                     <ListItemText> Normal</ListItemText>
//                 </ListItem>
//             </MenuItem>
//             <MenuItem value={'h1'} onClick={() => formatHeading('h1')}>
//                 <ListItem>
//                     <ListItemText> Heading 1</ListItemText>
//                 </ListItem>
//             </MenuItem>
//             <MenuItem value={'h2'} onClick={() => formatHeading('h2')}>
//                 <ListItem>
//                     <ListItemText> Heading 2</ListItemText>
//                 </ListItem>
//             </MenuItem>
//         </Select>
//     );
// }

// export default BlockFormatDropDown;
