const assignColor = (productColors, productId) => {
  // the same product (no matter the size of the bundles) should be the same color
  const colorAssignments = {
    'maltoso.social': productColors.mintGreen,
    'maltoso.infrastructure': productColors.azure,
    'shodan-hub': productColors.peach,
    pipl: productColors.sky,
    scamadviser: productColors.pink,
    intel471enterprise: productColors.rose,
    ciphertrace: productColors.salmon,
  };

  const matchedProductKey = Object.keys(colorAssignments).find(
    (assignmentKey) => productId.toLowerCase().includes(assignmentKey)
  );

  return !matchedProductKey
    ? productColors.default
    : colorAssignments[matchedProductKey];
};

export default assignColor;
