import React from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

const BetaTagWrapper: React.FC<{
  betaFontSize?: number | string;
  children: React.ReactNode;
  isBetaShown: boolean;
  sx?: SxProps;
}> = ({ betaFontSize = 14, children, isBetaShown, sx }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ...sx }}>
    <Typography
      sx={{
        display: isBetaShown ? undefined : 'none',
        color: 'textLink.main',
        fontSize: betaFontSize,
      }}
    >
      β
    </Typography>
    {children}
  </Box>
);

export default BetaTagWrapper;
