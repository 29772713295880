import React, { useCallback, useMemo } from 'react';
import sideNavItems, {
  CustomerNavigationItem,
} from '@/components/layout/sideNavItems';
import { hasRequiredRoles } from '@/utils/hasRequiredRoles';
import { useRole } from '@/context/RoleProvider/RoleProvider';
import { NavigationItem, SideNav } from '@maltego/mui-core';
import { useAuthentication } from '@maltego/common-contexts';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const whitelist = ['maltego.com'];

const SideNavSection: React.FC<{
  sectionName: string;
  sectionItems: NavigationItem[];
}> = ({ sectionName, sectionItems }) =>
  sectionItems.length > 0 && (
    <>
      <Box
        sx={{
          height: 54,
          p: '25px 0 0 18px',
          flex: 1,
          display: {
            xs: 'none',
            md: 'inherit',
          },
        }}
      >
        <Typography
          sx={{
            color: 'grey.600',
            fontSize: 12,
            fontWeight: 600,
            letterSpacing: 0.5,
            lineHeight: '15.06px',
          }}
        >
          {sectionName}
        </Typography>
      </Box>
      <Box
        sx={{
          height: 54,
          p: '34px 17px 0 17px',
          display: {
            xs: 'inherit',
            md: 'none',
          },
        }}
      >
        <Divider sx={{ borderColor: 'grey.700' }} />
      </Box>
      <SideNav
        items={sectionItems}
        sx={{ height: 'auto', borderRight: 'none' }}
      />
    </>
  );

const CustomerSideNav = () => {
  const { t } = useTranslation();
  const { roles } = useRole();
  const { claims } = useAuthentication();
  const userEmail = claims.email;
  const emailDomain = userEmail.toLowerCase().split('@')[1];
  const isEmailDomainOnWhitelist = whitelist.includes(emailDomain);

  const filterNavItems = useCallback(
    (navItems: CustomerNavigationItem[]) => {
      return navItems.filter((item) =>
        hasRequiredRoles(roles, item.requiresRole)
      );
    },
    [roles, isEmailDomainOnWhitelist]
  );

  const vaultNavItems = useMemo(
    () => filterNavItems(sideNavItems.vault),
    [filterNavItems]
  );

  const desktopNavItems = useMemo(() => {
    if (!isEmailDomainOnWhitelist) {
      sideNavItems.desktop[1].isComingSoon = true;
    }
    return filterNavItems(sideNavItems.desktop);
  }, [filterNavItems, isEmailDomainOnWhitelist]);

  const adminNavItems = useMemo(
    () => filterNavItems(sideNavItems.admin),
    [filterNavItems]
  );

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'primary.main',
        borderRight: '1px solid',
        borderColor: 'dividers.strong',
      }}
    >
      <SideNavSection
        sectionName={t('layout.customerSideNav.vault')}
        sectionItems={vaultNavItems}
      />
      <SideNavSection
        sectionName={t('layout.customerSideNav.admin')}
        sectionItems={adminNavItems}
      />
      <SideNavSection
        sectionName={t('layout.customerSideNav.desktop')}
        sectionItems={desktopNavItems}
      />
    </Box>
  );
};

export default CustomerSideNav;
