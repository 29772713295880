import { isMaltegoGraphFile } from '../../types/typeGuards';
import { Case, Graph } from '../../graph-store-client/model';
import editGraphInMaltegoClient from '../cases/links/editGraphInMaltegoClient';
import viewGraphInMaltegoClient from '../cases/links/viewGraphInMaltegoClient';
import { MaltegoFile } from './MaltegoFile';
import { useSnackbar } from '@maltego/ui';
import { useRouter } from 'next/router';
import { isLockOwner } from './CasesTable/utils/CaseAccessUtils';
import { useSession } from 'next-auth/react';
import { useGraphLockHandler } from '../../hooks/useGraphLockHandler';

export function useTableRowClickCaseGraph() {
  const router = useRouter();
  const { showSnackbar } = useSnackbar();
  const { data } = useSession();
  const { lockGraph } = useGraphLockHandler();

  return (rowData: MaltegoFile<Case | Graph>) => {
    if (!isMaltegoGraphFile(rowData)) {
      router.push(`cases/${rowData.id}`);
    } else {
      const graph = rowData as Graph;
      if (
        graph.lock !== undefined &&
        graph.lock !== null &&
        !isLockOwner(rowData, data || undefined)
      ) {
        viewGraphInMaltegoClient(graph.id, showSnackbar);
      } else {
        lockGraph(graph.id, graph.name, graph.caseId);
        editGraphInMaltegoClient(graph.id, showSnackbar);
      }
    }
  };
}
