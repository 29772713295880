import { Box, LinearProgress } from '@mui/material';
import { Editor } from '@vault/reporting';
import { EditorState } from 'lexical';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  getGetAllReportsQueryKey,
  useCreateReport,
  useGetAllReports,
  useUpdateReport,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { CreateReport, UpdateReport } from '../../../graph-store-client/model';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

interface CaseReportProps {
  caseId: string;
}
const CaseReport = ({ caseId }: CaseReportProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetAllReports(caseId);
  const useCreateReportMutation = useCreateReport();
  const useUpdateReportMutation = useUpdateReport();
  const { showSnackbar } = useSnackbar();

  let initialReportContent: any;

  const onReportContentChange = (editorState: EditorState) => {
    saveReportHandler(editorState);
  };

  const saveReportHandler = (editorState: EditorState) => {
    if (data && data.length > 0) {
      const updateReportPayload: UpdateReport = {
        name: data[0].name,
        content: editorState.toJSON(),
      };
      useUpdateReportMutation.mutate(
        { caseId: caseId, reportId: data[0].id, data: updateReportPayload },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({
              queryKey: getGetAllReportsQueryKey(caseId),
            });
          },
          onError: () => {
            showSnackbar(
              'Failed to save report changes',
              'error',
              SnackBarDuration.STANDARD
            );
          },
        }
      );
    } else {
      const createReportPayload: CreateReport = {
        name: 'Report 1',
        content: editorState.toJSON(),
      };
      useCreateReportMutation.mutate(
        { caseId: caseId, data: createReportPayload },
        {
          onSuccess: (payload) => {
            queryClient.setQueryData(getGetAllReportsQueryKey(caseId), [
              payload,
            ]);
          },
          onError: () => {
            showSnackbar(
              'Failed to save report changes',
              'error',
              SnackBarDuration.STANDARD
            );
          },
        }
      );
    }
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  if (data && data.length > 0) {
    try {
      initialReportContent = JSON.stringify(data[0].content);
    } catch (error) {}
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Editor
          initialEditorState={initialReportContent}
          id="caseReport"
          onChange={onReportContentChange}
          debounceTime={1000}
        />
      </Box>
    </>
  );
};
export default CaseReport;
