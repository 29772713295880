import { ProductTypeEnum } from '../types/Product';
import { InvitedOrExistingMember, Product } from '../types';

export const isProductDesktopOrTool = (product) =>
  product.product.type === ProductTypeEnum.DESKTOP ||
  product.product.type === ProductTypeEnum.TOOL;

const getAvailableSeats = (product) =>
  Math.max(product.productPlan.seats - product.assignees.length, 0); // Available seats should not be less than 0

interface AssignmentCriteria {
  remainingSeats: number;
  isAssignedToUser?: boolean;
  isSameProductAlreadyAssigned: boolean;
  isSelectionDisabled?: boolean;
  isToolOrDesktopAssigned: boolean;
}

export const getAssignmentCriteria = (
  selectedProduct: Product,
  user: InvitedOrExistingMember,
  products: Product[]
): AssignmentCriteria => {
  const remainingSeats = getAvailableSeats(selectedProduct);

  const isAssignedToUser = !!selectedProduct.assignees.find(
    (assignee) => assignee.id === user.id
  );

  const isToolOrDesktopAssigned = !!products.find(
    (product) =>
      isProductDesktopOrTool(product) &&
      product.assignees.some((assignee) => assignee.id === user.id)
  );

  const theSameProducts = products.filter(
    (product) =>
      product.product.id === selectedProduct.product.id &&
      product.id !== selectedProduct.id
  );

  const isSameProductAlreadyAssigned = !!theSameProducts.find((product) =>
    product.assignees.some((assignee) => assignee.id === user.id)
  );

  const isSelectionDisabled =
    (!isToolOrDesktopAssigned &&
      !isProductDesktopOrTool(selectedProduct) &&
      !isAssignedToUser) ||
    (remainingSeats === 0 && !isAssignedToUser) ||
    (isSameProductAlreadyAssigned && !isAssignedToUser);

  return {
    remainingSeats,
    isAssignedToUser,
    isSameProductAlreadyAssigned,
    isSelectionDisabled,
    isToolOrDesktopAssigned,
  };
};

export const getAssignmentTooltip = ({
  remainingSeats,
  isSameProductAlreadyAssigned,
  isToolOrDesktopAssigned,
}: AssignmentCriteria) => {
  if (remainingSeats <= 0) {
    return 'common.tooltip.noRemainingSeats';
  }

  if (isSameProductAlreadyAssigned) {
    return 'common.tooltip.alreadyAssigned';
  }

  if (!isToolOrDesktopAssigned) {
    return 'common.tooltip.noMaltegoLicenseAssigned';
  }

  return null;
};
