// https://mui.com/customization/default-theme/
import { lightMaltegoTheme, darkMaltegoTheme } from '@maltego/mui-core';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    textLink: Palette['primary'];
  }
  interface PaletteOptions {
    textLink: PaletteOptions['primary'];
  }
}

export const lightTheme = createTheme({ ...lightMaltegoTheme });
export const darkTheme = createTheme({ ...darkMaltegoTheme });
