import { AxiosError } from 'axios';

export class APIError extends Error {
  statusCode: number;
  message: string; // Default error message which may not be user-friendly
  displayMessage: string; // Use friendly error message to render in the UI

  constructor(message: string, displayMessage: string, statusCode: number) {
    super(message);

    this.displayMessage = displayMessage;
    this.statusCode = statusCode;

    this.name = 'APIError';
  }
}

export const getDisplayMessageFromError = (error: AxiosError): string => {
  /**
   * When the backend throws a 5xx code we assume something unexpected happened, and that the response
   * from the server isn't user ready.
   *
   * A 4xx indicates an expected error, or validation failure. The frontend assumes that the error messages
   * for these states codes, are safe to display to the user.
   */
  const response = error.response;
  const errorStatusCode = error.response.status;
  if (errorStatusCode >= 400 && errorStatusCode < 500) {
    if (errorStatusCode === 403) {
      return 'Permission Denied';
    }

    const contentType = response.headers['content-type'];
    const isJsonType = contentType && contentType.includes('application/json');

    if (isJsonType) {
      const responseData = response.data as { [key: string]: string };
      if (responseData.displayMessage) {
        return responseData.displayMessage;
      }
      if (responseData.message) {
        return responseData.message;
      }
    } else if (typeof response.data === 'string') {
      return response.data;
    }
  }

  return 'An unexpected error occurred.';
};
