import { useRole } from '@/context/RoleProvider/RoleProvider';
import {
  APIError,
  useOrganization,
  UserMenu,
  UserMenuItem,
  UserRole,
} from '@maltego/ui';
import { Box, Divider, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import API_URLs from '@/utils/apiURLs';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuthentication } from '@maltego/common-contexts';
import AppMenuItem from '@/layout/CustomerTopBar/AppMenuItem';
import CustomerPortalLogo from '@/icons/customer-portal-logo24.svg';
import MaltegoProductLogo from '@/icons/maltego-product-logo24.svg';
import SparkLogo from '@/icons/spark-logo.svg';
import UserIcon from '@/icons/user.svg';

const MutedText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 400,
  fontSize: '12px',
}));

const CustomerUserMenu: React.FC = () => {
  const { claims } = useAuthentication();
  const { currentOrganization } = useOrganization();
  const { roles: userRoles } = useRole();

  const userEmail = claims.email;
  const username = claims.name;
  const accountName = currentOrganization?.name;

  const { data: allRoles = [] } = useQuery<UserRole[], APIError>([
    API_URLs.roles,
  ]);

  const roles = allRoles.filter((role) =>
    userRoles.some((userRole) => userRole === role.id)
  );

  return (
    <UserMenu>
      <Box
        sx={{
          my: '15px',
          mx: '13px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '14px',
          }}
        >
          {username}
        </Typography>
        <MutedText>{userEmail}</MutedText>
        <Divider
          sx={{
            width: (theme) => theme.spacing(2),
            borderColor: (theme) => theme.palette.grey[600],
            my: (theme) => theme.spacing(1),
          }}
        />
        <MutedText>{accountName}</MutedText>
        {roles.map((role) => (
          <MutedText key={role.id}>{role.name}</MutedText>
        ))}
      </Box>
      <Divider />
      <Box>
        <AppMenuItem
          description="components.CustomerUserMenu.portalDescription"
          icon={CustomerPortalLogo}
          isUnclickable
          label="Customer Portal"
        />
        <AppMenuItem
          description="components.CustomerUserMenu.osintProfilerDescription"
          icon={MaltegoProductLogo}
          label="OSINT Profiler"
          link={process.env.NEXT_PUBLIC_OSINT_PROFILER_URL}
        />
        <AppMenuItem
          description="components.CustomerUserMenu.sparkDescription"
          icon={SparkLogo}
          label="Spark"
          link="https://spark.maltego.com/"
        />
      </Box>
      <Divider />
      {/*TODO: we can remove this condition once profile edit page-portal redirection is working */}
      {process.env.NEXT_PUBLIC_B2C_USER_PROFILE && (
        <UserMenuItem
          icon={UserIcon}
          label="layout.customerTopBar.myProfile"
          onClick={() =>
            (window.location.href = process.env.NEXT_PUBLIC_B2C_USER_PROFILE)
          }
        />
      )}
    </UserMenu>
  );
};

export default CustomerUserMenu;
