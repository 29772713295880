import Backdrop from '@mui/material/Backdrop';
import Menu from '@mui/material/Menu';
import React, { useRef } from 'react';
import { LogoutIcon } from '../../icons';
import UserAvatar from './UserAvatar';
import UserMenuItem from './UserMenuItem';
import { useToggle } from '@maltego/mui-core';
import { useAuthentication } from '@maltego/common-contexts';

const UserMenu: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isOpen, onToggle, onClose } = useToggle();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { logout } = useAuthentication();

  const onLogout = () => {
    onClose();
    logout();
  };

  return (
    <>
      <UserAvatar ref={anchorRef} isActive={isOpen} onClick={onToggle} />
      <Menu
        id="account-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          mt: 1.3,
          '.MuiPaper-root': {
            backgroundColor: 'background.default',
            minWidth: 250,
            boxShadow: '3px 5px 0 rgba(0, 0, 0, 0.08)',
          },
          '.MuiList-root': {
            py: 0,
          },
        }}
      >
        {children}
        <UserMenuItem
          icon={LogoutIcon}
          label={'layout.UserMenu.logout'}
          onClick={onLogout}
        />
      </Menu>
      <Backdrop
        sx={{
          backgroundColor: 'rgba(50, 56, 72, 0.45)',
        }}
        open={isOpen}
        onClick={onClose}
      />
    </>
  );
};

export default UserMenu;
