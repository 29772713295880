import React, { useCallback } from 'react';
import { FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';
import { InvitedOrExistingMember, InvitedUser } from '../../types';
import { SnackBarDuration, useOrganization, useSnackbar } from '../../contexts';
import { useStandardMutation } from '../../utils';
import BulkEmailInput from './BulkEmailInput';
import UserRolesSelect from '../UserRolesSelect/UserRolesSelect';
import { MDrawer } from '@maltego/mui-core';
import { InfoOutlineIcon } from '../../icons';
import useBulkEmailState from './useBulkEmailState';
import { useQueryClient } from '@tanstack/react-query';
import { InvitedUserDto } from '@portal/api/src/gw/model';

interface InviteUsersDrawerProps {
  createInvitationsURL?: string;
  members: InvitedOrExistingMember[];
  onClose: () => void;
  queryInvitationsURL: string;
  queryMembersURL?: string;
  queryRolesURL: string;
}

interface BackendInvitation {
  organizationId: string;
  roles: string[];
  invitee: InvitedUserDto;
}

export const getMutationBody = (
  emails: string[],
  role: string,
  currentOrganizationId: string
): BackendInvitation[] => {
  return emails.map((email) => {
    return {
      organizationId: currentOrganizationId,
      invitee: { email: email },
      roles: [role],
    };
  });
};

const InviteUsersDrawer: React.FC<InviteUsersDrawerProps> = ({
  createInvitationsURL,
  members,
  onClose,
  queryInvitationsURL,
  queryMembersURL,
  queryRolesURL,
}) => {
  const { t } = useTranslation();
  const { currentOrganization } = useOrganization();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutation = useStandardMutation<BackendInvitation[], InvitedUser>({
    queryKey: queryInvitationsURL,
    mutationKey: createInvitationsURL,
    onSuccess: (_data, listOfInvitations) => {
      queryClient
        .invalidateQueries([
          createInvitationsURL ? createInvitationsURL : queryMembersURL,
        ])
        .then(() => {
          /* Ignore */
        });
      let successMessage = t(
        'components.InviteUsersDrawer.successToastMultiple',
        { count: listOfInvitations.length }
      );

      if (listOfInvitations.length === 1) {
        successMessage = t('components.InviteUsersDrawer.successToastSingle', {
          email: listOfInvitations[0].invitee.email,
        });
      }

      showSnackbar(successMessage, 'success', SnackBarDuration.STANDARD);
      onClose();
    },
  });

  const [role, setRole] = React.useState<string>('');
  const [roleError, setRoleError] = React.useState<string>('');

  const onRoleChange = useCallback((e) => {
    setRole(e.target.value);
    setRoleError('');
  }, []);

  const {
    isValidForSubmit,
    emailInputValue,
    validEmails,
    error,
    ...passThroughProps
  } = useBulkEmailState(members);

  const onConfirm = useCallback(() => {
    const finalEmails = [...validEmails];
    const cleanedFinalEmailInput = emailInputValue.trim();

    if (isValidForSubmit(cleanedFinalEmailInput)) {
      if (cleanedFinalEmailInput.length > 0) {
        finalEmails.push(cleanedFinalEmailInput);
      }
    } else {
      // Error message will be populated by BulkEmailInput
      return;
    }

    if (!role) {
      setRoleError(t('yup.requiredField'));
      return;
    }

    const newMutationBody = getMutationBody(
      finalEmails,
      role,
      currentOrganization.id
    );
    mutation.mutate(newMutationBody);
  }, [
    validEmails,
    emailInputValue,
    role,
    currentOrganization.id,
    mutation,
    isValidForSubmit,
    t,
  ]);

  return (
    <>
      <MDrawer.Title
        drawerTitle="components.InviteUsersDrawer.inviteUsers"
        onClose={onClose}
      />

      <MDrawer.Body size="md">
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <Box sx={{ maxWidth: 318 }}>
            <BulkEmailInput
              label="common.form.email"
              error={error}
              value={emailInputValue}
              validEmails={validEmails}
              {...passThroughProps}
            />
          </Box>
          <UserRolesSelect
            name="role"
            label="common.form.role"
            error={roleError}
            onChange={onRoleChange}
            queryRolesURL={queryRolesURL}
          />
        </FormControl>
        <MDrawer.Hint
          sx={{ my: 2 }}
          startIcon={
            <SvgIcon
              sx={{ fill: 'none !important' }}
              viewBox="0 0 24 24"
              component={InfoOutlineIcon}
            />
          }
        >
          {t('components.InviteUsersDrawer.hintMultipleEmails')}
        </MDrawer.Hint>
      </MDrawer.Body>

      <MDrawer.Actions
        onConfirm={onConfirm}
        onClose={onClose}
        isLoading={mutation.isLoading}
        errorMessage={mutation.error?.toString()}
      />
    </>
  );
};

export default InviteUsersDrawer;
