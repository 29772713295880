interface Assignee {
  id: string;
  email: string;
}

export enum ProductTypeEnum {
  DESKTOP = 'DESKTOP',
  HUB_ITEM = 'HUB_ITEM',
  SERVER = 'SERVER',
  TOOL = 'TOOL',
}

export interface Product {
  id: string;
  product: {
    id: string;
    name: string;
    type: ProductTypeEnum;
  };
  productPlan: {
    name: string;
    seats: number;
  };
  entitlementPeriod: {
    validFrom: number;
    validUntil: number;
  };
  assignees: Assignee[];
}
