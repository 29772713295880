import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { EditorState, EditorThemeClasses, Klass, LexicalNode } from 'lexical';
import { useEffect, useState } from 'react';
import EditorNodes from './EditorNodes';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin/Index';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin/Index';
import LinkPlugin from './plugins/LinkPlugin/LinkPlugin';
import MarkdownPlugin from './plugins/MarkdownShortcutPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import EditorToolbar from './plugins/ToolbarPlugin/Toolbar';
import { CAN_USE_DOM } from './shared/canUseDOM';
import MaltegoEditorTheme from './themes/MaltegoEditorTheme';
import LexicalContentEditable from './ui/ContentEditable/Index';
import Placeholder from './ui/Placeholder/Index';
import useDebounce from './utils/useDebounce';

interface InitialConfig {
  namespace: string;
  onError: (error: Error) => void;
  nodes: Klass<LexicalNode>[];
  theme: EditorThemeClasses;
  editorState?: string | null;
}
const getConfig = (): InitialConfig => {
  return {
    namespace: 'ReportEditor',
    onError,
    nodes: [...EditorNodes],
    theme: MaltegoEditorTheme,
    editorState: undefined,
  };
};
function onError(error: Error) {
  console.error(error);
}
interface EditorProps {
  id: string;
  initialEditorState?: string;
  onChange: (editorState: EditorState) => void;
  debounceTime?: number;
}
export const Editor = ({
  id,
  initialEditorState,
  onChange,
  debounceTime = 500,
}: EditorProps) => {
  const config = getConfig();
  config.namespace = id;
  config.editorState = initialEditorState || undefined;

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [reportUpdates, setUpdatedReportUpdates] = useState<EditorState>();
  const debouncedValue = useDebounce<EditorState | undefined>(
    reportUpdates,
    debounceTime
  );

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };

    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const handleChange = (editorState: EditorState) => {
    setUpdatedReportUpdates(editorState);
  };
  useEffect(() => {
    if (reportUpdates) {
      onChange(reportUpdates);
    }
  }, [debouncedValue]);

  return (
    <LexicalComposer initialConfig={config}>
      <div className="editor-shell">
        <EditorToolbar />
        <div className="editor-container editor-container-extended">
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                  <LexicalContentEditable />
                </div>
              </div>
            }
            placeholder={<Placeholder>{'Enter some rich text...'}</Placeholder>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <DragDropPaste />
          <TablePlugin />
          <TableCellResizer />
          <HistoryPlugin />
          <ImagesPlugin />
          <LinkPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <CheckListPlugin />
          <HorizontalRulePlugin />
          <MarkdownPlugin />

          {floatingAnchorElem && !isSmallWidthViewport && (
            <>
              <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
              {/* <CodeActionMenuPlugin anchorElem={floatingAnchorElem} /> */}

              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <FloatingTextFormatToolbarPlugin
                anchorElem={floatingAnchorElem}
              />
            </>
          )}
          <OnChangePlugin onChange={handleChange} />
        </div>
      </div>
    </LexicalComposer>
  );
};

// function Placeholder() {
//     return <div className="editor-placeholder">Enter some plain text...</div>;
// }
