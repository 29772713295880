import React, { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  SvgIcon,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { MDialog } from '@maltego/mui-core';
import {
  InvitedOrExistingMember,
  InvitedUser,
  MemberStatusEnum,
} from '../../types';
import { SnackBarDuration, useOrganization, useSnackbar } from '../../contexts';
import { WarningIcon } from '../../icons';
import { useStandardMutation } from '../../utils';
import { useAuthentication } from '@maltego/common-contexts';
import { AccountDto } from '@portal/api/src/gw/model';

interface DeleteUserMutation {
  organizationId: string;
  userId: string;
}

const RemoveUserCheckbox: React.FC<{
  label: string;
  isChecked: boolean;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, isChecked, name, onChange }) => (
  <FormControlLabel
    // @ts-ignore
    label={label}
    control={
      <Checkbox
        disableRipple
        checked={isChecked}
        color="error"
        onChange={onChange}
        name={name}
        sx={{ py: 0.25 }}
      />
    }
  />
);

interface DeleteUserOrInvitationDialogProps {
  user: InvitedOrExistingMember;
  currentOrganization: AccountDto;
  onClose: () => void;
  closeDrawer: () => void;
  queryOrganizationsURL: string;
  queryUsersURL: string;
  removeInvitationURL: string;
  removeUserByAdminURL: string;
  removeUserBySelfURL: string;
}

const DeleteUserOrInvitationDialog = ({
  user,
  currentOrganization,
  onClose,
  closeDrawer,
  queryOrganizationsURL,
  queryUsersURL,
  removeInvitationURL,
  removeUserByAdminURL,
  removeUserBySelfURL,
}: DeleteUserOrInvitationDialogProps) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { setCurrentOrganizationID, organizations } = useOrganization();
  const { claims } = useAuthentication();
  const [checklistChecked, setChecklistChecked] = useState({
    userDissociatedCheck: false,
    assignmentDroppedCheck: false,
  });
  const queryClient = useQueryClient();

  const isUserInvited = user.status === MemberStatusEnum.invited;
  const isUserDeletingSelf = claims.email === user.email;
  const isAdminSelfWithoutOtherOrgs =
    isUserDeletingSelf && organizations.length === 1;

  const allCheckboxesAccepted =
    checklistChecked.userDissociatedCheck &&
    checklistChecked.assignmentDroppedCheck;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecklistChecked({
      ...checklistChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDeleteInvitation = useStandardMutation<string, InvitedUser>({
    mutationKey: removeInvitationURL,
    mutationMethod: 'DELETE',
    onSuccess: () => {
      showSnackbar(
        t('components.DeleteUserOrInvitationDialog.invitationDeleted'),
        'success',
        SnackBarDuration.STANDARD
      );
      closeDrawer();
    },
    queryKey: queryUsersURL, // re-fetch users after delete
    getNewQueryState: (oldInvitations, userId) =>
      oldInvitations.filter((invitation) => invitation.id !== userId),
    onError: () => {
      onClose();
      showSnackbar(
        t('components.DeleteUserOrInvitationDialog.invitationDeleteError'),
        'error',
        SnackBarDuration.STANDARD
      );
    },
  });

  const onMutationFail = useCallback(() => {
    closeDrawer();
    showSnackbar(
      t('components.DeleteUserOrInvitationDialog.userRemoveFailed'),
      'error',
      SnackBarDuration.STANDARD
    );
  }, [closeDrawer, showSnackbar, t]);

  const handleDeleteUser = useStandardMutation<
    DeleteUserMutation,
    InvitedOrExistingMember
  >({
    mutationKey: removeUserByAdminURL,
    mutationMethod: 'DELETE',
    onSuccess: () => {
      if (isAdminSelfWithoutOtherOrgs) {
        queryClient.invalidateQueries([queryOrganizationsURL]).then(() => {});
      } else {
        showSnackbar(
          t('components.DeleteUserOrInvitationDialog.userRemoved'),
          'success',
          SnackBarDuration.STANDARD
        );
        closeDrawer();
      }
    },
    queryKey: queryUsersURL, // re-fetch Users after delete
    getNewQueryState: (oldUsers, { userId }) =>
      oldUsers.filter((oldUser) => oldUser.id !== userId),
    onError: onMutationFail,
  });

  const handleDeleteAdminSelf = useStandardMutation<
    string,
    InvitedOrExistingMember
  >({
    mutationKey: removeUserBySelfURL,
    mutationMethod: 'DELETE',
    onSuccess: () => {
      closeDrawer();
      setCurrentOrganizationID();
    },
    queryKey: queryOrganizationsURL, // re-fetch organizations after delete the current org
    onError: onMutationFail,
  });

  const deleteUserOrSelf = useCallback(() => {
    if (isAdminSelfWithoutOtherOrgs || !isUserDeletingSelf) {
      handleDeleteUser.mutate({
        organizationId: currentOrganization.id,
        userId: user.id,
      });
    } else {
      handleDeleteAdminSelf.mutate(currentOrganization.id);
    }
  }, [
    isAdminSelfWithoutOtherOrgs,
    isUserDeletingSelf,
    handleDeleteAdminSelf,
    handleDeleteUser,
    currentOrganization.id,
    user.id,
  ]);

  return (
    <>
      <MDialog.Title onClose={onClose}>
        {t('components.DeleteUserOrInvitationDialog.removeUser')}
      </MDialog.Title>

      <MDialog.Alert
        startIcon={
          <SvgIcon sx={{ fill: 'none !important' }} component={WarningIcon} />
        }
      >
        {isUserInvited
          ? t('components.DeleteUserOrInvitationDialog.deleteInvitationWarning')
          : t('components.DeleteUserOrInvitationDialog.removeUserWarning')}
      </MDialog.Alert>

      <DialogContent sx={{ pt: '38px !important', color: 'primary.main' }}>
        <DialogContentText
          sx={{ fontSize: 15, color: 'primary.main', mb: 1.5 }}
        >
          <Trans
            i18nKey="components.DeleteUserOrInvitationDialog.removeUserDescription"
            values={{
              User: user.email,
              OrgName: currentOrganization.name,
              Possessive: isUserDeletingSelf
                ? t(
                    'components.DeleteUserOrInvitationDialog.possessiveForAdmin'
                  )
                : t(
                    'components.DeleteUserOrInvitationDialog.possessiveForUser'
                  ),
            }}
          />
        </DialogContentText>
        <>
          <RemoveUserCheckbox
            label={t(
              'components.DeleteUserOrInvitationDialog.userDissociatedCheck'
            )}
            isChecked={checklistChecked.userDissociatedCheck}
            onChange={handleChange}
            name="userDissociatedCheck"
          />
          <RemoveUserCheckbox
            label={t(
              'components.DeleteUserOrInvitationDialog.assignmentDroppedCheck'
            )}
            isChecked={checklistChecked.assignmentDroppedCheck}
            onChange={handleChange}
            name="assignmentDroppedCheck"
          />
        </>
      </DialogContent>

      <MDialog.Actions
        confirmText={t('common.button.remove')}
        confirmBtnColor="error"
        isConfirmDisabled={!allCheckboxesAccepted}
        isLoading={
          handleDeleteInvitation.isLoading ||
          handleDeleteUser.isLoading ||
          handleDeleteAdminSelf.isLoading
        }
        errorMessage={
          handleDeleteInvitation.error?.displayMessage ||
          handleDeleteUser.error?.displayMessage ||
          handleDeleteAdminSelf.error?.displayMessage
        }
        onCancel={onClose}
        onConfirm={() =>
          isUserInvited
            ? handleDeleteInvitation.mutate(user.id)
            : deleteUserOrSelf()
        }
      />
    </>
  );
};

export default DeleteUserOrInvitationDialog;
