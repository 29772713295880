import { QueryClient } from '@tanstack/react-query';
import { defaultReactQueryFunc } from '@maltego/ui';

const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultReactQueryFunc,
        staleTime: 60_000,
        retry: 0, // Disable retries on error
        refetchOnWindowFocus: false, // Trust that components will invalidate queries after mutations, prevent from errors every time page is focused
      },
    },
  });
};

export default getQueryClient;
