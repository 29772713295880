import { yupResolver } from '@hookform/resolvers/yup';
import {
  InPageError,
  MDialog,
  MTextField,
  WarningIcon,
} from '@maltego/mui-core';
import { Box, DialogContent, SvgIcon } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { SvgCase36 } from '../../../icons';
import {
  getFindCaseByIdQueryKey,
  getGetAllCasesQueryKey,
  useUpdateCase,
} from '../../../graph-store-client/maltegoCaseManagementAPI';
import { UpdateCase } from '../../../graph-store-client/model';
import { SnackBarDuration, useSnackbar } from '@maltego/ui';

const RENAME_CASE_SCHEMA = yup.object({
  name: yup.string().required(),
});

interface RenameCaseDialogProps {
  caseName: string;
  caseId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const RenameCaseDialog = ({
  caseName,
  caseId,
  onConfirm,
  onCancel,
}: RenameCaseDialogProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const DEFAULT_CREATE_CASE: UpdateCase = {
    name: caseName,
  };
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<UpdateCase>({
    resolver: yupResolver(RENAME_CASE_SCHEMA),
    reValidateMode: 'onChange',
    // mode: 'onTouched',
    defaultValues: DEFAULT_CREATE_CASE,
  });

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const updateCaseMutation = useUpdateCase();
  const error = updateCaseMutation.error;
  const errorDisplay = useMemo(
    () => (error ? error.response?.data?.title : null),
    [error]
  );
  const { showSnackbar } = useSnackbar();

  if (error) return <InPageError message={t('pages.cases.renameCaseError')} />;
  const updateCase = (data: UpdateCase) => {
    updateCaseMutation.mutate(
      {
        caseId: caseId,
        data: data,
      },
      {
        onSuccess: () => {
          const successMessage = `Case ${caseName} is renamed to ${data.name} successfully.`;
          showSnackbar(successMessage, 'info', SnackBarDuration.STANDARD);

          void queryClient.invalidateQueries({
            queryKey: getGetAllCasesQueryKey(),
          });
          void queryClient.invalidateQueries({
            queryKey: getFindCaseByIdQueryKey(caseId),
          });
          onConfirm();
        },
      }
    );
  };

  return (
    <>
      <MDialog.Title onClose={onCancel}>
        {t('pages.cases.renameCase')}
      </MDialog.Title>
      <DialogContent>
        <form onSubmit={handleSubmit(updateCase)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <SvgCase36 height={32} width={32} />
            <MTextField
              label={t('pages.cases.caseName')}
              error={errors?.name?.message}
              {...register('name')}
              type="search"
            ></MTextField>
          </Box>
        </form>
      </DialogContent>
      {errorDisplay && (
        <MDialog.Alert
          startIcon={
            <SvgIcon
              viewBox="0 0 24 24"
              sx={{ fill: 'none !important' }}
              component={WarningIcon}
            />
          }
        >
          {errorDisplay}
        </MDialog.Alert>
      )}
      <MDialog.Actions
        confirmText={t('common.button.confirm')}
        onCancel={onCancel}
        isLoading={updateCaseMutation.isLoading}
        onConfirm={handleSubmit(updateCase)}
      />
    </>
  );
};
