import React, { useEffect, useState } from 'react';
import { styled, Switch, SwitchProps, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import BetaTagWrapper from '../BetaTagWrapper/BetaTagWrapper';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 27,
  height: 14,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '1px',
    '&.Mui-checked': {
      transform: 'translateX(13px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette['textLink']['main'],
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
    boxSizing: 'border-box',
  },
}));

export interface BetaSwitchProps extends SwitchProps {
  label?: string;
  sx?: SxProps;
}

const BetaSwitch: React.FC<BetaSwitchProps> = ({ label, sx, ...props }) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  return (
    <BetaTagWrapper isBetaShown betaFontSize={21} sx={{ gap: 1, ...sx }}>
      {label && (
        <Typography sx={{ textTransform: 'uppercase' }}>{label}</Typography>
      )}
      <CustomSwitch checked={isChecked} onChange={props.onChange} {...props} />
    </BetaTagWrapper>
  );
};

export default BetaSwitch;
