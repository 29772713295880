import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuthentication } from '@maltego/common-contexts';

export const isCorrectEnv = ['test', 'dev'].includes(
  process.env.NEXT_PUBLIC_APP_ENV || 'unknown'
);

const EnableSentryUserTracking = () => {
  const { claims } = useAuthentication();

  useEffect(() => {
    if (isCorrectEnv && claims) {
      Sentry.setUser({
        username: claims.name,
        email: claims.email,
      });
    }
  }, [claims]);

  return <div />;
};

export default EnableSentryUserTracking;
